import { query } from './Query';

export const subscribeToEntryApi = async ({ entryId, token }) => {
  try {
    await query({
      method: 'POST',
      resource: `entries/${entryId}/relationships/subscriptions`,
      token
    });
    return { status: 'ok' };
  } catch (err) {
    console.log('subscribe from comments', err);
    return { error: 'что-то пошло не так' };
  }
};

export const unsubscribeFromEntryApi = async ({ entryId, token }) => {
  try {
    await query({
      method: 'PATCH',
      resource: `entries/${entryId}/relationships/subscriptions`,
      token
    });
    return { status: 'ok' };
  } catch (err) {
    console.log('unsubscribe from comments', err);
    return { error: 'что-то пошло не так' };
  }
};
