import React from 'react';

export const ChatIcon = ({ className }) => (
  <svg
    viewBox="-1 -1 34 30"
    className={`icon icon-comments ${className}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="chat-second-bubble secondary-filling-area"
      d="m 10.156425,0.97269067 19.458914,0.044736 0.529691,-0.031076 c 0.809076,-0.0474669 1.947944,1.07719609 1.949816,1.88766439 l 0.03108,13.4478469 c 0.0019,0.810467 -0.922222,1.4481 -1.732284,1.48237 l -1.469128,0.06215 c -0.91279,0.03862 -0.937948,0.637973 -0.929874,1.55154 l 0.03108,3.516211 -1.548862,-1.608046 -1.056582,-1.065602 V 9.1606073 c 0,-0.7095616 -0.0639,-1.5958212 -0.437401,-2.1453418 C 24.112998,6.1407614 23.45679,5.7937268 22.452906,5.7744894 17.211983,5.7843532 12.473178,5.8567867 7.8039881,5.8656822 V 2.7360508 c 0,-0.8104219 0.3629946,-1.76328346 1.1716118,-1.76336076 z"
    />
    <path
      className="chat-bubble filling-area"
      d="m 23.15502,6.017756 c 0.612742,-0.028775 1.73953,0.5072085 2.089583,0.7743161 0.158692,0.2346734 0.250524,0.5117105 0.250524,0.8190828 v 11.8236501 1.732094 c -0.445817,1.412886 -1.209105,1.779923 -2.376473,1.746138 l -9.763655,0.04519 c -0.790032,0.296989 -1.222793,0.928854 -1.836686,1.518119 L 7.8031685,28.506064 7.7720925,24.433921 C 7.7650479,23.510799 7.6235566,22.98918 6.7004094,22.98918 h -4.098547 c -0.8180324,0 -1.93391393,-0.959182 -1.93391393,-1.777213 V 7.6550366 c 0,-0.818936 0.64834983,-1.4677741 1.46909563,-1.4677741 8.347e-4,1.3e-6 0.00166,1.3e-6 0.00249,0 l 5.8134215,-0.012614 c 8.037e-4,1.1e-6 0.00167,1.1e-6 0.00247,0 z"
    />
    <path
      className="chat-outline main-outline"
      d="M 10.052334,3.0682216e-6 C 8.2866496,3.0682216e-6 6.8347824,1.4523798 6.8347824,3.2187438 v 2.0136143 l -3.6170992,0.0078 C 1.4516766,5.241432 0,6.6933043 0,8.4589014 V 20.675885 c 0,1.766024 1.4527172,3.217683 3.2187412,3.217683 h 3.7153667 v 4.491716 c -3.582e-4,0.867077 1.0671637,1.279675 1.6500398,0.637745 l 4.6632533,-5.130651 9.696958,-0.03955 c 1.221379,0 2.292471,-0.693258 2.837973,-1.704397 l 1.522808,1.675037 c 0.582851,0.641833 1.650282,0.229376 1.65004,-0.637611 V 18.69401 h 0.826079 C 31.547331,18.69401 33,17.242825 33,15.476461 V 3.259479 C 33,1.493115 31.547624,0.04073824 29.781259,0.04073824 L 10.054583,2.0027698e-8 Z M 9.8583791,1.7249763 c 6.7056529,0.013821 13.4113049,0.027664 20.1169569,0.041475 0.752501,0 1.348709,0.595328 1.348709,1.3467263 V 15.552049 c 0,0.751398 -0.595895,1.345515 -1.348709,1.345515 h -1.219787 c -0.848259,0 -1.55585,0.706678 -1.55585,1.553697 v 2.453221 L 26.16535,19.750218 V 19.074385 8.419357 c 0,-0.6625752 -0.203718,-1.2870191 -0.550719,-1.8001508 V 6.618166 C 25.036183,5.7631613 24.050611,5.199428 22.946608,5.199428 18.077555,5.2086353 12.849604,5.2182461 8.5117178,5.226548 L 8.5108765,3.071701 C 8.5105831,2.3203553 9.1075937,1.7250462 9.8583791,1.7249763 Z M 23.124828,6.9252871 c 0.469117,0 0.873828,0.2372794 1.11676,0.5963495 0.145587,0.2153002 0.229843,0.4694658 0.229843,0.7514641 v 10.8475423 1.5891 c 0,0.751326 -0.59395,1.34539 -1.348892,1.34539 l -10.045601,0.04142 c -0.435616,0.0015 -0.851034,0.187939 -1.142074,0.506691 -1.059641,1.167169 -2.1209267,2.333354 -3.1815874,3.49978 V 23.65003 c 0,-0.84694 -0.7064817,-1.553554 -1.5534188,-1.553554 H 3.0405201 c -0.7504994,0 -1.3466019,-0.594891 -1.3466019,-1.345388 V 8.3133593 c 0,-0.7513286 0.5948255,-1.3466015 1.3478136,-1.3466015 l 5.3357795,-0.011574 c 4.9157717,-0.00978 9.8315427,-0.020566 14.7473167,-0.029897 z"
    />
  </svg>
);
