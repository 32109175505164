import React from 'react';
import Select from './Select';
import { getTags } from '../../api/tags';
import { useTokenState } from '../../store/localStorage/useTokenState';

function TagsSelect({
  value,
  onChange,
  tagOptions,
  searchAllTags = false,
  ...rest
}) {
  const [token] = useTokenState();
  const tagsPageSize = 20;

  function handleTagsChange(options) {
    const tags = options.map(option => option.value);
    onChange([...tags]);
  }

  async function loadAllTags(inputValue, loadedOptions, { page }) {
    const response = await getTags({
      token,
      text: inputValue,
      pageSize: tagsPageSize,
      pageNumber: page
    });
    if (response.error) {
      return {
        options: [],
        hasMore: false
      };
    }
    const options = response.data.map(tag => ({
      label: tag.name,
      value: tag.name
    }));
    const hasMore =
      loadedOptions.length + options.length < response.meta.totalTags;
    return {
      options: options,
      hasMore: hasMore,
      additional: { page: page + 1 }
    };
  }

  const tagsValue = value.map(tag => ({
    label: '#' + tag,
    value: tag
  }));

  return searchAllTags ? (
    <Select
      isMulti
      isAsync
      isPaginated={true}
      isClearable={false}
      creatable={false}
      defaultOptions={tagOptions}
      loadOptions={loadAllTags}
      onChange={handleTagsChange}
      noOptionsMessage={({ inputValue }) =>
        inputValue ? 'ничего не найдено' : 'введите тег'
      }
      loadingMessage={() => 'ищем варианты'}
      controlledValue
      value={tagsValue}
      placeholder="теги"
      captureMenuScroll
      menuPlacement="auto"
      blogSelect={false}
      additional={{
        page: 1
      }}
      {...rest}
    />
  ) : (
    <Select
      isMulti
      isClearable={false}
      creatable={false}
      options={tagOptions}
      onChange={handleTagsChange}
      noOptionsMessage={() => 'ничего не найдено'}
      loadingMessage={() => 'ищем варианты'}
      controlledValue
      value={tagsValue}
      placeholder="теги"
      captureMenuScroll
      menuPlacement="auto"
      blogSelect={false}
      {...rest}
    />
  );
}

export default TagsSelect;
