import { Router } from '@reach/router';
import NotFound from 'scenes/Dybr/Static/NotFound';
import Footer from 'scenes/Footer/Footer';
import React from 'react';
import ManageCommunity from 'scenes/Management/Community/ManageCommunity';

function ManagementScenes() {
  return (
    <>
      <Router>
        <ManageCommunity path="community" />

        <NotFound default reason="Аккаунт: что-то пошло не так" />
      </Router>
      <Footer withLemur />
    </>
  );
}

export default ManagementScenes;
