import React from 'react';

const Icon = ({ className }) => (
  <svg
    viewBox="0 0 31.821514 30.800562"
    className={`icon icon-reply-to-comment ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    name="reply" // need this for click detection
  >
    <path
      className="arrow filling-area"
      d="M 11.933068,-9.4346183e-8 0,11.933067 11.933068,23.866134 v -5.966533 h 10.340439 c 3.480478,0 4.099633,3.480478 3.848668,6.960956 -0.250965,3.480478 -1.493459,5.662567 -0.903299,5.914081 0.524984,0.223737 1.299097,-0.991691 3.066944,-3.999818 1.767847,-3.008128 3.535694,-6.985817 3.535694,-10.864064 0,-9.9442221 -8.685456,-9.9442221 -8.685456,-9.9442221 h -11.20299 z"
      name="reply"
    />
    <path
      className="arrow main-outline"
      d="M 11.933068,0 0,11.933068 11.933068,23.866135 v -5.966533 h 10.340439 c 3.480478,0 4.099633,3.480478 3.848668,6.960956 -0.250965,3.480478 -1.493459,5.662567 -0.903299,5.914081 0.524984,0.223737 1.299097,-0.991691 3.066944,-3.999818 1.767847,-3.008128 3.535694,-6.985817 3.535694,-10.864064 0,-9.944223 -8.685456,-9.944223 -8.685456,-9.944223 H 11.933068 Z M 9.6579686,5.3484884 9.5695803,8.1988364 H 23.326841 c 0,0 6.288097,0.7364875 6.42068,8.1243186 0.11082,6.175174 -2.293607,9.13483 -2.293607,9.13483 0.238054,0.283733 2.666297,-9.83488 -4.807499,-9.83488 H 9.6137744 v 2.938736 L 3.2059722,11.933068 Z"
      name="reply"
    />
  </svg>
);
export default Icon;
