import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import css from 'components/FormElements/SearchTextField.module.css';

const StyledTextField = styled.input.attrs((props) => ({
  id: props.id
}))`
  height: 30px;
  width: 100%;
  border: none;

  border-bottom: 1px solid ${(props) => props.theme.backgroundDark};
  padding-bottom: 3px;
  font-weight: bold;
  font-size: 1em;
  color: ${(props) => props.theme.text};
  background: transparent;

  :hover,
  :focus {
    outline: none;
  }

  &::placeholder {
    color: ${(props) => props.theme.text};
    font-size: 1em;
    font-weight: normal;
    font-family: ${(props) => props.theme.textFont};
  }

  &:focus + label {
    display: none;
  }

  ${(props) =>
    props.value &&
    `
    + label {
      display: none;
    }
  `};

  &:-webkit-autofill + label {
    display: none;
  }
`;

const propTypes = {
  isRequired: PropTypes.bool,
  prefix: PropTypes.string,
  withCounter: PropTypes.bool,
  placeholder: PropTypes.string,
  maxLen: PropTypes.number,
  type: PropTypes.oneOf(['text', 'password', 'email', '']),
  label: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  onChange: PropTypes.func
};

const defaultProps = {
  isRequired: false,
  prefix: '',
  withCounter: false,
  placeholder: '',
  maxLen: 0,
  type: 'text',
  input: {},
  label: '',
  error: '',
  touched: false,
  onChange: () => {}
};

const SearchTextField = ({
  isRequired,
  prefix,
  input,
  maxLen,
  label,
  touched,
  error,
  value,
  id,
  ...props
}) => {
  return (
    <div className={css.container}>
      <StyledTextField
        touched={touched}
        error={error}
        value={value}
        id={id}
        {...props}
      />
      {!value && (
        <label className={css.label} htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};

SearchTextField.propTypes = propTypes;
SearchTextField.defaultProps = defaultProps;

export default SearchTextField;
