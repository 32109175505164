import Loading from 'components/Shared/Loading';
import React, { useEffect, useState } from 'react';
import { getDybrVersion } from 'utils/helpers/getDybrVersion';
import { useStoredVersionState } from 'store/localStorage/useStoredVersionState';
import { runMigrationToLS } from 'store/localStorage/migrationToLS';
import {
  useCommentDraftState,
  useEntryDraftState
} from 'store/localStorage/drafts/useDraftState';
import { useTokenState } from 'store/localStorage/useTokenState';
import merge from 'lodash/merge';

function showHasNewerVersionAlert() {
  window.alert(
    'Доступна новая версия Дыбра. Пожалуйста, сохраните все данные и обновите страницу.'
  );
}

export const checkCurrentAppVersion = (storedVersion, setStoredVersion) => {
  const currentVersion = getDybrVersion();

  console.log('Current app version: ', currentVersion);
  console.log('App version from local storage: ', storedVersion);

  if (currentVersion !== storedVersion) {
    console.log('Versions do not match');
    if (currentVersion && storedVersion) {
      console.log(
        `Дыбр обновлён с версии ${storedVersion} до версии ${currentVersion}`
      );
    }
    setStoredVersion(currentVersion);
  } else {
    console.log(
      'Versions match. Stored:',
      storedVersion,
      ', shown:',
      currentVersion
    );
  }
};

function StateMonitor({ children }) {
  const [versionChecked, setVersionChecked] = useState(false);
  const [updateAvailableShown, setUpdateAvailableShown] = useState(false);

  const [migration, setMigration] = useState();
  const [storedVersion, setStoredVersion] = useStoredVersionState();
  const [, setToken] = useTokenState();
  const [, setEntryDraft] = useEntryDraftState();
  const [, setCommentDraft] = useCommentDraftState();

  useEffect(() => {
    if (!migration) {
      const oldState = localStorage.getItem('persist:redux-store');
      const { commentLocalStorageState, entryLocalStorageState } =
        runMigrationToLS(oldState, setToken);

      if (entryLocalStorageState) {
        setEntryDraft(draft => {
          const newDraft = { ...draft };
          merge(newDraft, entryLocalStorageState);
          return newDraft;
        });
      }
      if (commentLocalStorageState) {
        setCommentDraft(draft => {
          const newDraft = { ...draft };
          merge(newDraft, commentLocalStorageState);
          return newDraft;
        });
      }
      if (oldState) {
        localStorage.removeItem('persist:redux-store');
      }

      setMigration('done');
    }
  }, []);

  useEffect(() => {
    // this will set new version in a just loaded tab
    checkCurrentAppVersion(storedVersion, setStoredVersion);
    setVersionChecked(true);
  }, []);

  useEffect(() => {
    // this will show alert in background tabs in case if new version is detected
    if (!versionChecked) {
      return;
    }

    const currentVersion = getDybrVersion();

    if (
      !updateAvailableShown &&
      storedVersion &&
      currentVersion &&
      currentVersion !== storedVersion
    ) {
      console.log('storedVersion: ', storedVersion);
      console.log('currentVersion: ', currentVersion);
      console.log('update available alert will be shown');
      showHasNewerVersionAlert();
      setUpdateAvailableShown(true);
    }
  }, [storedVersion, updateAvailableShown, versionChecked]);

  if (!migration || !versionChecked) {
    return <Loading />;
  }

  return children;
}

export const StateMonitorContainer = StateMonitor;
