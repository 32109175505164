export default ({ colors, header, font }) => `
 /*  .blog-comment {
    padding: 20px;
    background: ${colors.blocks};
    border: 1px solid ${colors.borders};
  }
  .blog-comment-meta--up {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
  }
  .blog-comment-date {
    font-size: 12px;
    min-width: 130px;
    text-align: right;
  }

  .blog-comment-meta--down {
    display: flex;
    justify-content: flex-end;
  }*/
`;
