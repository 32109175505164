export function getCommentUrl(commentId, entry) {
  return `${getEntryUrl(entry)}#${commentId}`;
}

export function getEntryUrl(entry) {
  const blogSlug = entry.community?.isCommunity
    ? entry.community.blogSlug
    : entry.profile.blogSlug;
  return `/blog/${blogSlug}/${entry.id}`;
}

export function getConversationUrl(conversationId) {
  return `/direct/${conversationId}`;
}

export function getConversationMessageUrl(messageId, conversationId) {
  return `${getConversationUrl(conversationId)}#${messageId}`;
}
