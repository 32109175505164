import { createSelector } from 'reselect';
import { selectUser } from 'store/user/selectors/selectUser';

export const selectCommunities = createSelector(
  selectUser,
  (state, { activeProfileId }) => activeProfileId,
  (user, activeProfileId) =>
    activeProfileId && user.communities
      ? user.communities[activeProfileId]
      : undefined
);
