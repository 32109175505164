import React from 'react';
import TextField from 'components/FormElements/TextField';
import { dybrConfigs } from 'configs/dybr';

function BlogNameField({
  title,
  setTitle,
  errorTitle,
  setErrorTitle,
  validate
}) {
  return (
    <TextField
      id="title"
      type="text"
      value={title}
      meta={{}}
      onChange={(e) => {
        setTitle(e.target.value);
        setErrorTitle('');
      }}
      label="название дневника"
      maxLen={dybrConfigs.MAX_LENGTH.blogTitle}
      onBlur={validate}
      error={errorTitle}
      touched={Boolean(errorTitle)}
    />
  );
}

export default BlogNameField;
