import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  subscribeToProfile,
  unsubscribeFromProfile
} from 'store/lists/operations';
import { selectProfileIsFavorite } from 'store/lists/selectors/selectProfileIsFavorite';

import { AddToFavoritesStyled } from 'components/Shared/AddToFavorites.style';
import { AddToFavoritesIcon } from 'styles/Icons/AddToFavorites';
import {
  useActiveProfile,
  useTokenState
} from 'store/localStorage/useTokenState';

function AddToFavorites({
  profile,
  isFavorite,
  subscribe,
  unsubscribe,
  sidebarMode = false
}) {
  const [waiting, setWaiting] = useState(false);
  const [favorite, setFavorite] = useState(isFavorite);
  const { activeProfileId } = useActiveProfile();
  const [token] = useTokenState();

  useEffect(() => {
    setFavorite(isFavorite);
    // I don't want it to be triggered on isFavorite change.
    // eslint-disable-next-line
  }, [profile.id]);

  const toggleSubscription = async () => {
    if (waiting) {
      return;
    }

    setWaiting(true);
    setFavorite(!favorite);
    const toggleFn = favorite ? unsubscribe : subscribe;

    const res = await toggleFn({ profile, token, activeProfileId });

    if (!res) {
      setFavorite(!favorite);
    }

    setWaiting(false);
  };

  return (
    <AddToFavoritesStyled
      title={favorite ? 'удалить из избранного' : 'добавить в избранное'}
      active={favorite}
      sidebarMode={sidebarMode}
      onClick={toggleSubscription}
    >
      <AddToFavoritesIcon />
    </AddToFavoritesStyled>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFavorite: selectProfileIsFavorite(state, ownProps.profile.id)
  };
};
const mapDispatchToProps = {
  subscribe: subscribeToProfile,
  unsubscribe: unsubscribeFromProfile
};
export const AddToFavoritesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddToFavorites);
