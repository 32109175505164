import React from 'react';

import {
  Block,
  Settings,
  SettingSectionTitle,
  SettingsFlexbox
} from 'components/DybrComponents/StyledServicePagesElements';
import { updateProfileSetting } from 'store/user/thunks/updateProfileSetting';
import { connect } from 'react-redux';
import { selectActiveProfileSettingsAugmented } from 'store/user/selectors/selectActiveProfileSettingsAugmented';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';
import AddPrivacyList from 'scenes/User/Settings/Privacy/PrivacyLists/AddPrivacyList';
import CustomPrivacyLists from 'scenes/User/Settings/Privacy/PrivacyLists/CustomPrivacyLists';

function PrivacyLists() {
  return (
    <Block>
      <SettingsFlexbox>
        <SettingSectionTitle>
          <h4>Списки</h4>
          <p>
            Здесь вы можете создать списки для гибких настроек доступа к вашему
            блогу. Вы можете выбрать любого пользователя на Дыбре, начав
            печатать его ник в поле "ники пользователей"
          </p>
        </SettingSectionTitle>
        <Settings>
          <CustomPrivacyLists />
          <AddPrivacyList />
        </Settings>
      </SettingsFlexbox>
    </Block>
  );
}

const mapDispatchToProps = {
  updateProfileSetting
};

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    settings: selectActiveProfileSettingsAugmented(state, { activeProfileId })
  };
};

export default WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(PrivacyLists)
);
