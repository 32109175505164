import { Router } from '@reach/router';
import { HeaderContainer } from 'scenes/Header/_HeaderScene';
import React from 'react';
import { AppWrapper } from 'App';
import ErrorLog from '../../scenes/Dybr/Static/ErrorLog';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'errorboundary',
      title: 'Что-то пошло не так!',
      conjunction: 'но',
      hasError: false,
      stepsToReproduce: '',
      error: undefined,
      errorInfo: undefined
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <AppWrapper>
          <Router>
            <HeaderContainer default />
          </Router>
          <ErrorLog
            mode="errorboundary"
            conjunction="но"
            title="Что-то пошло не так!"
            error={this.state.error}
            errorInfo={this.state.errorInfo}
          />
        </AppWrapper>
      );
    }

    return this.props.children;
  }
}
