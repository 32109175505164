import React, { useEffect } from 'react';

import theme from 'styles/DybrTheme';
import { DybrPageWrapper } from 'components/DybrComponents/Wrappers';
import {
  Section,
  SectionLargerBlock,
  SectionSmallerBlock
} from 'components/DybrComponents/Section';
import InterestingList from './components/InterestingList';
import { InteractionBlock } from './components/InteractionBlock';
import { LoginFormContainer } from './components/LoginForm';
import AboutDybr from './components/AboutDybr';
import DybrNews from './components/DybrNews';
import { loggedOutInfo } from './components/StaticTexts';

export function MainLoggedOut() {
  useEffect(() => {
    document.title = 'Дыбр';
  }, []);

  return (
    <DybrPageWrapper main>
      <Section
        backgroundColor={theme.backgroundLighter}
        title="Блоги для всех и всего"
      >
        <AboutDybr />
      </Section>
      <Section
        backgroundColor={theme.backgroundLighter}
        withBackground
        title="С чего начать?"
      >
        <SectionLargerBlock>
          <InterestingList data={loggedOutInfo} />
        </SectionLargerBlock>
        <SectionSmallerBlock>
          <InteractionBlock>
            <LoginFormContainer />
          </InteractionBlock>
        </SectionSmallerBlock>
      </Section>

      <Section lastSection>
        <DybrNews height={'300px'} />
      </Section>
    </DybrPageWrapper>
  );
}
