import React from 'react';
import 'simplebar';
import 'simplebar/dist/simplebar.min.css';
import { connect } from 'react-redux';
import { ResetAllNotificationsContainer } from './ResetAllNotifications';
import {
  selectNotificationsMentioned,
  selectNotificationsOwnBlog,
  selectNotificationsPrivate,
  selectNotificationsSmbdElseBlog
} from 'store/notifications/selectors/selectNotifications';
import {
  selectCommentsGroupedByEntryId,
  selectCommentsWithMentionGroupedByEntryId,
  selectConversationMessagesGroupedByProfileId
} from 'store/notifications/selectors/selectNotificationsGrouped';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';
import Accordion from './Accordion';
import {
  selectCommentedEntriesOwn,
  selectCommentedEntriesSmbdElse,
  selectEntriesWithMention,
  selectProfilesWithPrivateNotification
} from '../../../../store/notifications/selectors/selectNotificationEntries';
import GroupedNotificationsForEntry from './notifications/GroupedNotificationsForEntry';
import {
  selectOldestMentionedNotificationUrl,
  selectOldestOwnNotificationUrl,
  selectOldestSmbdElseNotificationUrl
} from '../../../../store/notifications/selectors/selectOldestNotificationUrl';
import GroupedNotificationsForConversation from './notifications/GroupedNotificationsForConversation';
import { SecondaryButton } from '../../../../components/Shared/Buttons';
import { navigate } from '@reach/router';
import { SidebarButtonArea } from './styled';

function NotificationSidebar({
  notificationsOwn,
  notificationsSmbdElse,
  notificationsMentioned,
  notificationsPrivate,
  oldestSmbdElseNotificationUrl,
  oldestOwnNotificationUrl,
  oldestMentionedNotificationUrl,
  oldestPrivateNotificationUrl,
  entriesOwn,
  entriesSmbdElse,
  entriesMentioned,
  profilesWithPrivateNotification,
  conversations,
  commentsGroupedByEntryId,
  commentsWithMentionGroupedByEntryId,
  messagesGroupedByProfileId
}) {
  // the inline style on sidebar is a hack way to do the loading cursor. Needs to be re-done properly, but now I cannot think
  return (
    <>
      <div>
        <Accordion
          title="комментарии"
          notificationsCounter={notificationsOwn?.length}
          notificationsLink={oldestOwnNotificationUrl}
          notificationsTitle={'к самому раннему комментарию'}
        >
          <GroupedNotificationsForEntry
            commentsGroupedByEntryId={commentsGroupedByEntryId}
            notifications={notificationsOwn}
            entries={entriesOwn}
          />
        </Accordion>

        <Accordion
          title="дискуссии"
          notificationsCounter={notificationsSmbdElse?.length}
          notificationsLink={oldestSmbdElseNotificationUrl}
          notificationsTitle={'к самому раннему комментарию'}
        >
          <GroupedNotificationsForEntry
            commentsGroupedByEntryId={commentsGroupedByEntryId}
            notifications={notificationsSmbdElse}
            entries={entriesSmbdElse}
          />
        </Accordion>

        <Accordion
          title="упоминания"
          notificationsCounter={notificationsMentioned?.length}
          notificationsLink={oldestMentionedNotificationUrl}
          notificationsTitle={'к самому раннему упоминанию'}
        >
          <GroupedNotificationsForEntry
            commentsGroupedByEntryId={commentsWithMentionGroupedByEntryId}
            notifications={notificationsMentioned}
            entries={entriesMentioned}
          />
        </Accordion>

        <Accordion
          title="разговоры"
          notificationsCounter={notificationsPrivate?.length}
          notificationsLink={oldestPrivateNotificationUrl}
          notificationsTitle={'к самому раннему сообщению'}
        >
          <GroupedNotificationsForConversation
            profiles={profilesWithPrivateNotification}
            notifications={notificationsPrivate}
            messagesGroupedByProfileId={messagesGroupedByProfileId}
          />
          <SidebarButtonArea>
            <SecondaryButton
              onClick={() => navigate('/direct')}
              title="Перейти к разговорам"
            >
              все разговоры
            </SecondaryButton>
          </SidebarButtonArea>
        </Accordion>
      </div>

      <ResetAllNotificationsContainer />
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    notificationsOwn: selectNotificationsOwnBlog(state, { activeProfileId }),
    notificationsSmbdElse: selectNotificationsSmbdElseBlog(state, {
      activeProfileId
    }),
    notificationsMentioned: selectNotificationsMentioned(state, {
      activeProfileId
    }),
    notificationsPrivate: selectNotificationsPrivate(state, {
      activeProfileId
    }),

    entriesOwn: selectCommentedEntriesOwn(state, { activeProfileId }),
    entriesSmbdElse: selectCommentedEntriesSmbdElse(state, { activeProfileId }),
    entriesMentioned: selectEntriesWithMention(state, { activeProfileId }),
    profilesWithPrivateNotification: selectProfilesWithPrivateNotification(
      state,
      { activeProfileId }
    ),

    commentsGroupedByEntryId: selectCommentsGroupedByEntryId(state),
    commentsWithMentionGroupedByEntryId:
      selectCommentsWithMentionGroupedByEntryId(state),
    messagesGroupedByProfileId:
      selectConversationMessagesGroupedByProfileId(state),

    oldestSmbdElseNotificationUrl: selectOldestSmbdElseNotificationUrl(state, {
      activeProfileId
    }),
    oldestOwnNotificationUrl: selectOldestOwnNotificationUrl(state, {
      activeProfileId
    }),
    oldestMentionedNotificationUrl: selectOldestMentionedNotificationUrl(
      state,
      { activeProfileId }
    )
  };
};

export const NotificationsSidebarContainer = WithActiveProfileId(
  connect(mapStateToProps)(NotificationSidebar)
);
