import * as css from './ButtonsNew.module.css';
import React from 'react';
import { classNames } from 'utils/helpers/classNames';

export function Button(props) {
  return (
    <button className={css.container} {...props}>
      {props.children}
    </button>
  );
}

export function ActionButton(props) {
  return (
    <button
      className={classNames({
        [css.container]: true,
        [css.action]: true
      })}
      {...props}
    >
      {props.children}
    </button>
  );
}

export function SecondaryButton({ className, children, ...props }) {
  return (
    <button
      className={classNames({
        [css.container]: true,
        [css.secondary]: true,
        ...(className ? { [className]: true } : {})
      })}
      {...props}
    >
      {children}
    </button>
  );
}
