import React from 'react';

export const BookmarkNormalViewIcon = ({ className }) => (
  <svg
    viewBox="0 0 24 27.052"
    className={`icon icon-bookmark-normal-view ${className}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="bookmark-backside-fill secondary-filling-area"
      d="m 20.591895,1.6116624 c 0.990351,0.016046 1.794188,0.8157762 1.794188,1.7906762 l 0,4.1884894 H 18.4177 l 0,-3.8138567 c 0,-0.1474482 0.01539,-0.2909696 0.04412,-0.4301791 0.170647,-0.819645 0.819252,-1.4740672 1.643808,-1.6718225 0.01584,-0.00391 0.02998,-0.010829 0.04591,-0.013862 0.107142,-0.02342 0.219546,-0.031645 0.331986,-0.038581 0.0366,-0.00217 0.07142,-0.010426 0.108448,-0.010873 z"
    />
    <path
      className="bookmark-fill filling-area"
      d="M 0.77000933,1.398531 H 18.196536 l -0.04053,24.923985 -9.0762501,-5.871475 -8.26922277,5.587788 z"
    />
    <path
      className="bookmark main-outline"
      d="M 23.999996,7.609563 24,2.9873748 c 1e-6,-1.6183279 -1.449931,-2.94697555 -3.064371,-2.98251642 V 0 H 2.9655685 C 1.3306802,0 5.7145259e-4,1.329616 5.7145259e-4,2.9649839 V 25.753956 C -0.0213903,27.181657 0.59360058,27.181657 1.1568707,26.916023 c 2.7592155,-1.839361 5.236229,-3.490603 8.0940947,-5.39573 2.9815666,1.987592 5.3613746,3.574024 8.0940946,5.395737 0.537893,0.265625 1.156299,0.2304 1.156299,-1.158074 V 12.255743 l 0.04151,-4.65661 V 3.8138272 c 0,-0.1404616 0.01441,-0.2771814 0.04126,-0.4097934 0.159881,-0.7808032 0.752034,-1.4236277 1.524582,-1.6120118 0.153768,-0.045284 0.2954,-0.045042 0.47113,-0.040898 l 7.2e-5,4.5e-6 c 0.927877,0.015285 1.681006,0.7771187 1.681006,1.7058189 V 7.599133 Z M 3.577153,1.7943747 h 14.172254 c -0.435136,0.4258369 -0.744623,0.9791948 -0.869896,1.5984736 -0.02354,0.1037164 -0.03231,0.2116015 -0.046,0.3010599 -0.01065,0.1031393 -0.01638,0.2080451 -0.01638,0.3138405 v 7.9678383 12.45282 l -3.762586,-2.508559 -1.881293,-1.25428 -0.940647,-0.627141 c -0.7882377,-0.614246 -1.072778,-0.614246 -1.8813085,0 l -0.9406624,0.627141 -1.8813249,1.25428 -3.7626497,2.508559 V 3.6048582 c 0,-0.9981942 0.8122929,-1.8104835 1.8104915,-1.8104835 z"
    />
  </svg>
);
