export class StoreListAction {
  static type = 'STORE_LIST';
  constructor(list) {
    this.list = list;
    this.type = StoreListAction.type;
  }
}

export class ClearListsAction {
  static type = 'lists/CLEAR_LISTS';
  constructor() {
    this.type = ClearListsAction.type;
  }
}

export class StoreFavoritesAction {
  static type = 'STORE_FAVORITES_LIST';
  constructor(list) {
    this.list = list;
    this.type = StoreFavoritesAction.type;
  }
}
export class StoreReadersAction {
  static type = 'STORE_READERS_LIST';
  constructor(list) {
    this.list = list;
    this.type = StoreReadersAction.type;
  }
}

export class BanProfileAction {
  static type = 'BAN_PROFILE';
  constructor(profile) {
    this.profile = profile;
    this.type = BanProfileAction.type;
  }
}

export class UnbanProfileAction {
  static type = 'UNBAN_PROFILE';
  constructor(profile) {
    this.profileID = profile.id;
    this.type = UnbanProfileAction.type;
  }
}

export class HideProfileAction {
  static type = 'HIDE_PROFILE';
  constructor(profile) {
    this.profile = profile;
    this.type = HideProfileAction.type;
  }
}

export class UnhideProfileAction {
  static type = 'UNHIDE_PROFILE';
  constructor(profile) {
    this.profileID = profile.id;
    this.type = UnhideProfileAction.type;
  }
}

export class SubscribeToProfileAction {
  static type = 'SUBSCRIBE_TO_PROFILE';
  constructor(profile) {
    this.profile = profile;
    this.type = SubscribeToProfileAction.type;
  }
}

export class UnsubscribeFromProfileAction {
  static type = 'UNSUBSCRIBE_FROM_PROFILE';
  constructor(profile) {
    this.profileID = profile.id;
    this.type = UnsubscribeFromProfileAction.type;
  }
}
