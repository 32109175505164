import { changePasswordApi } from 'api/auth';
import { parseToken } from 'store/user/reducer/parseToken';
import { fetchUserThunk } from 'store/user/thunks/fetchUserThunk';

export const changePassword = ({ token, password, setToken }) => async (
  dispatch
) => {
  const res = await changePasswordApi({
    password,
    token
  });
  if (!res.error) {
    setToken(res.accessToken);
  } else {
    // dispatch error
    // return { error: res.error };
  }
  return res;
};

export const changePasswordWithLocalToken = async ({
  token,
  password,
  setToken
}) => async (dispatch, getState) => {
  const res = await changePasswordApi({
    password,
    token
  });

  if (!res.error) {
    setToken?.(res.accessToken);
    setTimeout(() => {
      fetchUserThunk({
        setToken,
        token: res.accessToken,
        ...parseToken(res.accessToken) // this is what I added
      })(dispatch, getState);
    }, 1000);
  } else {
    console.error(res.error);
    // dispatch error
    // return { error: res.error };
  }
  return res;
};
