import { setEntryDraft } from 'store/localStorage/drafts/useSetEntryDraft';
import { setCommentDraft } from 'store/localStorage/drafts/useSetCommentDraft';

export function runMigrationToLS(reduxState, setToken) {
  console.log('Migration to LS started...');
  if (!reduxState) {
    console.log('No persisted redux state to migrate');
    return { commentLocalStorageState: {}, entryLocalStorageState: {} };
  }
  const parsedReduxState = JSON.parse(reduxState);
  const user = JSON.parse(parsedReduxState.user ?? '{}');
  const token = user.token;

  if (token) {
    setToken(token);
  }

  const forms = JSON.parse(parsedReduxState.forms ?? '{}').byProfile;
  let commentLocalStorageState = {};
  let entryLocalStorageState = {};

  if (forms) {
    for (const [profileId, drafts] of Object.entries(forms)) {
      // entry Drafts:
      console.log('checking entries for profile ', profileId);

      for (const key of Object.keys(drafts.entryDrafts)) {
        const draft = drafts.entryDrafts[key];
        const parts = key.split('.'); // blogId.entryId

        if (parts.length === 2) {
          // this is a community entry
          const entryDraft = {
            id: parts[1],
            content: draft.content,
            title: draft.title,
            tags: draft.tags
            // I don't use spread operator here because some drafts mistakenly contain blog entity
          };
          console.log('setting entry draft', profileId, entryDraft);
          entryLocalStorageState = setEntryDraft(
            entryDraft,
            { isCommunity: true, id: parts[0] },
            profileId
          )(entryLocalStorageState);
        } else if (parts.length === 1) {
          // this is a diary entry
          const entryDraft = {
            id: key,
            content: draft.content,
            title: draft.title,
            tags: draft.tags
            // I don't use spread operator here because some drafts mistakenly contain blog entity
          };
          console.log('setting entry draft', profileId, entryDraft);
          entryLocalStorageState = setEntryDraft(
            entryDraft,
            { isCommunity: false, id: profileId },
            profileId
          )(entryLocalStorageState);
        } else {
          console.log('unexpected format of the draft entry key: ', key);
        }
      }

      // comment drafts:
      console.log('checking comments for profile ', profileId);
      for (const key of Object.keys(drafts.commentDrafts)) {
        const draft = drafts.commentDrafts[key];

        const commentDraft = {
          eid: key,
          content: draft.content
        };

        console.log('setting comment draft', profileId, commentDraft);

        commentLocalStorageState = setCommentDraft(
          commentDraft,
          profileId
        )(commentLocalStorageState);
      }
    }
  }
  console.log('Finished migration of LS');
  console.log('commentLocalStorageState', commentLocalStorageState);
  console.log('entryLocalStorageState', entryLocalStorageState);

  return { commentLocalStorageState, entryLocalStorageState };
}
