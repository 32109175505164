export default ({ colors, header, fonts }) => `
.blog-pagination,
.comment-pagination {
  line-height: 1;

  background-color: ${colors.elementsBack};
  font-family: ${fonts.elements.family};
  font-size: ${fonts.elements.size}px;
  font-weight: ${fonts.elements.weight};
  font-style: ${fonts.elements.style};
  text-decoration: ${fonts.elements.textDecoration};
  text-decoration-color: ${colors.elements};
  margin: 0px;
  padding: 10px 40px 10px 40px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

  @media (max-width: 900px) {
    .blog-pagination,
    .comment-pagination {
      padding: 10px 20px 10px 20px;
    }
  }

  @media (max-width: 650px) {
    .blog-pagination,
    .comment-pagination {
    padding: 10px 10px 10px 10px;
  }
}


/******  PAGE NUMBERS ******/

.blog-pagination .page-numbers a,
.blog-pagination .page-numbers a:visited,
.blog-pagination .page-numbers span,
.comment-pagination .page-numbers a,
.comment-pagination .page-numbers a:visited,
.comment-pagination .page-numbers span {
    display: inline-block;
    padding: 4px 10px;
    margin: 0 2px 0 2px;
    color: ${colors.elements};

}

.blog-pagination .page-numbers a,
.blog-pagination .page-numbers a:visited,
.comment-pagination .page-numbers a,
.comment-pagination .page-numbers a:visited {
  border: 1px solid transparent;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.blog-pagination .page-numbers a:hover,
.blog-pagination .page-numbers a:active,
.blog-pagination .page-numbers a:focus,
.comment-pagination .page-numbers a:hover,
.comment-pagination .page-numbers a:active,
.comment-pagination .page-numbers a:focus {
  color: ${colors.elements};
  border: 1px solid ${colors.accent};
}

.blog-pagination div a,
.blog-pagination div a:visited,
.blog-pagination div a:hover,
.blog-pagination div a:active,
.blog-pagination div a:focus,
.comment-pagination div a,
.comment-pagination div a:visited,
.comment-pagination div a:hover,
.comment-pagination div a:active,
.comment-pagination div a:focus  {
    border: none;
}

@media (max-width: 650px) {
  .page-numbers {
    margin-top: -10px;
  }
  .blog-pagination span.current,
  .comment-pagination span.current,
  .blog-pagination .page-numbers a,
  .blog-pagination .page-numbers a:visited,
  .comment-pagination .page-numbers a,
  .comment-pagination .page-numbers a:visited {
    margin-top: 10px;
    padding: 4px 7px;
  }
}

/******  CURRENT PAGE ******/

.blog-pagination span.current,
.comment-pagination span.current {
  border: 1px solid ${colors.accent};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: bold;
}

/******  NEXT/PREVIOUS LINKS ******/

.blog-pagination .previous,
.comment-pagination .previous  {
  width: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.blog-pagination .next,
.comment-pagination .next  {
  width: 150px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.blog-pagination .previous a,
.blog-pagination .next a,
.comment-pagination .previous a,
.comment-pagination .next a {
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
}

.blog-pagination .previous a,
.blog-pagination .next a,
.blog-pagination .previous span,
.blog-pagination .next span,
.comment-pagination .previous a,
.comment-pagination .next a,
.comment-pagination .previous span,
.comment-pagination .next span  {
  padding: 0px;
  margin: 0px;
  font-weight: ${fonts.elements.weight};
  color: ${colors.elements};
}

.blog-pagination .previous span,
.blog-pagination .next span,
.comment-pagination .previous span,
.comment-pagination .next span {
  padding-bottom: calc(1ex/3.5);
}

@media (max-width: 650px) {
  .blog-pagination .previous-word,
  .blog-pagination .next-word,
  .comment-pagination .previous-word,
  .comment-pagination .next-word {
    display: none;
  }
}

@media (max-width: 850px) {
  .blog-pagination .next,
  .blog-pagination .previous,
  .comment-pagination .next,
  .comment-pagination .previous {
    width: 40px;
  }

}

/******  ARROW ICONS ******/

.blog-pagination .icon-simple-arrow-left,
.blog-pagination .icon-simple-arrow-right,
.comment-pagination .icon-simple-arrow-left,
.comment-pagination .icon-simple-arrow-right  {
  height: ${fonts.elements.size}px;
  width:  ${fonts.elements.size}px;
}

.blog-pagination .icon-simple-arrow-left,
.comment-pagination .icon-simple-arrow-left  {
  margin-right: 20px;
}

.blog-pagination .icon-simple-arrow-right,
.comment-pagination .icon-simple-arrow-right  {
  margin-left: 20px;
}

.blog-pagination .icon .main-outline,
.comment-pagination .icon .main-outline {
  fill: ${colors.elements};
}
`;
