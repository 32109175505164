import React from 'react';
import styled from 'styled-components';
import theme from 'styles/DybrTheme';
import Pagination from 'components/Shared/Pagination';

const StyledPagination = styled.div`
  width: 100%;
  line-height: 1;
  font-size: 14px;
  background-color: ${(p) => p.backgroundColor ?? theme.backgroundLight};
  margin: 0px;
  padding: 10px 40px 10px 40px;
  text-align: center;
  display: flex;
  justify-content: space-between;

  @media (max-width: 900px) {
    padding: 10px 20px 10px 20px;
  }

  @media (max-width: 650px) {
    padding: 10px 10px 10px 10px;
  }

  /******  PAGE NUMBERS ******/

  .page-numbers a:link,
  .page-numbers a:visited,
  .page-numbers span {
    display: inline-block;
    padding: 4px 10px;
    margin: 0 2px 0 2px;
    color: ${theme.text};
    text-decoration: none;
  }

  .page-numbers {
    @media (max-width: 650px) {
      margin-top: -10px;
    }
  }

  .page-numbers a:link,
  .page-numbers a:visited {
    border: 1px solid transparent;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    @media (max-width: 650px) {
      margin-top: 10px;
    }
  }

  .page-numbers a:hover,
  .page-numbers a:active,
  .page-numbers a:focus {
    color: ${theme.text};
    border: 1px solid ${theme.brand};
  }

  div a:link,
  div a:visited,
  div a:hover,
  div a:active,
  div a:focus {
    border: none;
  }

  /******  CURRENT PAGE ******/

  span.current {
    border: 1px solid ${theme.brand};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-weight: bold;
  }

  /******  NEXT/PREVIOUS LINKS ******/

  .previous {
    width: 150px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 850px) {
      width: 40px;
    }
  }

  .next {
    width: 150px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 850px) {
      width: 40px;
    }
  }

  .previous a,
  .next a {
    display: flex;
    text-decoration: none;
    align-items: center;
    height: 100%;
  }

  .previous a,
  .next a,
  .previous span,
  .next span {
    padding: 0px;
    margin: 0px;
    font-weight: bold;
    color: ${theme.accentDark};
  }

  .previous .previous-word {
    padding-bottom: calc(1ex / 3.5);

    @media (max-width: 850px) {
      display: none;
    }
  }

  .next .next-word {
    padding-bottom: calc(1ex / 3.5);

    @media (max-width: 850px) {
      display: none;
    }
  }

  /******  ARROW ICONS ******/

  .icon-simple-arrow-left,
  .icon-simple-arrow-right {
    height: 20px;
    width: 20px;
  }

  .icon-simple-arrow-left {
    margin-right: 20px;
  }

  .icon-simple-arrow-right {
    margin-left: 20px;
  }

  .icon {
    .main-outline {
      fill: ${theme.textLight};
    }
  }
`;

const DybrPagination = (props) => {
  const wrap = (pagination) => (
    <StyledPagination backgroundColor={props.backgroundColor}>
      {pagination}
    </StyledPagination>
  );
  return <Pagination {...props} wrap={wrap} />;
};

export default DybrPagination;
