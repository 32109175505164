import { StoreDesignsAction } from 'store/designer/actions';
import { getProfileDesignsAPI } from 'api/designs';

export const loadDesigns = ({ token, activeProfileId }) => async (dispatch) => {
  const res = await getProfileDesignsAPI({
    profileId: activeProfileId,
    token
  });

  if (!res.error) {
    dispatch(new StoreDesignsAction(res.data));
  } else {
    // dispatch error
  }
};
