import React from 'react';
import styled from 'styled-components';
import FieldWrapper from './StyledFieldWrapper';

const RadioGroupTitle = styled.p`
  color: ${props => props.theme.textLight};
  margin: 0;
  margin-bottom: 10px;
`;

const RadioGroupWrapper = styled(FieldWrapper)`
  justify-content: flex-start;
  margin-bottom: 18px;

  input:checked + label {
    font-weight: bold;
  }

  @media (min-width: 1500px) {
    justify-content: flex-start;
  }
`;

const StyledRadio = styled.input`
  &:checked,
  &:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  &:checked + label,
  &:not(:checked) + label {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    line-height: 15px;
    display: inline-block;
  }

  &:checked + label:before,
  &:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 12px;
    height: 12px;
    border: 1px solid ${props => props.theme.backgroundDark};
    border-radius: 100%;
    background: ${props => props.theme.backgroundLighter};
  }

  &:checked + label:after,
  &:not(:checked) + label:after {
    content: '';
    width: 8px;
    height: 8px;
    background: ${props => props.theme.accent};
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 100%;
  }

  &:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  &:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
`;

const StyledLabel = styled.label``;

function RadioGroup({ title, name, checked, items, onCheck }) {
  return (
    <>
      {title && <RadioGroupTitle>{title}</RadioGroupTitle>}
      {items.map((item, i) => (
        <React.Fragment key={item.radioValue ?? i}>
          <RadioGroupWrapper
            onClick={() => {
              if (item.radioValue !== checked) {
                onCheck(item.value, item.radioValue);
              }
            }}
          >
            <StyledRadio
              type="radio"
              name={name}
              onChange={() => {}}
              value={item.value ?? ''}
              checked={item.radioValue === checked}
            />
            <StyledLabel htmlFor={item.radioValue}>{item.label}</StyledLabel>
          </RadioGroupWrapper>

          {item.radioValue === checked && item.component?.()}
        </React.Fragment>
      ))}
    </>
  );
}

export default RadioGroup;
