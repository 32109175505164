import React from 'react';

const Icon = ({ className }) => (
  <svg
    className={`icon icon-simple-arrow-right-with-background ${className}`}
    viewBox="0 0 21.912875 21.912875"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="circle main-outline"
      d="M 4.3045046e-7,10.956437 A 10.956437,10.956437 0 0 1 10.956438,4.3045045e-7 10.956437,10.956437 0 0 1 21.912874,10.956437 10.956437,10.956437 0 0 1 10.956438,21.912874 10.956437,10.956437 0 0 1 4.3045046e-7,10.956437 Z"
    />
    <path
      className="arrow main-outline"
      d="m 8.2147954,15.709813 c -0.02579,0.243288 0.04811,0.493155 0.242188,0.669922 0.299913,0.302609 0.817964,0.329692 1.146484,0.05859 1.4789216,-1.435059 2.9443196,-2.885442 4.4179696,-4.326172 0.310404,-0.336571 0.801799,-0.629235 0.736328,-1.156251 0.06548,-0.527029 -0.425881,-0.819724 -0.736328,-1.1562496 -1.4736,-1.440447 -2.939187,-2.889335 -4.4179696,-4.324214 -0.328296,-0.27137 -0.846321,-0.245721 -1.146484,0.05664 -0.388166,0.353534 -0.294886,1.000812 0.113281,1.296874 1.405826,1.374742 2.8070116,2.754372 4.2148446,4.1269536 -1.407833,1.372583 -2.8090186,2.752213 -4.2148446,4.126954 -0.204083,0.148032 -0.329682,0.383665 -0.355469,0.626953 z"
    />
  </svg>
);

export default Icon;
