import React from 'react';

const Icon = ({ className }) => (
  <svg
    height="26"
    width="26"
    viewBox="-1 -1 31 36"
    className={`icon icon-journal ${className}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="journal-inside filling-area"
      d="m 7.0238615,0 c -0.463323,0 -0.850249,0.43602 -0.850249,0.932155 v 25.509406 c 0,0.486938 0.386926,0.889253 0.850249,0.889253 h 9.1287955 4.892829 3.84562 c 2.943424,0 4.108889,-1.878589 4.108889,-4.18104 V 4.186889 C 28.999953,1.884434 27.837103,0 24.891106,0 h -3.84562 z"
    />
    <path
      className="journal-pencil main-outline"
      d="M 0.00171853,5.432857 H 3.7988063 V 27.314776 H 0.00171853 Z"
    />
    <path
      className="journal-pencil-tip main-outline"
      d="M 1.8919907,0.00107548 C 1.8503679,0.00387926 1.8126319,0.03315108 1.7916506,0.0785475 L 0.01698652,3.9647527 C -0.03097595,4.068904 0.02830732,4.1995838 0.12374169,4.199697 H 3.6735643 C 3.7688854,4.199614 3.8282819,4.068904 3.7803195,3.9647527 L 2.0056553,0.0785475 C 1.982259,0.02775483 1.938372,-0.00216982 1.8919945,0.00107548 Z"
    />
    <path
      className="journal-outline filling-outline"
      d="M 7.0238615,0 C 6.5605388,0 6.1736126,0.43602072 6.1736126,0.93215489 V 26.441561 c 0,0.486938 0.3869262,0.889253 0.8502489,0.889253 h 2.5643958 v 5.854232 c 2.604e-4,-0.02566 0.00943,-0.05106 0.029434,-0.07604 l 3.0675227,-3.886571 c 0.03623,-0.04555 0.101585,-0.07517 0.173548,-0.078 0.08023,-0.0032 0.156566,0.02717 0.196944,0.078 l 3.067526,3.886571 c 0.02,0.02491 0.02906,0.05019 0.02943,0.07604 v -5.854232 h 4.892829 3.84562 C 27.834535,27.330814 29,25.452225 29,23.149774 V 4.1868894 C 28.999958,1.8844344 27.837108,0 24.891111,0 h -3.84562 z M 7.9287144,1.6361428 H 24.890783 c 1.399839,0 2.501991,1.1252123 2.501991,2.5507466 V 23.149774 c 0,1.42553 -1.102152,2.552697 -2.501991,2.552697 H 7.9287144 Z"
    />
  </svg>
);

export default Icon;
