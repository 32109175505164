import { StyledStatusList } from 'scenes/User/Settings/Styled';
import moment from 'moment';
import React from 'react';

function getFileStatus(s) {
  switch (s.status) {
    case 'upload':
      return 'в очереди';
    case 'unpacked':
      return 'импортируется';
    case 'error':
      if (s.error === 'unpack_failed') return 'ошибка распаковки файла';
      if (s.error === 'use_dybr_importer')
        return 'ошибка: архив должен быть создан нашей программой';
      return 'ошибка импорта';
    case 'done':
    case 'tags_updated':
      return 'импорт завершен';
    default:
      return '';
  }
}

function UploadStatuses({ importStatuses }) {
  if (!importStatuses.length) {
    return null;
  }

  return (
    <StyledStatusList>
      <tr>
        <th>Дата и время</th>
        <th>Файл</th>
        <th>Статус</th>
      </tr>
      {importStatuses.map((s) => (
        <tr>
          <td>{moment.unix(s.createdAt).format('DD.MM.YYYY HH:mm')}</td>
          <td>{s.source.split('\\').pop().split('/').pop()}</td>
          <td>{getFileStatus(s)}</td>
        </tr>
      ))}
    </StyledStatusList>
  );
}

export default UploadStatuses;
