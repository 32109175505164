// on the main page for guest users there is a header with a bigger logo and mission statement
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Link } from '@reach/router';
import theme from 'styles/DybrTheme';
import logo from 'styles/Logo.svg';
import background from 'styles/img/wide_header_bg.png';

// import LogInForm from 'scenes/LogIn';

const TopBar = styled.header`
  background-color: ${props => props.theme.backgroundDarker};
  background: url(${background});
  background-size: cover;
  image-rendering: -moz-crisp-edges;
  color: ${props => props.theme.textLighter};
  width: 100%;
`;

const Container = styled.div`
  height: ${props => props.height};
  max-width: 1180px;

  margin: 0 auto;
  height: ${props => (props.big ? '150px' : '60px')};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  @media (max-width: 600px) {
    justify-content: center;
    padding: 15px 20px;
  }
  @media (max-width: 450px) {
    justify-content: flex-start;
    padding: 25px 70px;
  }

  a {
    display: inline-block;

    @media (max-width: 450px) {
      text-align: center;
    }
  }

  h2 {
    font-family: 'Montserrat Alternates', sans-serif;
    text-transform: lowercase;
    font-weight: bold;
    text-align: right;
    max-width: 200px;
    line-height: 1.4;
    font-size: 24px;
    flex: 1 0 100px;

    @media (max-width: 600px) {
      flex: 1 0 300px;
      max-width: 100%;
      text-align: center;
      line-height: 1;
      font-size: 20px;
    }
    @media (max-width: 450px) {
      max-width: 200px;
      text-align: left;
    }
  }

  form {
    display: flex;
    position: relative;
    font-size: 16px;

    div.form-input-wrapper {
      margin-right: 30px;
      padding-bottom: 35px;
    }

    input {
      color: ${props => props.theme.textLight};
      padding: 10px 2px;
      font-size: 14px;
      width: 190px;
    }

    .form-error {
      position: absolute;
      font-size: 12px;
    }

    div.buttons {
      display: flex;
      width: 140px;
      flex-wrap: wrap;
      align-items: flex-end;

      button {
        margin: auto;
        width: 110px;
        height: 45px;
        font-size: 14px;
        text-transform: lowercase;
      }
      button:first-child {
        font-weight: bold;
      }
      button:last-child {
        width: 140px;
        height: 20px;
        padding: 0;
        font-weight: normal;
      }
    }
  }
`;

const Logo = styled.img`
  margin-bottom: 0;
  width: ${props => (props.big ? '305px' : '150px')};
  height: ${props => (props.big ? '63px' : '31px')};
  margin-top: 6px;

  @media (max-width: 600px) {
    max-width: 80%;
    margin-bottom: 20px;
  }

  @media (max-width: 450px) {
    margin-bottom: -5px;
    margin-top: 0;
    max-width: 184px;
    height: 37px;
  }
`;

class Header extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <TopBar>
          <Container big>
            <Link to="/">
              <Logo src={logo} big alt="Dybr logo" />
            </Link>
            {/*<h2>БЛОГИ ДЛЯ ВСЕХ И ДЛЯ ВСЕГО</h2>*/}
          </Container>
        </TopBar>
      </ThemeProvider>
    );
  }
}

export default Header;
