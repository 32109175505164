import styled from 'styled-components';

export const Button = styled.button`
  padding: 16px;
  color: ${(props) => props.theme.text};
  background: ${(props) => props.theme.backgroundLighter};
  font-weight: bold;
  border: none;
  border-radius: 0px;
  text-transform: lowercase;
  font-size: 16px;
  text-align: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  width: ${(props) => props.width || 'auto'};
  &:disabled {
    opacity: 0.5;
  }
`;

export const ActionButton = styled(Button)`
  background: ${(props) => props.theme.accent};
  color: ${(props) => props.theme.textLighter};
  padding: 15px 30px;
  font-size: 16px;
  font-weight: bold;
  flex-shrink: 0;
`;

export const SecondaryButton = styled(Button)`
  background: ${(props) => props.theme.backgroundDark};
  color: ${(props) => props.theme.textLighter};
  font-weight: bold;
  padding: 15px 30px;
  font-weight: bold;
  font-size: 16px;
`;

export const ActionButtonSmall = styled(Button)`
  background: ${(props) => props.theme.accent};
  color: ${(props) => props.theme.textLighter};
  padding: 13.5px 30px;
  font-size: 14px;
  font-weight: bold;
`;

export const ImportantButton = styled(Button)`
  color: ${(props) => props.theme.textLighter};
  background: ${(props) => props.theme.brand};
  padding: 15px 30px;
  font-size: 16px;
  font-weight: bold;
  flex-shrink: 0;
`;

// a button that looks like a link
export const LinkButton = styled(Button)`
  color: ${(props) => props.theme.textLight};
  text-decoration: underline;
  background: transparent;
  font-weight: normal;
  font-size: 12px;
`;

// links that look like buttons
export const ButtonLink = styled.a`
  display: block;
  background: ${(props) => props.background || props.theme.backgroundLighter};
  color: ${(props) => props.color || props.theme.text} !important;
  max-width: ${(props) => props.width};
  margin: 25px auto;
  padding: 10px 15px;
  text-align: center;
  text-transform: lowercase;
  text-decoration: none;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;
export const ButtonLinkGray = styled(Button)`
  background: ${(props) => props.theme.backgroundDark};
  color: ${(props) => props.theme.textLighter} !important;
  width: auto;
  display: inline-block;
`;
