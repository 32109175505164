import React from 'react';

const Icon = ({ className }) => (
  <svg
    className={`icon icon-simple-arrow-up ${className}`}
    viewBox="0 0 12 7"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="arrow main-outline"
      d="m 10.422309,6.5489037 c 0.243288,0.025786 0.493155,-0.048104 0.669922,-0.2421875 C 11.39484,6.006803 11.421924,5.4887517 11.150821,5.1602318 9.7157671,3.681311 8.2653833,2.2159132 6.8246534,0.74226308 6.4880836,0.43185948 6.1954191,-0.05953582 5.6684034,0.00593498 5.1413747,-0.05954862 4.8486792,0.43181628 4.5121534,0.74226308 3.0717023,2.215863 1.6228148,3.6814506 0.18793467,5.1602318 c -0.27137,0.328296 -0.245721,0.8463214 0.05664,1.1464844 C 0.59810867,6.6948822 1.2453867,6.6016019 1.5414498,6.193435 2.9161915,4.7876092 4.2958217,3.3864243 5.6684034,1.9785912 7.0409851,3.3864243 8.4206151,4.7876092 9.7953568,6.193435 9.9433885,6.3975185 10.179022,6.5231174 10.422309,6.5489037 Z"
    />
  </svg>
);

export default Icon;
