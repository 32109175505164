import React, { Fragment, useState } from 'react';
import {
  Child,
  FormElementWrapper,
  TextFieldWithButton
} from 'scenes/Blog/designer/components/styled';
import ColorSetting from 'scenes/Blog/designer/components/ColorSetting';
import FontSettings from 'scenes/Blog/designer/components/FontSettings';
import Switch from 'components/FormElements/Switch';
import TextField from 'components/FormElements/TextField';
import DropzoneImage from 'scenes/Blog/designer/DropzoneImage';
import SelectBackgroundDisplay from 'scenes/Blog/designer/components/BackgroundDisplaySetting';

export const HeaderSettingsTab = ({
  design,
  changeSetting,
  toggleSetting,
  handleDrop
}) => {
  const [headerImageLink, setHeaderImageLink] = useState(
    design.header.image.url
  );

  const updateHeaderImageLink = (e) => {
    if (headerImageLink !== design.header.image.url) {
      changeSetting('header.image.url')(e);
    }
  };

  return (
    <Fragment>
      <Child className="extra-space">
        <ColorSetting
          label="цвет фона"
          value={design.header.background}
          onChange={changeSetting('header.background')}
        />
        <ColorSetting
          label="цвет текста"
          value={design.header.color}
          onChange={changeSetting('header.color')}
        />
      </Child>
      <Child>
        <FontSettings
          label="название дневника"
          setting={design.header.font}
          update={changeSetting}
          nodeKey={'header.font'}
        />
      </Child>

      <Child>
        <FormElementWrapper>
          <Switch
            label="использовать фоновое изображение"
            checked={design.header.image.enable}
            onClick={toggleSetting('header.image.enable')}
          />
        </FormElementWrapper>
        {design.header.image.enable && (
          <Fragment>
            <TextFieldWithButton>
              <TextField
                label="ссылка"
                value={headerImageLink}
                touched={false}
                onChange={(e) => {
                  setHeaderImageLink(e.target.value);
                }}
                onBlur={updateHeaderImageLink}
              />
              <DropzoneImage
                maxSize={1 * 1024 * 1024}
                onAccepted={handleDrop('header.image.url')}
              />
            </TextFieldWithButton>

            <FormElementWrapper>
              <div className="label-section">отображение фона</div>
              <SelectBackgroundDisplay
                setting={design.header.image}
                update={changeSetting}
                nodeKey={'header.image'}
                types={'headerImageDisplayTypes'}
              />
            </FormElementWrapper>
          </Fragment>
        )}
      </Child>
    </Fragment>
  );
};
