import { useEntryDraftState } from 'store/localStorage/drafts/useDraftState';

export const useEntryDraftById = ({ activeProfileId, entryId, blog }) => {
  const [draft] = useEntryDraftState();

  const entryDrafts = draft?.[activeProfileId] ?? {};

  if (blog.isCommunity && entryId === '0') {
    return entryDrafts[`${blog.id}.${entryId}`] ?? {};
  }

  return entryDrafts[entryId] ?? {};
};
