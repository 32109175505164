import styled from 'styled-components';
import { IconButton } from 'components/Shared/IconButton.style';

export const AddToFavoritesStyled = styled(IconButton)`
  border: 1px solid ${(props) => props.theme.accent};

  --main-color: ${(p) => p.theme.backgroundLighter};
  --fill-color: ${(p) => p.theme.backgroundLighter};

  && .icon {
    .main-outline,
    .filling-outline {
      fill: var(--main-color);
    }
    .filling-area {
      opacity: ${(p) => (p.active ? '1' : '0')};
      fill: var(--fill-color);
    }
    :hover .favorites-star {
      fill: ${(props) => {
        if (props.sidebarMode) {
          return props.theme.colors.accent;
        }
        return props.theme.accent;
      }};
    }
  }
`;
