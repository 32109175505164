import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const RequiredSymbol = styled.span`
  font-size: 22px;
  color: ${p => (p.isRequired ? p.theme.brandDark : p.theme.accent)};
  position: absolute;
  top: ${p => (p.label ? '18px;' : '4px;')};
  left: -16px;
`;

const Error = styled.span`
  font-size: 12px;
  color: ${props => props.theme.brand};
  position: absolute;
  top: 34px;
`;

const Subscript = styled.span`
  height: 30px;
`;

const TextPrefix = styled.span`
  color: ${props => props.theme.text};
  position: relative;
  top: 6px;
  margin-right: 3px;
`;
/*
const HintSign = styled.span`
  color: ${props => props.theme.darkGray};
  position: relative;
  bottom: 13px;
`;*/

const Counter = styled.span`
  color: ${p => (p.error ? p.theme.brandDark : p.theme.textLight)};
  position: absolute;
  top: 34px;
`;

const TextFieldWrapper = styled.div`
  flex-wrap: nowrap;
  display: flex;
  width: 100%;
  font-size: 14px;
  margin-bottom: 20px;
  padding-top: 15px;
  position: relative;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const LabelErrorWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledTextField = styled.input.attrs(props => ({
  id: props.id
}))`
  height: 30px;
  width: calc(100%);
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid
    ${props =>
      (props.touched &&
        (props.error ? props.theme.brandDark : props.theme.accent)) ||
      props.theme.backgroundDarker};
  padding-bottom: 3px;
  font-weight: bold;
  font-size: 1em;
  color: ${props => props.theme.text};
  background: transparent;
  -webkit-appearance: none;
  border-radius: 0;

  :hover,
  :focus {
    outline: none;
    border-color: ${props => props.theme.accent};
  }

  &::placeholder {
    color: ${props => props.theme.textLight};
    font-size: 1em;
    font-weight: normal;
    font-family: ${props => props.theme.textFont};
  }

  &:focus + label {
    transform: translate(0, -1.3em);
    font-size: 12px;
    font-family: ${props => props.theme.textFont};
    font-weight: normal;
  }

  ${props =>
    props.value &&
    `
    + label {
      transform: translate(0, -1.3em);
      font-size: 12px;
      font-family: ${props.theme.textFont};
      font-weight: normal;
    }
  `};

  &:-webkit-autofill + label {
    transform: translate(0, -1.3em);
    font-size: 12px;
    font-family: ${props => props.theme.textFont};
    font-weight: normal;
  }
`;

const StyledLabel = styled.label`
  color: ${props => props.theme.textLight};
  position: absolute;
  transform: translate(0, 6px);
  top: -2px;
  font-size: 1em;
  pointer-events: none;
  transition: 0.2s;
`;

const propTypes = {
  isRequired: PropTypes.bool,
  prefix: PropTypes.string,
  withCounter: PropTypes.bool,
  placeholder: PropTypes.string,
  minLen: PropTypes.number,
  maxLen: PropTypes.number,
  type: PropTypes.oneOf(['text', 'password', 'email', '']),
  label: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  onChange: PropTypes.func
};

const defaultProps = {
  isRequired: false,
  prefix: '',
  withCounter: false,
  placeholder: '',
  minLen: 0,
  maxLen: 0,
  type: 'text',
  input: {},
  label: '',
  error: '',
  touched: false,
  onChange: () => {}
};

/*{
  isRequired,
  prefix,
  withCounter,
  placeholder,
  input,
  maxLen,
  type,
  label,
  touched,
  error,
  onChange,
  onBlur,
  value,
}*/
const TextField = ({
  isRequired,
  prefix,
  input,
  minLen,
  maxLen,
  label,
  touched,
  error,
  value,
  id,
  className,
  ...props
}) => {
  return (
    <div className={className ? className : ''}>
      <TextFieldWrapper className="text-field">
        {isRequired && (
          <RequiredSymbol
            touched={touched}
            error={error}
            label={label}
            isRequired={isRequired}
          >
            *
          </RequiredSymbol>
        )}
        {prefix && (
          <TextPrefix className="prefix-label" label={label}>
            {prefix}
          </TextPrefix>
        )}
        <LabelErrorWrapper>
          <StyledTextField
            touched={touched}
            error={error}
            value={value}
            id={id}
            {...props}
          />
          <StyledLabel htmlFor={id}>{label}</StyledLabel>
          <Subscript>
            {error.length ? (
              <Error>{error}</Error>
            ) : (
              maxLen > 0 && (
                <Counter
                  touched={touched}
                  error={error}
                  isRequired={isRequired}
                >
                  {value.length}/{maxLen} символов
                </Counter>
              )
            )}
          </Subscript>
        </LabelErrorWrapper>
      </TextFieldWrapper>
    </div>
  );
};

TextField.propTypes = propTypes;
TextField.defaultProps = defaultProps;

export default TextField;
