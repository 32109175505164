// Main page

import React from 'react';
import { Link } from '@reach/router';

import styled from 'styled-components';
import theme from 'styles/DybrTheme';
import { DybrPageWrapper } from 'components/DybrComponents/Wrappers';

import HeadedBlock from 'components/DybrComponents/HeadedBox';
import {
  Section,
  SectionLargerBlock,
  SectionSmallerBlock
} from 'components/DybrComponents/Section';

import NewsBlock from './components/DybrNews';

import { InterestingList } from './components/InterestingList';

import { loggedInInfo } from './components/StaticTexts';
import { connect } from 'react-redux';
import { selectActiveProfileAugmented } from 'store/user/selectors/selectActiveProfileAugmented';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';

const ActionBlock = styled.div`
  padding: 0 0 0 100px;
  @media (max-width: 800px) {
    padding: 0;
  }
`;

const ButtonLink = styled.div`
  a {
    display: block;
    background: ${(props) => props.background || props.theme.backgroundLighter};
    color: ${(props) => props.color || props.theme.text} !important;
    max-width: ${(props) => props.width};
    margin: 25px auto;
    padding: 10px 15px;
    text-align: center;
    text-transform: lowercase;
    text-decoration: none;
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
`;

class MainLoggedIn extends React.Component {
  state = { scroll: false };
  componentDidMount() {
    this.setState({ scroll: true });
    window.addEventListener('scroll', this.scrollFalse);
    document.title = 'Дыбр';
  }
  scrollFalse = () => {
    this.setState({ scroll: false });
    window.removeEventListener('scroll', this.scrollFalse);
  };
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollFalse);
  }

  render() {
    if (this.state.scroll) window.scrollTo(0, 0);

    // const { loading, getActiveProfile } = this.props.user;
    // if (loading) return null;

    const { activeProfile } = this.props;
    if (!activeProfile) return null;

    return (
      <DybrPageWrapper>
        <Section>
          <SectionLargerBlock>
            <NewsBlock height={'272px'} />
          </SectionLargerBlock>
          <SectionSmallerBlock>
            <ActionBlock data-testid="salutation-section">
              <HeadedBlock title="Привет!" dark>
                <div>
                  Спасибо, что зашли на огонёк! Держите быстрые ссылки на самое
                  важное.
                </div>
                <ButtonLink>
                  <Link to={`/settings`}>К настройкам</Link>
                </ButtonLink>
                <ButtonLink>
                  <Link to="/feed">ЧИТАТЬ ОБЩУЮ ЛЕНТУ</Link>
                </ButtonLink>
                <ButtonLink>
                  <Link to={`/blog/${activeProfile.blogSlug}/0/edit`}>
                    Написать новую запись
                  </Link>
                </ButtonLink>
              </HeadedBlock>
            </ActionBlock>
          </SectionSmallerBlock>
        </Section>
        <Section
          backgroundColor={theme.backgroundLighter}
          withBackground
          title="Важная информация"
          lastSection
        >
          <InterestingList data={loggedInInfo} />
        </Section>
      </DybrPageWrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    activeProfile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

export const MainLoggedInContainer = WithActiveProfileId(
  connect(mapStateToProps)(MainLoggedIn)
);
