import styled from 'styled-components';

export const Page = styled.div`
  width: 100%;
  min-width: ${p => p.theme.minWidth};
  min-height: 150vh;
  position: relative;
  background-color: ${props => props.theme.colors.background};
`;

export const Container = styled.div`
  width: 100%;
  min-height: 150vh;
  margin-bottom: ${p => (p.designer ? '355px' : 'auto')};
  ${p => {
    return p.designer && p.designerPreviewMode ? 'margin-bottom: 55px' : '';
  }};

  padding-bottom: 65px;
  display: flex;
  justify-content: flex-start;

  &&.right {
    justify-content: flex-end;
  }
`;

export const ContentContainer = styled.div`
  padding-top: 30px;
  width: 100%;
  max-width: ${p => p.theme.layout.maxWidth}px;
  min-width: ${p => p.theme.minWidth};

  .left > &&,
  .right > && {
    margin: 0 40px 0 40px;
  }

  .center > && {
    margin: 0 auto;
  }

  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  @media (max-width: ${p => p.theme.layout.maxWidth + 80}px) {
    .left > &&,
    .right > &&,
    .center > && {
      margin: 0 15px 0 15px;
    }
  }

  @media (max-width: 600px) {
    .left > &&,
    .right > &&,
    .center > && {
      margin: 0;
    }
  }
`;

export const Header = styled.div`
  width: 100%;

  &&.left .blog-header--main,
  &&.right .blog-header--main,
  &&.left .blog-header--thin,
  &&.right .blog-header--thin {
    padding-left: 40px;
    padding-right: 40px;

    @media (max-width: ${p => p.theme.layout.maxWidth + 80}px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &&.right .blog-header--thin,
  &&.right .blog-header--main {
    align-items: flex-end;
  }

  &&.right .blog-header--thin {
    @media (max-width: ${p => p.theme.layout.maxWidth + 30}px) {
      align-items: center;
    }
  }

  &&.center .blog-header--main .blog-header-title,
  &&.center .blog-header--main .blog-header-profile {
    text-align: center;
    padding-right: 65px;
    padding-left: 65px;
  }

  &&.left .blog-header--main .blog-header-title,
  &&.right .blog-header--main .blog-header-title,
  &&.left .blog-header--main .blog-header-profile,
  &&.right .blog-header--main .blog-header-profile {
    padding-right: 40px;
    padding-left: 40px;
  }

  &&.right .blog-header--main .blog-header-title,
  &&.right .blog-header--main .blog-header-profile {
    text-align: right;
    padding-left: 65px;
    @media (max-width: 650px) {
      padding-right: 15px;
    }
    @media (max-width: 600px) {
      padding-right: 0;
    }
  }

  &&.left .blog-header--main .blog-header-title,
  &&.left .blog-header--main .blog-header-profile {
    padding-right: 65px;
    @media (max-width: 650px) {
      padding-left: 15px;
    }
    @media (max-width: 600px) {
      padding-left: 0;
    }
  }

  &&.left .blog-header--thin .blog-header-avatar,
  &&.right .blog-header--thin .blog-header-avatar,
  &&.left .blog-header--thin .blog-header-profile,
  &&.right .blog-header--thin .blog-header-profile {
    margin: 0;
    width: ${p => p.theme.layout.maxWidth}px;
    display: flex;
    justify-content: center;

    @media (max-width: ${p => p.theme.layout.maxWidth + 30}px) {
      width: auto;
      padding-right: 65px;
      padding-left: 65px;
    }
  }

  &&.right .blog-header-tabs {
    display: flex;
    justify-content: flex-end;

    @media (max-width: ${p => p.theme.layout.maxWidth + 30}px) {
      justify-content: center;
    }
  }

  &&.center .blog-header-tabs {
    text-align: center;
  }

  &&.right .blog-header-tabs,
  &&.left .blog-header-tabs {
    padding-left: 40px;
    padding-right: 40px;

    @media (max-width: ${p => p.theme.layout.maxWidth + 80}px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &&.right .blog-header-tabs-position,
  &&.left .blog-header-tabs-position {
    width: ${p => p.theme.layout.maxWidth}px;
    display: flex;
    justify-content: center;

    @media (max-width: ${p => p.theme.layout.maxWidth + 30}px) {
      width: auto;
    }
  }
`;

export const Epigraph = styled.div`
  width: 100%;
`;

/*
const Aside = styled.div`
  min-width: 250px;
  width: 25%;
  padding: 0 7px;
  flex: 0 1 auto;
`; */

export const Content = styled.div`
  width: 100%;
  flex: 0 1 auto;
`;

export const LayoutBlock = styled.div`
  width: 100%;
`;
