import React, { useState } from 'react';
import {
  Block,
  Settings,
  SettingSectionTitle,
  SettingsFlexbox
} from 'components/DybrComponents/StyledServicePagesElements';
import { connect } from 'react-redux';
import { selectActiveProfileAugmented } from 'store/user/selectors/selectActiveProfileAugmented';
import { updateProfileThunk } from 'store/user/thunks/updateProfileThunk';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';
import { ActionButton } from 'components/Shared/Buttons';
import {
  useActiveProfile,
  useTokenState
} from 'store/localStorage/useTokenState';
import TextField from 'components/FormElements/TextField';

function ChangeBlog({ profile, updateProfileThunk }) {
  const [title, setTitle] = useState('');
  const [token] = useTokenState();
  const isConfirmed = title === profile.blogTitle;
  const { activeProfileId } = useActiveProfile();

  const handleClick = () => {
    updateProfileThunk({
      data: { isCommunity: true },
      token,
      activeProfileId
    });
  };

  const ChangeBlogBlock = (
    <Block>
      <SettingsFlexbox>
        <SettingSectionTitle>
          <h4>Операции с дневником</h4>
          <p>Здесь вы можете преобразовать дневник в сообщество</p>
        </SettingSectionTitle>
        <Settings>
          <p>
            Введите название своего дневника, чтобы преобразовать его в
            сообщество.
          </p>
          <br />
          <TextField
            label="название дневника"
            value={title}
            onChange={e => setTitle(e.target.value)}
            touched
          />
          <br />
          <ActionButton onClick={handleClick} disabled={!isConfirmed}>
            Преобразовать в сообщество
          </ActionButton>
        </Settings>
      </SettingsFlexbox>
    </Block>
  );

  return profile.isCommunity ? null : ChangeBlogBlock;
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    profile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

const mapDispatchToProps = {
  updateProfileThunk
};

export const ChangeBlogContainer = WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(ChangeBlog)
);
