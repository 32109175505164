function isPlainObject(object) {
  if (typeof object !== 'object' || object === null) {
    return false;
  }

  let proto = object;

  while (Reflect.getPrototypeOf(proto) !== null) {
    proto = Reflect.getPrototypeOf(proto);
  }

  return Reflect.getPrototypeOf(object) === proto;
}

export const classActionMiddleware = () => next => action => {
  if (isPlainObject(action) || typeof action === 'function') {
    return next(action);
  }

  return next({ ...action });
};
