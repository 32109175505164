import styled from 'styled-components';

// options:
// - make individual styled components for each thing you need to style (headers, lists, etc.)
// - or write everything inside this one
// it is a matter of preference at this point

// avoid using class names if you can

export const StyledHelpSection = styled.div`
  margin: 80px 15px;

  h2,
  h3,
  h4 {
    /** theme settings can be found in styles/DybrTheme.js, they are passed here via react context API automatically */
    font-family: ${p => p.theme.headerFont};
  }
  h3 {
    font-size: 24px;
    margin-top: 46px;
    text-align: left;
    text-transform: uppercase;
  }
  h4 {
    text-align: left;
    font-size: 16px;
    text-transform: lowercase;
    margin-top: 30px;
  }
  p {
    font-family: ${p => p.theme.textFont};

    @media (min-width: 350px) {
      text-align: justify;
    }
  }
  ul,
  ol {
    list-style-position: inside;
  }
  ul {
    li {
    }
  }
  ol {
    li {
    }
  }
`;
