import React from 'react';
import { StyledIconButton } from '../../../scenes/Blog/designer/components/styled';
import ArrowUp from '../../../styles/Icons/SimpleArrowUp';
import ArrowDown from '../../../styles/Icons/SimpleArrowDown';

export function ReadMoreButton({ handleToggleExpand, isExpanded }) {
  return (
    <div
      className={`blog-entry-content  blog-entry-content-expansion-button ${
        !isExpanded ? 'blog-entry-content-expansion-button--collapsed' : ''
      }`}
      onClick={() => handleToggleExpand()}
    >
      <span>{isExpanded ? 'Свернуть' : 'Показать полностью'}</span>
      <StyledIconButton>
        {isExpanded ? <ArrowUp /> : <ArrowDown />}
      </StyledIconButton>
    </div>
  );
}
