import styled from 'styled-components';
import { NicknameUnderlinedSmall } from 'components/DybrComponents/NicknameDisplay';
import React from 'react';

export const List = styled.div`
  padding-top: 30px;
  padding-bottom: 50px;
  border-top: 1px solid ${(p) => p.theme.divider};
  border-bottom: 1px solid ${(p) => p.theme.divider};

  h5 {
    margin-bottom: 25px;
    font-size: 15px;
    font-weight: normal;
    strong {
      font-weight: bold;
    }
  }
`;

function ProfilesList({ profiles }) {
  if (!profiles) {
    return null;
  }

  return (
    <List>
      <h5>
        <strong>Всего:</strong> {profiles.length}
      </h5>
      {profiles.map((profile) => (
        <NicknameUnderlinedSmall key={profile.id} profile={profile} />
      ))}
    </List>
  );
}

export default ProfilesList;
