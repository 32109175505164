// do something at regular but reduced rate in respond to the chain of events
// use for something continious like scrolling

// use like this:
// const myHandler = (event) => // do something with the event
// const tHandler = throttle(200, myHandler);
// domNode.addEventListener("mousemove", tHandler);

export function throttle(delay, fn) {
  let lastCall = 0;
  return function (...args) {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return fn(...args);
  };
}

// do an action only only once for countinus event sequence (events coming more often than specified)
// use for reducing reaction rate for things like typing (trigger only when user pauses)

// const myHandler = (event) => // do something with the event
// const dHandler = debounce(200, myHandler);
// domNode.addEventListener("input", dHandler);

export function debounce(delay, fn) {
  let timerId;
  return function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  };
}
