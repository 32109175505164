import defaultBlogDesign from 'configs/defaultBlogDesign';

export default function composeBlogTheme({
  loggedIn,
  ownBlog,
  designer,
  tmpDesign,
  blogDesignDisabled,
  blogTheme
}) {
  if (loggedIn) {
    if (ownBlog && designer) {
      const designerTheme = tmpDesign || {};

      return {
        ...defaultBlogDesign,
        ...designerTheme
      };
    }

    if (blogDesignDisabled) {
      return {
        ...defaultBlogDesign
      };
    }

    return {
      ...defaultBlogDesign,
      ...blogTheme
    };
  } else if (blogTheme) {
    return {
      ...defaultBlogDesign,
      ...blogTheme
    };
  } else return defaultBlogDesign;
}
