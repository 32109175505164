import React, { useState } from 'react';
import { ChromePicker } from 'react-color';
import { rgbaObjectToString, rgbaStringToObject } from 'utils/helpers/colors';
import css from 'scenes/Blog/designer/components/ColorSetting.module.css';
import {
  ColorStyled,
  SwatchSectionStyled
} from 'scenes/Blog/designer/components/ColorSetting.style';

function ColorSetting({ value, label, onChange }) {
  const [picking, setPicking] = useState(false);
  const [rgba, setRgba] = useState(rgbaStringToObject(value));

  const handleChange = color => {
    setRgba(color.rgb);
    onChange(rgbaObjectToString(color.rgb));
  };

  return (
    <div className="color-picker">
      <div className="label-section">{label}</div>
      <SwatchSectionStyled
        className="swatch-section"
        onClick={() => setPicking(!picking)}
      >
        <ColorStyled rgba={rgba} />
      </SwatchSectionStyled>
      {picking && (
        <div className={css.popover}>
          <div className={css.cover} onClick={() => setPicking(false)} />
          <ChromePicker
            className={css.picker}
            color={rgba}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  );
}

export default ColorSetting;
