import { getNewNotificationsApi } from 'api/notifications';
import { StoreNotificationsAction } from '../actions/StoreNotificationsAction';
import { selectNotificationsMap } from 'store/notifications/selectors/selectNotificationsMap';
import isEqual from 'lodash/isEqual';
import { selectIsSignupIncomplete } from 'store/user/selectors/selectIsSignupIncomplete';

export const loadNotifications =
  ({ token, activeProfileId }) =>
  async (dispatch, getState) => {
    const state = getState();
    const isSignupIncomplete = selectIsSignupIncomplete(state);
    const currentNotificationIds = Object.keys(selectNotificationsMap(state));

    if (
      !token ||
      !activeProfileId ||
      activeProfileId === '0' ||
      isSignupIncomplete
    ) {
      return;
    }
    const res = await getNewNotificationsApi({
      profileId: activeProfileId,
      token
    });

    if (!res.error) {
      const newNotificationIds = res.data ? res.data.map(n => n.id) : [];

      if (
        isEqual(
          currentNotificationIds.slice().sort(),
          newNotificationIds.slice().sort()
        )
      ) {
        return;
      }

      dispatch(new StoreNotificationsAction(res.data));
    }
  };
