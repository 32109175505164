import { query } from 'api/Query';

/*
GET /profiles/id/relationships/designs - посмотреть свои дизайны
GET /profiles/id/relationships/current-design - посмотреть текущий дизайн
POST /profiles/id/relationships/designs - добавить дизайн к своим
PATCH /profiles/id/relationships/current-design - поменять свой дизайн
DELETE /profiles/id/relationships/current-design - удалить текущий дизайн, вернуть дефолтный
DELETE /profiles/id/relationships/designs - удалить один из своих дизайнов
*/

// data structure changed at a point leaving some users with incorrect version, so we need an extra check and transform
export const getProperDesignData = data => {
  if (!data.data) return data;
  return getProperDesignData(data.data);
};

// data has to be the userTheme design object
export const createDesignAPI = async ({ design, activeProfileId, token }) => {
  try {
    const res = await query({
      method: 'POST',
      resource: `profiles/${activeProfileId}/relationships/designs`,
      queryParams: {
        data: design
      },
      token
    });
    if (res.error) {
      return res;
    } else {
      return { success: true, data: getProperDesignData(res.data) };
    }
  } catch (error) {
    return { error: 'не удалось создать дизайн' };
  }
};

export const updateDesignAPI = async ({
  design,
  designId,
  activeProfileId,
  token
}) => {
  try {
    const res = await query({
      method: 'PATCH',
      resource: `profiles/${activeProfileId}/relationships/designs/${designId}`,
      queryParams: {
        data: design,
        id: designId
      },
      token
    });
    if (res.error) {
      return res;
    } else {
      return { success: true, data: getProperDesignData(res.data) };
    }
  } catch (error) {
    return { error: 'не удалось обновить дизайн' };
  }
};

export const deleteDesignAPI = async ({ designId, profileId, token }) => {
  try {
    const res = await query({
      method: 'DELETE',
      resource: `profiles/${profileId}/relationships/designs`,
      token,
      id: designId
    });
    if (res.error) {
      return res;
    } else {
      return { success: true };
    }
  } catch (error) {
    console.error(error.response);
    return { error: 'не удалось удалить дизайн' };
  }
};

export const getProfileDesignsAPI = async ({ profileId, token }) => {
  try {
    const res = await query({
      method: 'GET',
      resource: `profiles/${profileId}/relationships/designs`,
      token
    });
    if (res.error) {
      return res;
    } else {
      return {
        success: true,
        data: res.data.map(d => ({ ...getProperDesignData(d), id: d.id }))
      };
    }
  } catch (error) {
    console.error(error.response);
    return { error: 'не удалось получить список дизайнов профиля' };
  }
};

export const getDesignAPI = async ({ designId, profileId, token }) => {
  try {
    const res = await query({
      method: 'GET',
      resource: `profiles/${profileId}/relationships/designs/${designId}`,
      token
    });
    if (res.error) {
      return res;
    } else {
      return { success: true, data: getProperDesignData(res.data) };
    }
  } catch (error) {
    console.error(error.response);
    return { error: 'не удалось получить дизайн профиля' };
  }
};
