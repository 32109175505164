import styled from 'styled-components';

export const StyledSidebar = styled.div`
  background-color: ${props => props.theme.backgroundLight};
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 600;
  overflow: hidden;
  width: 100%;
  max-width: 390px;
  padding-top: 50px;
  box-shadow: 2px 17px 12px 1px ${props => props.theme.shadow};

  .scroll-wrapper {
    height: 100%;
  }
  .simplebar-scrollbar {
    width: 5px;
  }

  .simplebar-scrollbar:before {
    background: ${p => p.theme.backgroundDark};
    border-radius: 0px;
  }

  .horizontal.simplebar-track .simplebar-scrollbar {
    height: 5px;
  }

  /* needs more work, but this is fine for now  */
  position: fixed;

  .progress {
    cursor: progress;
  }

  a {
    color: ${props => props.theme.text};
    text-decoration: none;
  }

  li button {
    font-size: 16px;
    text-transform: lowercase;
    background: transparent;
    border: none;
    color: ${props => props.theme.text};
    text-decoration: none;
    cursor: pointer;
  }

  ul {
    list-style: none;
  }

  @media (max-width: 650px) {
    padding-top: 100px;
  }
`;

export const StyledMenu = styled.ul`
  margin-top: 15px;
  border-top: 1px solid ${props => props.theme.divider};
  padding-top: 20px;

  li {
    margin-top: 5px;
  }
  li:last-child {
    margin-top: 30px;
  }

  li a {
    display: block;
    width: 100%;
    padding: 15px 0 15px 50px;
    &:hover {
      background: ${props => props.theme.backgroundLighter};
      border-left: 10px solid ${props => props.theme.brand};
      padding-left: 40px;
      font-weight: bold;
    }
  }

  li button {
    padding: 15px 0 15px 50px;
    width: 100%;
    text-align: left;
    &:hover {
      background: ${props => props.theme.backgroundLighter};
      border-left: 10px solid ${props => props.theme.brand};
      padding-left: 40px;
      font-weight: bold;
    }
  }
`;

export const StyledCommentCounter = styled.div`
  background: ${p => p.theme.brand};
  color: ${p => p.theme.textLighter};
  font-size: 12px;
  height: 19px;
  border-radius: 10px;
  min-width: 25px;
  padding: 3px;
  text-align: center;

  &:hover {
    background: ${p => p.theme.brandDarker};
  }
`;

export const StyledSidebarContent = styled.div`
  height: 100%;

  .simplebar-scrollbar {
    width: 5px;
  }

  .simplebar-scrollbar:before {
    background: ${p => p.theme.backgroundDark};
    border-radius: 0px;
  }

  .horizontal.simplebar-track .simplebar-scrollbar {
    height: 5px;
  }
`;

export const StyledDiscussionSection = styled.div`
  .section-header {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px 0 0;
    width: 100%;
    min-width: 325px;
    border-top: 1px solid ${p => p.theme.divider};
    border-bottom: 1px solid ${p => p.theme.divider};
    position: relative;
    background-color: transparent;
    transition: background-color 0.2s;
    cursor: pointer;

    &:hover,
    &.section-open {
      background-color: ${p => p.theme.accentLighter};
      .section-title {
        font-weight: bold;
      }
      .arrow .circle.main-outline {
        fill: ${p => p.theme.backgroundDark};
      }
    }

    &.section-closed {
      border-bottom: none;
      height: 69px;
    }
    &.section-open {
      margin-bottom: 30px;
    }
    :before {
      opacity: 0;
      transition: opacity 0.2s;
      position: absolute;
      bottom: -10px;
      left: 66px;
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid ${p => p.theme.accentLighter};
      z-index: 1999;
    }
    :after {
      opacity: 0;
      transition: opacity 0.2s;
      position: absolute;
      bottom: -11px;
      left: 65px;
      content: '';
      width: 0;
      height: 0;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-top: 11px solid ${p => p.theme.divider};
      z-index: 1998;
    }
    &.section-open:before,
    &.section-open:after {
      opacity: 1;
    }
  }
  &:last-child .section-header {
    border-bottom: 1px solid ${p => p.theme.divider};
    height: 70px;
  }
  .section-title {
    text-transform: lowercase;
    display: flex;
    align-items: center;
    padding: 25px 53px 25px 18px;

    .arrow {
      width: 30px;
      height: 30px;
      margin-right: 18px;

      .icon-simple-arrow-left-with-background,
      .icon-simple-arrow-right-with-background {
        height: 30px;
        width: 30px;
      }

      .circle.main-outline {
        fill: ${p => p.theme.divider};
      }
      .arrow.main-outline {
        fill: ${p => p.theme.backgroundLighter};
      }
    }
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const EntryWithCommentsWrapper = styled.div`
  margin-bottom: 20px;
  &.comments--open .plus {
    padding: 14px 23px 14px 24px;
    font-size: 17px;
  }

  .comments {
    transition: background-color 2s;
    transition: opacity 0.5s;
    transition: all 0.5s;
  }

  &.comments--closed .comments {
    height: 0;
    max-height: 0;
    min-height: 0;

    a {
      height: 33.333px;
    }
    a > div {
      display: none;
    }
  }
  &.comments--open .comments {
    height: max-content;
    min-height: 50px;
    max-height: none;
  }

  .comments--loading {
    opacity: 0.1;
    animation: loading 1.5s linear infinite;

    @keyframes loading {
      0% {
        background: transparent;
      }
      50% {
        background: ${p => p.theme.backgroundDark};
      }
      100% {
        background: transparent;
      }
    }
  }
`;

export const StyledEntry = styled.div`
  display: flex;
  align-items: center;
  height: 47px;
  position: relative;

  .conversation-profile-avatar {
    max-width: 30px;
    max-height: 30px;
    min-height: 30px;
    min-width: 30px;
    cursor: pointer;
    border: 0;
    background: transparent;
    font-size: 0px;
    margin: 0 5px 0 0;

    img {
      height: auto;
      width: auto;
      max-width: 30px;
      max-height: 30px;
    }
  }

  .plus {
    font-size: 18px;
    font-weight: bold;
    height: 100%;
    display: block;
    cursor: pointer;
    text-align: center;
    padding: 14px 24px;
    color: ${p => p.theme.brand};
    transition: color 0.2s;
  }

  .plus:hover {
    color: ${p => p.theme.accentDark};
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 40px;
    width: 100%;
    height: 100%;

    :after {
      position: absolute;
      content: '';
      width: 86%;
      left: 16px;
      bottom: 6px;
      border-bottom: 1px solid ${p => p.theme.divider};
      transition: border 0.2s;
    }

    &:hover:after {
      border-bottom: 1px solid ${p => p.theme.accent};
    }
  }
  .entry-text {
    width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.25;
    font-size: 14px;
    font-weight: bold;

    @media (max-width: 390px) {
      width: 200px;
    }
  }
  .conversation-profile-name {
    width: 215px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.25;
    font-size: 14px;
    font-weight: bold;

    @media (max-width: 390px) {
      width: 200px;
    }
  }
  .comment-amount {
  }
`;

export const StyledEntryExpanded = styled(StyledEntry)`
  .avatar {
    max-width: 30px;
    max-height: 30px;
    min-height: 30px;
    min-width: 30px;
    cursor: pointer;
    border: 0;
    background: transparent;
    font-size: 0px;
    margin: 2px 5px 0 0;

    img {
      height: auto;
      width: auto;
      max-width: 30px;
      max-height: 30px;
    }
  }
  .entry-author-nickname {
    font-weight: bold;
    width: 259px;
    color: ${p => p.theme.accentDark};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .entry-text {
    width: 259px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 400;
    line-height: 1.25;
  }
  .entry-title {
    font-weight: 700;
  }
`;

export const StyledComment = styled.div`
  a {
    display: flex;
    width: 100%;
    padding: 5px 20px 5px 59px;
    font-size: 14px;

    &:hover {
      background: ${p => p.theme.backgroundScroll};
    }
  }
  .avatar {
    max-width: 30px;
    max-height: 30px;
    min-height: 30px;
    min-width: 30px;
    cursor: pointer;
    border: 0;
    background: transparent;
    font-size: 0px;
    margin: 2px 5px 0 0;

    img {
      height: auto;
      width: auto;
      max-width: 30px;
      max-height: 30px;
    }
  }

  .comment-author-nickname {
    font-weight: bold;
    width: 259px;
    color: ${p => p.theme.accentDark};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .comment-text {
    width: 259px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.25;
  }
`;

export const ChosenProfile = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 40px 15px 50px;

  a:hover {
    color: ${props => props.theme.brand};
  }

  h4 {
    background-color: ${props => props.theme.backgroundLight};
    padding: 0 15px;
  }

  h5 {
    font-weight: normal;
    margin-top: 5px;
    font-size: 14px;
    padding: 0 15px;
  }
`;

export const ChosenProfileAvatarWrapper = styled.div`
  position: relative;
  background-color: ${props => props.theme.backgroundLight};
  /* padding-left: 15px;
  margin-left: -15px; */
  width: 86px;

  .icon {
    position: absolute;
    top: -8px;
    right: -13px;

    .icon-fill--accent {
      fill: ${props => props.theme.accent};
    }

    .icon-fill--inner {
      fill: ${props => props.theme.backgroundLighter};
    }
  }
`;

export const ChosenProfileAvatar = styled.button`
  max-width: 86px;
  max-height: 86px;

  transition: all 0.2s;
  border: 0;
  background: transparent;
  font-size: 0px;

  img {
    height: auto;
    width: auto;
    max-width: 86px;
    max-height: 86px;
    border: 3px solid ${props => props.theme.accent};
    border-radius: 2px;
  }
`;

export const UserLinks = styled.div`
  flex-grow: 1;
  min-height: 86px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const SidebarButtonArea = styled.div`
  display: flex;
  justify-content: center;
`;

export const ResetButtonArea = styled(SidebarButtonArea)`
  display: flex;
  justify-content: center;
  padding-top: 37px;
`;
