import React, { useCallback, useEffect, useState } from 'react';
import css from 'scenes/Dybr/Feeds/BlogList.module.css';
import StyledTable from 'scenes/Dybr/Feeds/StyledTable';
import queryString from 'query-string';
import DybrPagination from 'components/DybrComponents/DybrPagination';
import { getAllProfilesApi } from 'api/profiles';
import ProfileLink from 'scenes/Management/Community/ProfileLink';
import moment from 'moment';
import AnimatedErrorBlock from 'components/FormElements/AnimatedErrorBlock';
import { IconButton } from 'components/Shared/IconButton.style';
import SearchIcon from 'styles/Icons/SearchIcon';
import { navigate } from '@reach/router';
import { debounce } from 'utils/helpers/general';
import SearchTextField from 'components/FormElements/SearchTextField';
import { useTokenState } from 'store/localStorage/useTokenState';
import { classNames } from 'utils/helpers/classNames';

const pageSize = 10;

function BlogCell({ blog }) {
  const { blogSlug, blogTitle } = blog;

  return blogSlug === undefined ? (
    ''
  ) : (
    <>
      <a href={`/blog/${blogSlug}`} className={css.titleColumnNameLink}>
        <div className={css.titleColumnName}>{blogTitle}</div>
      </a>

      {/* TODO here should be community tags selected when community was created*/}
      {/*{community && (*/}
      {/*  <div className={css.communityColumnTags}>*/}
      {/*    {tags*/}
      {/*      .slice(0, 5)*/}
      {/*      .map((tag) => `#${tag.name}`)*/}
      {/*      .join(' ')}*/}
      {/*  </div>*/}
      {/*)}*/}
    </>
  );
}

function BlogList({ isCommunity = true }) {
  const [blogs, setBlogs] = useState([]);
  const [totalNumber, setTotalNumber] = useState(0);
  const [error, setError] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [token] = useTokenState();
  const [sort, setSort] = useState('blog-title');

  useEffect(() => {
    document.title = `${
      isCommunity ? 'Все сообщества' : 'Все дневники'
    } - Дыбр`;
  }, [isCommunity]);

  const parsed = queryString.parse(location.search);
  const page = Number(parsed.page || parsed.p) || 1;

  // const pageSize = userSettings.pagination.entries;

  async function loadProfiles(page, searchTerm, isCommunity, sort, token) {
    setError(false);

    const res = await getAllProfilesApi({
      isCommunity,
      searchTerm,
      sort,
      pageNumber: page,
      pageSize,
      token
    });

    if (!res.error) {
      setBlogs(res.data);
      setTotalNumber(res.meta.totalProfiles ?? ' ');
    } else {
      setError(true);
    }
  }

  useEffect(() => {
    loadProfiles(page, searchTerm, isCommunity, sort, token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, page, isCommunity, sort]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const search = useCallback(debounce(500, loadProfiles), []);

  function handleSearchTermChange(e) {
    const value = e.target.value;
    setSearchTerm(value);

    if (page && page !== 1) {
      // this navigation will trigger loadProfiles() automatically, search term will be already correct
      navigate(`/feed/${isCommunity ? 'communities' : 'blogs'}/?page=${1}`);
    } else {
      search(1, value, isCommunity, sort, token);
    }
  }

  function handleEnterPress(e) {
    if (e.key === 'Enter') {
      loadProfiles(1, searchTerm, isCommunity, sort, token);
      // navigation to page 1 is not needed here, it will be provided by onChange()
    }
  }
  const handleSortChange = (sortType) => {
    if (sort.endsWith(sortType)) {
      sort.startsWith('-') ? setSort(sortType) : setSort('-' + sortType);
    } else {
      setSort(sortType);
    }
  };
  const searchLabel = `искать ${isCommunity ? 'сообщество' : 'дневник'}`;

  return (
    <>
      <div className={css.container}>
        <div className={css.block}>
          <div className={css.searchContainer}>
            <SearchTextField
              onKeyUp={handleEnterPress}
              value={searchTerm}
              onChange={handleSearchTermChange}
              touched
              label={searchLabel}
            />
            <IconButton
              className={css.searchButton}
              onClick={() =>
                loadProfiles(1, searchTerm, isCommunity, sort, token)
              }
            >
              <SearchIcon />
            </IconButton>
          </div>
          <div className={css.total}>
            Всего {isCommunity ? 'сообществ' : 'дневников'}: {totalNumber}
          </div>

          <AnimatedErrorBlock show={error}>
            что-то пошло не так
          </AnimatedErrorBlock>

          <StyledTable className={css.textLeft}>
            <thead>
              <tr>
                <th
                  onClick={() => handleSortChange('blog-title')}
                  className={css.clickableColumnName}
                >
                  {isCommunity ? 'сообщество' : 'дневник'}
                </th>
                <th
                  onClick={() => handleSortChange('nickname')}
                  className={css.clickableColumnName}
                >
                  {isCommunity ? 'владелец' : 'автор'}
                </th>
                <th
                  onClick={() => handleSortChange('created-at')}
                  className={classNames({
                    [css.clickableColumnName]: true,
                    [css.textCenter]: true
                  })}
                >
                  дата регистрации
                </th>
                <th
                  className={classNames({
                    // [css.clickableColumnName]: true,
                    [css.textCenter]: true
                  })}
                >
                  записи
                </th>

                <th
                  className={classNames({
                    // [css.clickableColumnName]: true,
                    [css.textCenter]: true
                  })}
                >
                  последняя запись
                </th>
              </tr>
            </thead>
            <tbody>
              {blogs.map((blog) => (
                <tr key={blog.id}>
                  <td className={css.titleColumn}>
                    <BlogCell blog={blog} community={isCommunity} />
                  </td>
                  <td className={css.ownerColumn}>
                    <ProfileLink profile={blog} className={css.profileName} />
                  </td>
                  <td className={css.lastEntryColumn}>
                    {blog.createdAt
                      ? moment(blog.createdAt).format('DD.MM.YYYY')
                      : ''}
                  </td>
                  <td className={css.entriesColumn}>
                    {blog.meta?.totalEntries ?? '0'}
                  </td>
                  <td className={css.lastEntryColumn}>
                    {blog.meta?.lastPublishedAt
                      ? moment(blog.meta?.lastPublishedAt).format('DD.MM.YYYY')
                      : ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </StyledTable>
          <div className={css.pagination}>
            <DybrPagination
              backgroundColor="transparent"
              classModifier="top"
              totalRecords={totalNumber}
              currentPage={page}
              onChange={() => {}}
              pageSize={pageSize}
              url={`/feed/${isCommunity ? 'communities' : 'blogs'}/?page=`}
              data-testid={`${
                isCommunity ? 'communities' : 'blogs'
              }-table-pagination`}
            />
          </div>
        </div>
      </div>
      <div className={css.bottomSpace} />
    </>
  );
}

export default BlogList;
