import React, { useEffect, useReducer } from 'react';
import TextField from 'components/FormElements/TextField';
import Checkbox from 'components/FormElements/Checkbox';
import AnimatedErrorBlock from 'components/FormElements/AnimatedErrorBlock';
import theme from 'styles/DybrTheme';

import { FormWrapper } from './components/StyledForm';
import { Section } from 'components/DybrComponents/Section';
import {
  ActionButton,
  ImportantButton,
  SecondaryButton
} from 'components/Shared/Buttons';

import { createUserApi } from 'api/users';
import produce from 'immer';
import { selectIsSignupIncomplete } from 'store/user/selectors/selectIsSignupIncomplete';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import { doLogoutThunk } from 'store/user/thunks/doLogoutThunk';
import { useTokenState } from 'store/localStorage/useTokenState';
import { validateEmailDomain } from '../../utils/helpers/validate';

function reducer(state, { type, payload }) {
  return produce(state, draft => {
    switch (type) {
      case 'set_field':
        draft.fields[payload.field] = payload.value;
        draft.fieldErrors[payload.field] = '';
        draft.submitError = '';
        return;
      case 'set_field_error':
        draft.fieldErrors[payload.field] = payload.value;
        return;
      case 'set_status':
        draft.status = payload;
        return;
      case 'set_submit_error':
        draft.submitError = payload;
        draft.status = '';
        return;
      default:
        return;
    }
  });
}

export const CreateAccount = ({ doLogout }) => {
  const [token, setToken] = useTokenState();
  const loggedIn = Boolean(token);

  useEffect(() => {
    document.title = `Создание аккаунта - Дыбр`;
  }, []);

  const [state, dispatch] = useReducer(reducer, {
    status: '',
    fieldErrors: {},
    fields: {
      token: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      tos: false,
      isAdult: false
    },
    submitError: ''
  });

  if (loggedIn) {
    return (
      <Section withBackground noPadding>
        <FormWrapper>
          <h2>Ошибка</h2>
          <p style={{ padding: '40px 0' }}>
            Похоже, что аккаунт уже создан. Если вы хотите создать еще один,
            сначала нужно разлогиниться
          </p>
          <div />

          <ImportantButton
            style={{ marginBottom: '40px' }}
            onClick={() => {
              doLogout(setToken);
              return navigate('/');
            }}
          >
            выход
          </ImportantButton>

          <SecondaryButton onClick={() => navigate('/')}>
            вернуться на главную
          </SecondaryButton>
        </FormWrapper>
      </Section>
    );
  }

  function changeField(field, value) {
    dispatch({ type: 'set_field', payload: { field, value } });
  }

  async function handleSubmit(e) {
    e.preventDefault();

    if (!validateForm()) return;

    dispatch({ type: 'set_status', payload: 'loading' });

    const { email, password } = state.fields;
    let res = await createUserApi({ email, password });
    if (res.error) {
      dispatch({ type: 'set_submit_error', payload: res.error });
    } else {
      dispatch({ type: 'set_status', payload: 'done' });
    }
  }

  function validatePassword() {
    const { password, passwordConfirmation } = state.fields;
    if (password !== passwordConfirmation) {
      dispatch({
        type: 'set_field_error',
        payload: { field: 'passwordConfirmation', value: 'пароли не совпадают' }
      });
      return false;
    }
    if (!password.length) {
      dispatch({
        type: 'set_field_error',
        payload: {
          field: 'passwordConfirmation',
          value: 'не может быть пустым'
        }
      });
      return false;
    }
    return true;
  }

  function validateEmail() {
    if (!state.fields.email.length) {
      dispatch({
        type: 'set_field_error',
        payload: { field: 'email', value: 'не может быть пустым' }
      });
      return false;
    }
    const unsupportedEmailDomain = validateEmailDomain(state.fields.email);
    if (unsupportedEmailDomain) {
      dispatch({
        type: 'set_field_error',
        payload: {
          field: 'email',
          value: `Почтовый домен "${unsupportedEmailDomain}" не поддерживается`
        }
      });
      return false;
    }
    return true;
  }

  function validateFields() {
    const email = validateEmail();
    const password = validatePassword();

    return email && password;
  }

  function validateForm() {
    const { tos, isAdult } = state.fields;

    if (!validateFields()) {
      dispatch({
        type: 'set_submit_error',
        payload: 'заполните все поля'
      });
      return false;
    }
    if (!tos) {
      dispatch({
        type: 'set_submit_error',
        payload: 'прочитайте и примите наши правила, пожалуйста'
      });
      return false;
    }
    if (!isAdult) {
      dispatch({
        type: 'set_submit_error',
        payload: 'сайт предназначен только для взрослых'
      });
      return false;
    }
    return true;
  }

  return (
    <Section withBackground noPadding>
      <FormWrapper>
        {state.status === 'done' ? (
          <>
            <h2 data-testid="account-created-confirmation">Аккаунт создан</h2>
            <p>
              Мы выслали письмо на указанный вами адрес. Чтобы продолжить,
              пройдите по ссылке в нём.
            </p>
          </>
        ) : (
          <>
            <h2>Создание аккаунта</h2>

            <form onSubmit={handleSubmit} data-testid="registration-form">
              <TextField
                type="email"
                value={state.fields.email}
                meta={{}}
                onChange={e => changeField('email', e.target.value)}
                id="email"
                label="почта"
                onBlur={validateEmail}
                error={state.fieldErrors.email}
                touched={!!state.fieldErrors.email}
              />
              <TextField
                value={state.fields.password}
                onChange={e => changeField('password', e.target.value)}
                meta={{}}
                id="pass"
                label="новый пароль"
                type="password"
              />
              <TextField
                type="password"
                value={state.fields.passwordConfirmation}
                onChange={e =>
                  changeField('passwordConfirmation', e.target.value)
                }
                meta={{}}
                id="pass2"
                label="пароль ещё раз"
                onBlur={validatePassword}
                error={state.fieldErrors.passwordConfirmation}
                touched={!!state.fieldErrors.passwordConfirmation}
              />
              <br />
              <Checkbox
                id={'tos-checkbox'}
                checked={state.fields.tos}
                onChange={e => changeField('tos', !state.fields.tos)}
                touched={!state.fields.tos}
                isRequired={true}
                labelElement={
                  <span>
                    я согласен с{' '}
                    <a
                      href="/terms"
                      style={{ color: theme.accent }}
                      target="blank"
                    >
                      правилами
                    </a>{' '}
                    и тем, что они могут обновляться со временем
                  </span>
                }
              />
              <br />
              <Checkbox
                id={'adult-checkbox'}
                checked={state.fields.isAdult}
                onChange={e => changeField('isAdult', !state.fields.isAdult)}
                touched={!state.fields.isAdult}
                isRequired={true}
                labelElement={<span>мне есть 18</span>}
              />
              <br />
              <AnimatedErrorBlock show={state.submitError}>
                {state.submitError}
              </AnimatedErrorBlock>
              <ActionButton
                type="submit"
                data-testid="continue-registration-button"
              >
                продолжить
              </ActionButton>
            </form>
          </>
        )}
      </FormWrapper>
    </Section>
  );
};

const mapStateToProps = state => {
  return {
    isSignupIncomplete: selectIsSignupIncomplete(state)
  };
};

const mapDispatchToProps = {
  doLogout: doLogoutThunk
};

export const CreateAccountContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAccount);
