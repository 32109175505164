import React, { useEffect, useState } from 'react';
import {
  Block,
  ContentWrapper,
  Settings,
  SettingSectionTitle,
  SettingsFlexbox
} from 'components/DybrComponents/StyledServicePagesElements';
import theme from 'styles/DybrTheme';
import { HeaderGeneral } from 'components/DybrComponents/HeaderGeneral';
import Switch from 'components/FormElements/Switch';
import NicknameField from 'scenes/Account/createBlog/NicknameField';
import BlogSlugField from 'scenes/Account/createBlog/BlogSlugField';
import BlogNameField from 'scenes/Account/createBlog/BlogNameField';
import AnimatedErrorBlock from 'components/FormElements/AnimatedErrorBlock';
import { ActionButton } from 'components/Shared/Buttons';
import { ButtonWrapper } from 'scenes/Account/components/StyledForm';
import defaultProfileSettings from 'configs/defaultProfileSettings';
import { createProfileThunk } from 'store/user/thunks/createProfileThunk';
import { connect } from 'react-redux';
import {
  validateName,
  validateSlug,
  validateTitle
} from 'utils/helpers/validate';
import { navigate } from '@reach/router';
import { selectProfiles } from 'store/user/selectors/selectProfiles';
import { useTokenState } from 'store/localStorage/useTokenState';

function CreateBlog({ createProfileThunk, profiles }) {
  const [isCommunity, setIsCommunity] = useState(false);
  const [nickname, setNickname] = useState('');
  const [errorNickname, setErrorNickname] = useState('');
  const [slug, setSlug] = useState('');
  const [errorSlug, setErrorSlug] = useState('');
  const [title, setTitle] = useState('');
  const [errorTitle, setErrorTitle] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [errorCreate, setErrorCreate] = useState('');
  const [success, setSuccess] = useState(false);

  const [token, setToken] = useTokenState();

  const validationError = errorNickname || errorTitle || errorSlug;

  useEffect(() => {
    document.title = `Создание блога - Дыбр`;
  }, []);

  const onValidateNickname = async () => {
    const error = await validateName(nickname, { token });

    if (error) {
      setErrorNickname(error);
    }
    return error;
  };

  const onValidateTitle = () => {
    const error = validateTitle(title);

    if (error) {
      setErrorTitle(error);
    }
    return error;
  };

  const onValidateSlug = async () => {
    const error = await validateSlug({ slug, token });

    if (error) {
      setErrorSlug(error);
    }
    return error;
  };

  const validate = async () => {
    const nick = await onValidateNickname();
    if (nick) return false;
    const slug = await onValidateSlug();
    if (slug) return false;
    return !onValidateTitle();
  };

  const createBlog = async () => {
    setErrorCreate('');

    if (validationError) {
      return null;
    }

    const valid = await validate();

    if (!valid) return;

    setSubmitting(true);

    const res = await createProfileThunk({
      nickname,
      isCommunity,
      blogSlug: slug,
      blogTitle: title,
      settings: defaultProfileSettings,
      token,
      setToken
    });

    if (res.error) {
      setErrorCreate(res.error);
    } else {
      setSuccess(true);
    }
    setSubmitting(false);
  };

  return (
    <div
      data-testid="CreateBlog"
      style={{
        backgroundColor: theme.backgroundLight,
        minHeight: 'calc(100vh - 50px)'
      }}
    >
      <HeaderGeneral>
        <h1 data-testid="new-profile-creation-header">создать блог</h1>
      </HeaderGeneral>

      <ContentWrapper>
        {success ? (
          <>
            <div
              style={{ textAlign: 'center' }}
              data-testid="new-profile-created-success"
            >
              {profiles && Object.keys(profiles).length > 1 ? (
                <p>Ура, всё получилось! Новый блог создан.</p>
              ) : (
                <p>
                  Ура, всё получилось! Рады приветствовать вас в наших рядах!
                </p>
              )}
              <br />
              <br />

              <ActionButton
                data-testid="navigate-to-main-button"
                onClick={() => navigate('/')}
              >
                на главную
              </ActionButton>
            </div>
          </>
        ) : (
          <>
            <Block>
              <SettingsFlexbox>
                <SettingSectionTitle>
                  <h4>Тип блога</h4>
                  <p>Выберите, хотите вы вести дневник или сообщество</p>
                </SettingSectionTitle>
                <Settings>
                  <Switch
                    testId="new-profile-community-switcher"
                    label="сообщество"
                    checked={isCommunity}
                    onClick={() => setIsCommunity(!isCommunity)}
                    leftLabel="дневник"
                  />
                </Settings>
              </SettingsFlexbox>
            </Block>
            <Block>
              <SettingsFlexbox>
                <SettingSectionTitle>
                  <h4>О блоге</h4>
                  <p>Назовите ваш профиль, блог и придумайте короткий адрес</p>
                </SettingSectionTitle>
                <Settings>
                  <NicknameField
                    nickname={nickname}
                    setErrorNickname={setErrorNickname}
                    setNickname={setNickname}
                    errorNickname={errorNickname}
                    validate={onValidateNickname}
                  />
                  <BlogNameField
                    title={title}
                    setTitle={setTitle}
                    errorTitle={errorTitle}
                    setErrorTitle={setErrorTitle}
                    validate={onValidateTitle}
                  />
                  <BlogSlugField
                    slug={slug}
                    setSlug={setSlug}
                    errorSlug={errorSlug}
                    setErrorSlug={setErrorSlug}
                    validate={onValidateSlug}
                  />
                </Settings>
              </SettingsFlexbox>
              <AnimatedErrorBlock show={validationError || errorCreate}>
                {validationError || errorCreate}
              </AnimatedErrorBlock>
            </Block>
            <ButtonWrapper>
              <ActionButton
                data-testid="create-new-profile-button"
                disabled={validationError}
                onClick={createBlog}
              >
                {submitting ? 'один момент...' : 'создать'}
              </ActionButton>
            </ButtonWrapper>
          </>
        )}
      </ContentWrapper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  profiles: selectProfiles(state)
});

const mapDispatchToProps = {
  createProfileThunk
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateBlog);
