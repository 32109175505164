import { parseToken } from 'store/user/reducer/parseToken';
import config from 'configs/dybr';
import { doLogoutThunk } from 'store/user/thunks/doLogoutThunk';

export const checkTokenThunk = ({ token, setToken }) => async (
  dispatch,
  getState
) => {
  if (token) {
    const { tokenExp } = parseToken(token);
    const leftUntilExpired =
      tokenExp - (Date.now() / 1000 + config.EXPIRATION_BUFFER);

    if (leftUntilExpired < 0) {
      doLogoutThunk(setToken)(dispatch, getState);
      alert('Кажется, ваш токен устарел. Пожалуйста, залогиньтесь снова');
    } else {
      console.log('checked token, it`s ok. Time left:', leftUntilExpired);
    }
  }
};
