import cloneDeep from 'lodash/cloneDeep';

export const defaultUserState = {
  userData: {
    createdAt: '',
    updatedAt: '',
    email: '',
    isAdult: undefined,
    termsOfService: false
  },
  communities: {},
  activeProfile: '0',
  settings: undefined,
  profiles: {}
};

export const defaultNotificationState = {
  items: {},
  entries: {},
  comments: {}
};

export const defaultDesignerState = {
  designerOn: false,
  designerCollapsed: false,
  designs: {},
  draftDesign: {}
};

export const defaultListsState = {
  banned: { profiles: [] },
  hidden: { profiles: [] },
  favorites: [],
  readers: [],
  profiles: {}
};

export const initialState = {
  lists: cloneDeep(defaultListsState),
  user: cloneDeep(defaultUserState),
  designer: cloneDeep(defaultDesignerState),
  forms: { byProfile: {} },
  notifications: cloneDeep(defaultNotificationState),
  dybrVersion: undefined
};
