import React from 'react';
import { Router } from '@reach/router';
import NavBarLoggedOut from './NavBarLoggedOut';
import HeaderMain from './HeaderMain';
import { NavBarLoggedInContainer } from 'scenes/Header/NavBarLoggedIn';
import { selectIsSignupIncomplete } from 'store/user/selectors/selectIsSignupIncomplete';
import { connect } from 'react-redux';
import { useTokenState } from 'store/localStorage/useTokenState';

function Header({ isSignupIncomplete, location }) {
  const [token] = useTokenState();
  const isLoggedIn = Boolean(token);

  return (
    <div data-testid="header">
      {isLoggedIn && !isSignupIncomplete ? (
        <NavBarLoggedInContainer pathname={location.pathname} />
      ) : (
        <Router primary={false}>
          <HeaderMain path="/" />
          <NavBarLoggedOut default />
        </Router>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isSignupIncomplete: selectIsSignupIncomplete(state)
});

export const HeaderContainer = connect(mapStateToProps)(Header);
