import React, { useState } from 'react';
import {
  Block,
  Settings,
  SettingSectionTitle,
  SettingsFlexbox
} from 'components/DybrComponents/StyledServicePagesElements';
import { connect } from 'react-redux';
import { selectActiveProfileAugmented } from 'store/user/selectors/selectActiveProfileAugmented';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';
import { useTokenState } from 'store/localStorage/useTokenState';
import { ActionButton } from 'components/Shared/Buttons';
import RadioGroup from 'components/FormElements/RadioGroup';
import TextField from 'components/FormElements/TextField';
import { deleteDybrBlog } from 'api/profiles';
import { doLogoutThunk } from 'store/user/thunks/doLogoutThunk';
import { navigate } from '@reach/router';
import theme from 'styles/DybrTheme';

const opitions = {
  KEEP: 'keep',
  ANON: 'anonimize',
  DELETE: 'delete'
};

const commentOptions = [
  {
    label: 'оставить комментарии',
    value: opitions.KEEP,
    radioValue: opitions.KEEP
  },
  {
    label: 'оставить комментарии от анонимного профиля',
    value: opitions.ANON,
    radioValue: opitions.ANON
  },
  {
    label: 'удалить все комментарии',
    value: opitions.DELETE,
    radioValue: opitions.DELETE
  }
];

function DeleteBlog({ profile, doLogout }) {
  const [deleteOption, setDeleteOption] = useState('');
  const [title, setTitle] = useState('');
  const [token, setToken] = useTokenState();
  const isConfirmed =
    title === profile.blogTitle &&
    Object.values(opitions).includes(deleteOption);

  const changeOptions = (_, id) => {
    setDeleteOption(id);
  };

  const handleClick = async () => {
    await deleteDybrBlog({
      profileId: profile.id,
      comments: deleteOption,
      token
    });
    doLogout(setToken);
    navigate('/');
  };

  return (
    <Block>
      <SettingsFlexbox>
        <SettingSectionTitle>
          <h4>Удалить дневник</h4>
          <p>Удалить дневник и профиль</p>
        </SettingSectionTitle>
        <Settings>
          <p>
            Удаление дневника это необратимое действие. Если вы точно уверены,
            что хотите удалить дневник, введите в поле <strong>название</strong>{' '}
            своего дневника.
          </p>
          <br />
          <TextField
            label="название дневника"
            value={title}
            onChange={e => setTitle(e.target.value)}
            touched
          />
          <br />
          <RadioGroup
            title="что делать с комментариями в чужих дневниках"
            name="deletingCommentsOptions"
            checked={deleteOption}
            onCheck={changeOptions}
            items={commentOptions}
          />
          <br />
          <ActionButton
            onClick={handleClick}
            disabled={!isConfirmed}
            style={{ backgroundColor: theme.brand }}
          >
            Удалить дневник
          </ActionButton>
        </Settings>
      </SettingsFlexbox>
    </Block>
  );
}

const mapDispatchToProps = {
  doLogout: doLogoutThunk
};

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;
  return {
    profile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

export const DeleteBlogContainer = WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(DeleteBlog)
);
