import defaultAva from 'components/img/default_ava.jpg';
import { getRelativeUrl } from 'utils/helpers/hostNames';
import defaultProfileSettings from '../../configs/defaultProfileSettings';

export const getAvatar = ({ settings }) => {
  if (!settings) {
    settings = defaultProfileSettings;
  }
  const { avatar } = settings;
  if (settings && avatar) {
    if (
      // default_ava filename changes with each build, always refer to the current one
      avatar.substring(0, 25) !== '/static/media/default_ava'
    ) {
      // make existing urls relative in prod
      // and in dev mode  - pointing to the server
      return getRelativeUrl(avatar);
    }
  }
  return defaultAva;
};

export const makeProfileUrl = profile => {
  return '/profile/' + profile.id;
};
