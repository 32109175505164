import { createSelector } from 'reselect';
import { selectNotificationItems } from './selectNotificationItems';

/**
 * will return an object:
 * { 'entryID': [comment, comment] }
 */
export const selectConversationMessagesGroupedByProfileId = createSelector(
  selectNotificationItems,
  items =>
    items.reduce((grouped, item) => {
      if (item.source && item.type === 'message') {
        const message = {
          ...item.message,
          profile: item.source
        };
        if (item.source.id in grouped) {
          grouped[item.source.id].push(message);
        } else {
          grouped[item.source.id] = [message];
        }
      }
      return grouped;
    }, {})
);

export const selectCommentsWithMentionGroupedByEntryId = createSelector(
  selectNotificationItems,
  items =>
    items.reduce((grouped, item) => {
      if (item.comments && item.type === 'mention') {
        const comment = {
          ...item.comments,
          profile: item.profiles
        };
        if (item.entry in grouped) {
          grouped[item.entry].push(comment);
        } else {
          grouped[item.entry] = [comment];
        }
      }
      return grouped;
    }, {})
);

export const selectCommentsGroupedByEntryId = createSelector(
  selectNotificationItems,
  notifications =>
    notifications.reduce((grouped, notification) => {
      if (notification.comments && notification.type === 'comment') {
        const comment = {
          ...notification.comments,
          profile: notification.profiles
        };
        if (notification.entry in grouped) {
          grouped[notification.entry].push(comment);
        } else {
          grouped[notification.entry] = [comment];
        }
      }
      return grouped;
    }, {})
);
