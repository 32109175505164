import { Link } from '@reach/router';

import config from 'configs/dybr';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  useActiveProfile,
  useTokenState
} from 'store/localStorage/useTokenState';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';

import { loadNotifications } from 'store/notifications/operations';
import { selectNotificationsTotal } from 'store/notifications/selectors';
import { selectActiveProfileAugmented } from 'store/user/selectors/selectActiveProfileAugmented';
import { selectCommunities } from 'store/user/selectors/selectCommunities';
import { ChatIcon } from 'styles/Icons/Chat';
import { QuickAccessIcon } from 'styles/Icons/FastForwardQuickAccess';
import { FavoritesIcon } from 'styles/Icons/Favorites';

import JournalIcon from 'styles/Icons/Journal';
import { NewPostIcon as PlusIcon } from 'styles/Icons/NewPost';
import SearchIcon from 'styles/Icons/SearchIcon';
import { getAvatar } from 'utils/helpers/profiles';

import NavBarContainer from './components/NavBarContainer';
import Sidebar from './components/Sidebar/Sidebar';
import {
  AvatarButton,
  IconButton,
  NotificationNumber
} from './components/StyledHeaderElements';

function getCurrentBlog(blogSlug, pathname, myCommunities) {
  if (myCommunities && pathname.toString().startsWith('/blog')) {
    const currentBlogSlug = pathname
      .toString()
      .replace('/blog/', '')
      .split('/')[0];

    for (const community of Object.values(myCommunities)) {
      if (community.blogSlug === currentBlogSlug) {
        // that means that user is a member of this community
        return currentBlogSlug;
      }
    }
  }
  return blogSlug;
}

function NavBar({
  loadNotifications,
  pathname,
  profile,
  notificationsTotal,
  myCommunities
}) {
  const [currentSidebar, setCurrentSidebar] = useState(null);
  const [lastPathname, setLastPathname] = useState(pathname);
  const { activeProfileId } = useActiveProfile();
  const [token] = useTokenState();

  useEffect(() => {
    if (lastPathname !== pathname) {
      setCurrentSidebar(null);
      setLastPathname(pathname);
    }
  }, [pathname, lastPathname]);

  useEffect(() => {
    loadNotifications({ token, activeProfileId });

    const interval = setInterval(
      () => loadNotifications({ token, activeProfileId }), // todo check if token will be renewed here
      config.NOTIFICATION_LOAD_INTERVAL
    );
    return () => clearInterval(interval);
  }, [loadNotifications, activeProfileId]);

  const setSidebar = (type) => () => {
    if (type === currentSidebar) {
      type = null;
    }

    setCurrentSidebar(type);
  };

  const handleSidebarClickAway = () => {
    setTimeout(() => setCurrentSidebar(null));
  };

  if (!profile) {
    // todo dispatch error
    throw new Error('cannot find profile');
  }

  const avatar = getAvatar(profile);

  const {
    settings: { quickAccessLink },
    blogSlug
  } = profile;

  const currentBlog = getCurrentBlog(blogSlug, pathname, myCommunities);

  return (
    <React.Fragment>
      <NavBarContainer>
        <ul data-testid="navbar-loggedin">
          <li>
            <IconButton>
              <Link
                to={`/blog/${currentBlog}/0/edit`}
                title="новая запись"
                data-testid="new-entry-link"
              >
                <PlusIcon
                  className={
                    pathname === `/blog/${currentBlog}/0/edit`
                      ? 'icon-active'
                      : ''
                  }
                />
              </Link>
            </IconButton>
          </li>
          <li>
            <IconButton>
              <Link
                to={`/blog/${blogSlug}`}
                title="мой дневник"
                data-testid="my-blog-link"
              >
                <JournalIcon
                  className={
                    pathname === `/blog/${blogSlug}` ? 'icon-active' : ''
                  }
                />
              </Link>
            </IconButton>
          </li>
          <li>
            <IconButton>
              <Link
                to={`/blog/${blogSlug}/favorites`}
                title="избранное"
                data-testid="my-favorites-link"
              >
                <FavoritesIcon
                  className={
                    pathname === `/blog/${blogSlug}/favorites` ||
                    pathname === `/blog/${blogSlug}/bookmarks`
                      ? 'icon-active'
                      : ''
                  }
                />
              </Link>
            </IconButton>
          </li>
          <li>
            <IconButton
              onClick={setSidebar('comments')}
              title="комментарии"
              data-testid="my-notifications-button"
            >
              <ChatIcon
                className={currentSidebar === 'comments' ? 'icon-active' : ''}
              />
              {notificationsTotal > 0 && (
                <NotificationNumber>{notificationsTotal}</NotificationNumber>
              )}
            </IconButton>
          </li>
          <li>
            <IconButton
              onClick={setSidebar('search')}
              title="поиск"
              data-testid="my-search-button"
            >
              <SearchIcon
                className={
                  pathname === `/blog/${blogSlug}/search` ||
                  pathname === `/search` ||
                  currentSidebar === 'search' ? 'icon-active' : ''}
              />
            </IconButton>
          </li>
          {quickAccessLink && quickAccessLink.enable && (
            <li>
              <IconButton>
                <Link
                  to={'/' + quickAccessLink.url}
                  title={quickAccessLink.name}
                  data-testid="my-quick-access-link"
                >
                  <QuickAccessIcon
                    className={
                      pathname === '/' + quickAccessLink.url
                        ? 'icon-active'
                        : ''
                    }
                  />
                </Link>
              </IconButton>
            </li>
          )}
          <li>
            <AvatarButton
              onClick={setSidebar('menu')}
              title="Меню"
              data-testid="user-menu-button"
            >
              <img src={avatar} alt="profile menu" />
            </AvatarButton>
          </li>
        </ul>
      </NavBarContainer>
      <Sidebar type={currentSidebar} onClickAway={handleSidebarClickAway} />
    </React.Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    profile: selectActiveProfileAugmented(state, { activeProfileId }),
    notificationsTotal: selectNotificationsTotal(state),
    myCommunities: selectCommunities(state, { activeProfileId })
  };
};

const mapDispatchToProps = {
  loadNotifications
};

export const NavBarLoggedInContainer = WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(NavBar)
);
