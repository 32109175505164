import {
  subscribeToEntryApi,
  unsubscribeFromEntryApi
} from 'api/subscriptions';

export function useSubscribe({ setEntry, token }) {
  async function toggleSubscribe(entry) {
    let res;
    if (entry.meta.subscribed) {
      res = await unsubscribeFromEntryApi({ entryId: entry.id, token });
    } else {
      res = await subscribeToEntryApi({ entryId: entry.id, token });
    }

    if (res.error) {
      return false;
    }

    setEntry?.((currentEntry) => ({
      ...currentEntry,
      meta: { ...currentEntry.meta, subscribed: !currentEntry.meta.subscribed }
    }));
    return true;
  }

  return { toggleSubscribe };
}
