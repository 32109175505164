import { query } from 'api/Query';

//https://slonopotam.net/v2/tags?filters[tag|contains]=error&page[size]=5&page[number]=1
export const getTags = async ({ token, text, pageSize, pageNumber }) => {
  const queryParams = {
    'filters[tag|contains]': text,
    'page[size]': pageSize,
    'page[number]': pageNumber
  };

  try {
    return await query({
      method: 'GET',
      resource: 'tags',
      queryParams,
      token
    });
  } catch (e) {
    console.error(e);
    return { error: 'что-то пошло не так' };
  }
};

export const getTagsByProfile = async ({ token, profileId }) => {
  try {
    return await query({
      method: 'GET',
      resource: `profiles/${profileId}/tags`,
      token
    });
  } catch (e) {
    console.error(e);
    return { error: 'что-то пошло не так' };
  }
};
