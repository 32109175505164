import React from 'react';
import styled from 'styled-components';
import FieldWrapper from './StyledFieldWrapper';
import Error from './StyledError';
import css from 'components/FormElements/Checkbox.module.css';
import TickIcon from 'styles/Icons/TickIcon';
import { classNames } from 'utils/helpers/classNames';

const CheckboxWrapper = styled(FieldWrapper)`
  @media (min-width: 1200px) {
    /* justify-content: center; */
  }

  .error {
    margin-top: -15px;
  }
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
`;

const StyledCheckbox = styled.input`
  appearance: none;
  outline: none;
  cursor: pointer;
  width: 22px;
  height: 22px;
  border: 1px solid;
  margin-right: 15px;
  border-color: ${props =>
    props.checked
      ? !props.inverted
        ? props.theme.accent
        : props.theme.white
      : (props.isRequired &&
          props.touched &&
          (!props.inverted
            ? props.theme.brandDark
            : props.theme.textLighter)) ||
        (!props.inverted
          ? props.theme.backgroundDarker
          : props.theme.textLighter)};
`;

const Checkbox = ({
  label,
  labelElement,
  isRequired,
  touched,
  error,
  checked,
  id,
  onChange,
  className,
  invertedColor,
  inputClassName,
  children
}) => (
  <div className={className}>
    <CheckboxWrapper>
      <StyledLabel htmlFor={id} checked={checked}>
        <div className={css.checkboxContainer}>
          <StyledCheckbox
            type="checkbox"
            inverted={invertedColor}
            isRequired={isRequired}
            touched={touched}
            id={id}
            checked={checked}
            onChange={onChange}
          />
          {checked && (
            <div
              className={classNames({
                [css.iconContainer]: true,
                [css.iconChecked]: checked,
                [css.inverted]: invertedColor
              })}
            >
              <TickIcon />
            </div>
          )}
        </div>
        {label || labelElement || children}
      </StyledLabel>
    </CheckboxWrapper>
    <CheckboxWrapper>
      {touched && error && <Error className="error">{error}</Error>}
    </CheckboxWrapper>
  </div>
);

export default Checkbox;
