import { query } from './Query';
//
// const activate = async ({ userId, termsOfService = true, isAdult, token }) => {
//   try {
//     await query(
//       'PATCH',
//       'users',
//       {
//         termsOfService,
//         isAdult
//       },
//       userId
//     );
//
//     let response = await query('POST', 'sessions', {
//       action: 'confirm',
//       token
//     });
//
//     const { accessToken } = response.data;
//
//     if (!accessToken) {
//       return { error: 'что-то случилось и токен не вернулся' };
//     }
//   } catch (err) {
//     let errMessage = 'Ошибка активации аккаунта';
//     if (err.status === 422) {
//       errMessage = 'Код недействителен или просрочен';
//     }
//     console.log('activateAccount', err);
//     return { error: errMessage };
//   }
// };

export const createUserApi = async ({
  email,
  password,
  termsOfService = true,
  isAdult = true
}) => {
  //token is not expected here
  try {
    await query({
      method: 'POST',
      resource: 'users',
      queryParams: {
        email,
        password
      }
    });
    return { status: 'ok' };
  } catch (err) {
    console.error(err);
    if (err.response.status === 400) {
      return { error: 'адрес уже зарегистрирован' };
    }
    return { error: 'что-то не получилось' };
  }
};

export const getUserData = async ({ userId, token }) => {
  try {
    let response = await query({
      method: 'GET',
      resource: 'users',
      queryParams: {
        include: 'profiles'
      },
      token,
      id: userId
    });
    return {
      status: 'ok',
      data: response.data
    };
  } catch (err) {
    console.log(err);

    return { error: 'не получилось получить данные пользователя' };
  }
};

export const updateUserApi = async ({ data, userId, token }) => {
  try {
    let res = await query({
      method: 'PATCH',
      resource: 'users',
      queryParams: data,
      token,
      id: userId
    });
    if (res.data) {
      return { status: 'ok', data: res.data };
    }
  } catch (err) {
    console.log('userUpdate', err);
    return { error: 'не получилось обновить данные пользователя' };
  }
};

export const changeUserEmailApi = async ({ newEmail, userId, token }) => {
  try {
    let res = await query({
      method: 'PATCH',
      resource: `users/${userId}/email-reset?email=${newEmail}`,
      token
    });
    if (res.data) {
      return { status: 'ok', data: res.data };
    }
  } catch (err) {
    console.log('userUpdate', err.response);
    return {
      error: 'не получилось поменять почту'
    };
  }
};

export const confirmUserEmailApi = async ({ emailToken, userToken }) => {
  console.log(userToken);
  try {
    let res = await query({
      method: 'POST',
      resource: `users/email-reset-confirmation?token=${emailToken}`,
      token: userToken,
      withNonce: false
    });
    if (res.data) {
      return { status: 'ok', data: res.data };
    }
  } catch (err) {
    console.log('userConfirm', err.response);
    return {
      error: 'не получилось подтвердить почту'
    };
  }
};

export const updateUserPasswordApi = async ({
  oldPassword,
  newPassword,
  userId,
  token
}) => {
  try {
    let res = await query({
      method: 'PATCH',
      resource: 'users',
      queryParams: { password: newPassword, 'current-password': oldPassword },
      token,
      id: userId
    });
    if (res.data) {
      return { status: 'ok', data: res.data };
    }
  } catch (err) {
    console.log('userUpdate', err.response);
    if (err.response.status === 403) {
      return { error: 'неверный текущий пароль' };
    }
    return {
      error: 'не получилось поменять пароль'
    };
  }
};
