import styled from 'styled-components';

export const InactiveProfiles = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-align-items: center;
  padding: 0px 40px 15px 45px;

  a:hover {
    color: ${props => props.theme.brand};
  }

  h4 {
    background-color: ${props => props.theme.backgroundLight};
    padding: 0 15px;
  }

  h5 {
    font-weight: normal;
    margin-top: 5px;
    font-size: 14px;
    padding: 0 15px;
  }
`;

export const InactiveProfileAvatar = styled.button`
  max-width: 46px;
  max-height: 46px;
  margin: 5px;
  transition: all 0.2s;
  border: 0;
  background: transparent;
  font-size: 0px;

  img {
    height: auto;
    width: auto;
    max-width: 46px;
    max-height: 46px;
    border: 3px solid ${props => props.theme.white};
    border-radius: 2px;

    &:hover {
      border: 3px solid ${props => props.theme.brand};
    }
  }
`;
