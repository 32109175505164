import React, { useState } from 'react';
import { getAvatar } from 'utils/helpers/profiles';
import { getCommentUrl, getEntryUrl } from 'utils/helpers/getUrl';
import {
  EntryWithCommentsWrapper,
  StyledComment,
  StyledCommentCounter,
  StyledEntry
} from '../styled';
import { truncateEntry } from 'utils/helpers/strings';

function NotificationDetailsForEntry({
  entry,
  entryHasMention = false,
  comments
}) {
  const [open, setOpen] = useState(false);

  const entryUrl = getEntryUrl(entry);

  const earliestCommentUrl = entryHasMention
    ? entryUrl
    : getCommentUrl(comments && comments.length && comments[0].id, entry);

  return (
    <EntryWithCommentsWrapper
      className={open ? 'comments--open' : 'comments--closed'}
    >
      <StyledEntry key={entry.id}>
        <span
          className="plus"
          onClick={() => setOpen(!open)}
          title="раскрыть комментарии"
        >
          {open ? '–' : '+'}
        </span>
        <a href={earliestCommentUrl} title="к комментариям">
          <div className="entry-text">{truncateEntry(entry, 50)} </div>
          <StyledCommentCounter className="comment-amount">
            {(comments?.length ?? 0) + (entryHasMention ? 1 : 0)}
          </StyledCommentCounter>
        </a>
      </StyledEntry>
      {open && entryHasMention && (
        <div className={`comments`}>
          <StyledComment key={entry.id}>
            <a href={entryUrl} title="к посту">
              <div className="avatar">
                <img width="50px" src={getAvatar(entry.profile)} alt="" />
              </div>
              <div>
                <div className="comment-author-nickname">
                  {entry.profile.nickname}
                </div>
                <div className="comment-text">
                  {truncateEntry({ content: entry.content }, 50)}
                </div>
              </div>
            </a>
          </StyledComment>
        </div>
      )}
      {open && comments && comments.length && (
        <div className={`comments`}>
          {comments.map(comment => {
            if (!entry || !comment) {
              return null;
            }
            const author = comment.profile;
            const avatar = getAvatar(author);

            const commentUrl = getCommentUrl(comment.id, entry);

            return (
              <StyledComment key={comment.id}>
                <a href={commentUrl} title="к комментарию">
                  <div className="avatar">
                    <img width="50px" src={avatar} alt="" />
                  </div>
                  <div>
                    <div className="comment-author-nickname">
                      {author.nickname}
                    </div>
                    <div className="comment-text">
                      {truncateEntry(comment, 50)}
                    </div>
                  </div>
                </a>
              </StyledComment>
            );
          })}
        </div>
      )}
    </EntryWithCommentsWrapper>
  );
}

export default NotificationDetailsForEntry;
