import styled from 'styled-components';
import { getRelativeUrl } from 'utils/helpers/hostNames';
import { AddToFavoritesStyled } from 'components/Shared/AddToFavorites.style';

export const BlogDisplayStyled = styled.div`
  width: 100%;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.5) 23%,
      rgba(0, 0, 0, 0.44) 47%,
      rgba(0, 0, 0, 0.47) 69%,
      rgba(0, 0, 0, 0.6) 100%
    ),
    ${p => {
        const { header } = p.design;
        return header.image.enable
          ? `url("${getRelativeUrl(header.image.url)}") ${
              header.image.position
            }/${header.image.size} ${header.image.repeat},`
          : `linear-gradient(${header.background}, ${header.background}),`;
      }}
      ${p => {
        const { background } = p.design;
        return background.enable
          ? `url("${getRelativeUrl(background.url)}") ${background.position}/${
              background.size
            } ${background.repeat},`
          : '';
      }}
      ${p => p.design.colors.background};

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 15px;

  && .blog-title {
    font-weight: bold;
    font-size: 16px;
    color: ${p => p.theme.backgroundLighter};
    text-align: center;
    word-break: break-word;
    text-decoration: none;
    margin-bottom: 15px;
  }

  ${AddToFavoritesStyled} {
    width: 64px;
    height: 50px;
    padding: 7px 14px;
  }
`;
