import React from 'react';

const Icon = ({ className }) => (
  <svg
    viewBox="0 0 15 15"
    className={`icon icon-plus-for-expand ${className}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="minus-sign main-outline"
      fill="currentColor"
      d="M 6.5,0 V 6.5 H 0 v 2 H 6.5 V 15 h 2 V 8.5 H 15 v -2 H 8.5 V 0 Z"
    />
  </svg>
);

export default Icon;
