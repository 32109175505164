export default ({ colors }) => `
  .fr-view table, table {
    border-collapse: collapse;
    empty-cells: show;
    border-color: ${colors.borders};
    border-spacing: 0;
  }

  table td {
    border: 1px solid ${colors.borders};
    padding: 3px;
  }
  .fr-view table th, table th {
    background: ${colors.elementsBack};
    border: 1px solid ${colors.borders};
    padding: 3px;
  }
  .fr-view table.fr-alternate-rows tbody tr:nth-child(2n),
  table.fr-alternate-rows tbody tr:nth-child(2n) {
    background: ${colors.elementsBack};
  }

  .fr-dashed-borders td {
    border-style: dashed;
  }
  .fr-thick {
    border-width: 2px;
  }
  td.fr-highlighted {
    border-color: ${colors.buttons};
    border-width: 2px;
  }
`;
