import React from 'react';
import styled from 'styled-components';

const BlocksWrapper = styled.div`
  width: 100%;
  padding-top: 23px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 1050px) {
    justify-content: center;
  }

  @media (max-width: 800px) {
    justify-content: center;
    padding-top: 0;

    div:last-child {
      margin-bottom: 0px;
    }
  }
`;

const Block = styled.div`
  flex: 1 0 300px;
  max-width: 400px;
  min-width: 320px;
  padding: 0 25px;

  @media (max-width: 1050px) {
    padding: 0 25px;
    max-width: 370px;
  }
  @media (max-width: 800px) {
    margin-bottom: 10px;
    p:last-child {
      margin-bottom: 0px;
    }
  }
  h3 {
    font-family: 'Montserrat Alternates', sans-serif;
    text-transform: lowercase;
    font-weight: bold;
    text-align: center;
    font-size: 24px;
    margin-bottom: 8px;
    line-height: 1.3;

    @media (max-width: 600px) {
      font-size: 20px;
    }
  }

  p {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    font-size: 16px;
    text-align-last: center;
  }
`;

const AboutBlock = () => (
  <BlocksWrapper>
    <Block>
      <h3>никакой рекламы</h3>
      <p>
        Мы верим, что поддерживать работу сайта можно более гуманными способами.
        Не вы для сайта, а сайт для вас.
      </p>
    </Block>
    <Block>
      <h3>приватность</h3>
      <p>
        Гибкие настройки доступа к вашим записям, возможность полной
        анонимности. Мы не собираем ваши персональные данные.
      </p>
    </Block>
    <Block>
      <h3>индивидуальность</h3>
      <p>
        Можно настроить вид дневника, завести несколько блогов и выбрать, что
        читать в ленте. Мы все разные.
      </p>
    </Block>
  </BlocksWrapper>
);

export default AboutBlock;
