import { getAvatar } from 'utils/helpers/profiles';
import React from 'react';
import css from 'scenes/Management/Community/ProfileLink.module.css';
import { classNames } from 'utils/helpers/classNames';

function ProfileLink({
  profile,
  className = css.profileName,
  link,
  children: payload
}) {
  const avatar = getAvatar(profile);
  const profileLink = link ?? `/profile/${profile.id}`;

  return (
    <div className={css.container}>
      <div className={css.avatar}>
        <img className={css.avatarImg} src={avatar} alt="" />
      </div>
      <div className={css.data}>
        <a
          href={profileLink}
          className={classNames({
            [css.link]: true,
            [className]: Boolean(className)
          })}
        >
          {profile.nickname}
        </a>
        <>{payload}</>
      </div>
    </div>
  );
}
export default ProfileLink;
