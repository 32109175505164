import { createSelector } from 'reselect';
import { selectLists } from './selectLists';

export const selectFavoriteProfiles = createSelector(selectLists, lists => {
  return lists.favorites.map(id => ({
    ...lists.profiles[id],
    isFavorite: true,
    isReader: lists.readers.includes(id)
  }));
});
