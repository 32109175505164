import React, { useEffect, useState } from 'react';
import Switch from 'components/FormElements/Switch';
import TextField from 'components/FormElements/TextField';
import { HOSTNAME } from 'configs/dybr';
import css from 'scenes/User/Settings/SettingsCommon.module.css';

import {
  Block,
  ContentWrapper,
  Settings,
  SettingSectionTitle,
  SettingsFlexbox
} from 'components/DybrComponents/StyledServicePagesElements';
import { connect } from 'react-redux';
import { updateProfileSetting } from 'store/user/thunks/updateProfileSetting';
import { selectActiveProfileSettingsAugmented } from 'store/user/selectors/selectActiveProfileSettingsAugmented';
import { useTokenState } from 'store/localStorage/useTokenState';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';
import { MAX_ENTRY_CONTENT_HEIGHT } from '../../../components/Shared/constants';

function CommonSettingsScene({ settings, updateProfileSetting }) {
  useEffect(() => {
    document.title = `Общие настройки`;
  }, []);

  const [newComments, setNewComments] = useState(
    settings.notifications.comments.enable
  );
  const [newPosts, setNewPosts] = useState(
    settings.notifications.entries.enable
  );
  const [newMessages, setNewMessages] = useState(
    settings.notifications.messages.enable
  );
  const [newMentions, setNewMentions] = useState(
    settings.notifications.mentions.enable
  );
  const [quickLinkEnable, setQuickLinkEnable] = useState(
    settings.quickAccessLink.enable
  );
  const [quickLinkName, setQuickLinkName] = useState(
    settings.quickAccessLink.name
  );
  const [quickLinkUrl, setQuickLinkUrl] = useState(
    settings.quickAccessLink.url
  );
  const [autocutLength, setAutocutLength] = useState(
    settings.feed.autocutLength
      ? settings.feed.autocutLength
      : MAX_ENTRY_CONTENT_HEIGHT
  );
  const [autocutEnabled, setAutocutEnabled] = useState(
    autocutLength ? autocutLength > 0 : true
  );
  const [token] = useTokenState();

  return (
    <ContentWrapper>
      <Block>
        <SettingsFlexbox>
          <SettingSectionTitle>
            <h4>Почтовые уведомления</h4>
            <p>О чём бы вы хотели получать уведомления на почту?</p>
          </SettingSectionTitle>
          <Settings>
            <Switch
              label="новые комментарии"
              checked={newComments}
              onClick={e => {
                setNewComments(!newComments);
                updateProfileSetting(
                  'notifications.comments',
                  { enable: e.target.checked, regularity: 'timely' },
                  token
                );
              }}
            />
            <Switch
              label="новые посты в избранном"
              checked={newPosts}
              onClick={e => {
                setNewPosts(!newPosts);
                updateProfileSetting(
                  'notifications.entries',
                  { enable: e.target.checked, regularity: 'timely' },
                  token
                );
              }}
            />
            <Switch
              label="новые личные сообщения"
              checked={newMessages}
              onClick={e => {
                setNewMessages(!newMessages);
                updateProfileSetting(
                  'notifications.messages',
                  { enable: e.target.checked, regularity: 'timely' },
                  token
                );
              }}
            />
            <Switch
              label="новые упоминания"
              checked={newMentions}
              onClick={e => {
                setNewMentions(!newMentions);
                updateProfileSetting(
                  'notifications.mentions',
                  { enable: e.target.checked, regularity: 'timely' },
                  token
                );
              }}
            />
          </Settings>
        </SettingsFlexbox>
      </Block>
      <Block>
        <SettingsFlexbox>
          <SettingSectionTitle>
            <h4>Кнопка быстрого доступа</h4>
            <p>
              Вы можете добавить свою личную кнопку в верхнюю панель, которая
              будет вести куда угодно на сайте. Например, можно сделать ссылку
              на общую ленту.
            </p>
          </SettingSectionTitle>
          <Settings>
            <Switch
              label="использовать быструю кнопку"
              checked={quickLinkEnable}
              onClick={e => {
                setQuickLinkEnable(!quickLinkEnable);
                updateProfileSetting(
                  'quickAccessLink.enable',
                  e.target.checked,
                  token
                );
              }}
            />
            <TextField
              label="название кнопки"
              value={quickLinkName}
              onChange={e => {
                setQuickLinkName(e.target.value);
              }}
              onBlur={e => {
                updateProfileSetting(
                  'quickAccessLink.name',
                  e.target.value,
                  token
                );
              }}
              touched={true}
            />
            <TextField
              className={css.topMargin}
              prefix={HOSTNAME + '/'}
              label="адрес страницы"
              value={quickLinkUrl}
              onChange={e => {
                setQuickLinkUrl(e.target.value);
              }}
              onBlur={e => {
                updateProfileSetting(
                  'quickAccessLink.url',
                  e.target.value,
                  token
                );
              }}
              touched={true}
            />
          </Settings>
        </SettingsFlexbox>
      </Block>
      <Block>
        <SettingsFlexbox>
          <SettingSectionTitle>
            <h4>Автоматическое ограничение длинных постов</h4>
            <p>
              Показывает только небольшую часть записи. По кнопке “Показать
              полностью” можно раскрыть запись и прочитать целиком.
            </p>
          </SettingSectionTitle>
          <Settings>
            <Switch
              label="использовать автокат в общей ленте"
              checked={autocutEnabled}
              onClick={e => {
                setAutocutEnabled(!autocutEnabled);
                const newAutocutLength = autocutEnabled
                  ? -1
                  : MAX_ENTRY_CONTENT_HEIGHT;
                setAutocutLength(newAutocutLength);
                updateProfileSetting(
                  'feed.autocutLength',
                  newAutocutLength,
                  token
                );
              }}
            />
          </Settings>
        </SettingsFlexbox>
      </Block>
    </ContentWrapper>
  );
}

const mapDispatchToProps = {
  updateProfileSetting
};

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    settings: selectActiveProfileSettingsAugmented(state, { activeProfileId })
  };
};

export const CommonSettingsContainer = WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(CommonSettingsScene)
);
