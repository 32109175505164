import { createSelector } from 'reselect';
import { selectNotificationItems } from './selectNotificationItems';

export const selectNotificationIdsByCommentIds = createSelector(
  selectNotificationItems,
  (state, { commentIds }) => commentIds,
  (notifications, commentIds) =>
    notifications
      .filter(notification => commentIds.includes(notification.comment))
      .map(notification => notification.id)
);

export const selectNotificationsByMessageId = createSelector(
  selectNotificationItems,
  (state, { messageIds }) => messageIds,
  (notifications, messageIds) => {
    return notifications.filter(notification =>
      messageIds.includes(notification.message?.id)
    );
  }
);

export const selectNotificationIdsByEntryId = createSelector(
  selectNotificationItems,
  (state, { entryId }) => entryId,
  (notifications, entryId) =>
    notifications
      .filter(notification => notification.entry === entryId)
      .filter(notification => !notification.comment)
      .map(notification => notification.id)
);
