import { createSelector } from 'reselect';
import { selectUser } from 'store/user/selectors/selectUser';
import defaultProfileSettings from 'configs/defaultProfileSettings';
import { merge } from 'lodash';
import { selectProfiles } from 'store/user/selectors/selectProfiles';

export const selectInactiveProfilesAugmented = createSelector(
  selectUser,
  selectProfiles,
  (state, { activeProfileId }) => activeProfileId,
  (user, profiles, activeProfileId) => {
    const inactiveProfiles = { ...profiles };

    if (activeProfileId && activeProfileId !== '0') {
      delete inactiveProfiles[activeProfileId];
    }

    return Object.values(inactiveProfiles).map((profile) => ({
      ...profile,
      settings: merge({}, defaultProfileSettings, profile.settings)
    }));
  }
);
