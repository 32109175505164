import { query } from 'api/Query';

export const joinCommunityApi = async ({
  activeProfileId,
  communityId,
  token
}) => {
  try {
    const queryParams = {
      profile: { id: activeProfileId, type: 'profiles' },
      community: { id: communityId, type: 'profiles' },
      relationshipNames: ['profile', 'community']
    };
    return await query({
      method: 'POST',
      resource: `community-join-requests`,
      queryParams,
      token,
      withNonce: false
    });
  } catch (err) {
    console.log('joinCommunityApi', err);
    return { error: 'что-то пошло не так' };
  }
};

export const leaveCommunityApi = async ({
  activeProfileId,
  communityId,
  token
}) => {
  try {
    await query({
      method: 'DELETE',
      resource: `profiles/${communityId}/relationships/community-participants`,
      queryParams: [
        {
          id: activeProfileId,
          type: 'profiles'
        }
      ],
      token
    });
    return { status: 'ok' };
  } catch (err) {
    console.log('leaveCommunityApi', err);
    return { error: 'что-то пошло не так' };
  }
};

export const getCommunityMembersApi = async ({ communityId, token }) => {
  try {
    return await query({
      method: 'GET',
      resource: `profiles/${communityId}/community-participants`,
      token
    });
  } catch (err) {
    console.log('getCommunityMembersApi', err);
    return { error: 'что-то пошло не так' };
  }
};

export const getCommunityJoinRequestsApi = async ({ communityId, token }) => {
  try {
    const queryParams = {
      'filters[state]': 'pending',
      'filters[community-id]': communityId
    };
    return await query({
      method: 'GET',
      resource: `community-join-requests`,
      queryParams,
      token
    });
  } catch (err) {
    console.log('getCommunityJoinRequestsApi', err);
    return { error: 'что-то пошло не так' };
  }
};

export const editCommunityJoinRequestsApi = async ({
  request,
  state,
  communityId,
  token
}) => {
  try {
    const queryParams = {
      state,
      profile: { id: request.profile.id, type: 'profiles' },
      community: { id: communityId, type: 'profiles' },
      relationshipNames: ['profile', 'community']
    };
    return await query({
      method: 'PATCH',
      resource: `community-join-requests`,
      id: request.id,
      queryParams,
      token
    });
  } catch (err) {
    console.log('getCommunityJoinRequestsApi', err);
    return { error: 'что-то пошло не так' };
  }
};
