import convert from 'htmr';
import { More, moreTagsToJSX } from 'components/Froala/More';
import { Link } from 'components/Froala/Link';

// used before rendering html from database

// fixes old comment links that do not work now
// with time, we can drop it, not much harm
function fixCommentLinks(html = '') {
  const regex = /<a href="\?comment=(\d+)">/g;

  html = html.replace(regex, "<a href='#$1'/>");

  html = html.replace(/<notifications@dybr.ru>/g, '');

  return html;
}

export default function processHtmlToReact(html = '') {
  html = fixCommentLinks(moreTagsToJSX(html));

  // html = brToParagraphs(html); does not combine with MORE tags

  // <more /> tags are going to be replaced with More component
  return convert(html, {
    transform: {
      a: Link,
      more: More
    }
  });
}
