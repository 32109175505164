import React, { useEffect, useState } from 'react';
import Accordion from '../Accordion';
import { getRandomProfile } from 'api/profiles';
import { useTokenState } from 'store/localStorage/useTokenState';
import { navigate, useMatch } from '@reach/router';
import BlogSearchForm from './BlogSearchForm';
import GlobalSearchForm from './GlobalSearchForm';
import Tabs from 'components/DybrComponents/Tabs';
import { TabContent } from '../SidebarForm';

function SearchSidebar() {
  const tabs = [
    { title: 'по всем записям', name: 'global' },
    { title: 'по дневнику', name: 'local' }
  ];
  const [activeTab, setActiveTab] = useState('');
  const [token] = useTokenState();
  const match = useMatch('/blog/:blogSlug/*');

  useEffect(() => {
    if (!activeTab) {
      setActiveTab(match ? 'local' : 'global');
    }
  }, [match]);

  async function redirectToRandomProfile() {
    const response = await getRandomProfile({ token });
    if (!response.error && response.status === 'ok') {
      navigate(`/blog/${response.data.blogSlug}`);
    } else {
      alert('Упс! Что-то пошло не так');
    }
  }

  return (
    <>
      <Accordion
        title="случайный дневник"
        initialState="closed"
        canChangeState={false}
        onClick={redirectToRandomProfile}
      />
      <Accordion title="поиск" initialState="open" canChangeState={false}>
        <Tabs
          tabs={tabs}
          currentTab={activeTab}
          onTabChange={setActiveTab}
          containerClassName="sidebar-tabs"
        />
        <TabContent active={activeTab === 'global'}>
          <GlobalSearchForm />
        </TabContent>
        <TabContent active={activeTab === 'local'}>
          <BlogSearchForm initialBlogSlug={match ? match.blogSlug : null} />
        </TabContent>
      </Accordion>
    </>
  );
}

export const SearchSidebarContainer = SearchSidebar;
