/* Comment component */
import React, { useEffect, useRef, useState } from 'react';
import { Link } from '@reach/router';
import moment from 'moment';
import processHtmlToReact from 'utils/helpers/processHtml';
// import BookmarkUp from 'components/Icons/BookmarkNormalView';
import EntryMenu from 'components/Shared/EntryMenu';
import SettingsCogIcon from 'styles/Icons/SettingsCog';
import ReplyIcon from 'styles/Icons/ReplyToComment';
import LinkIcon from 'styles/Icons/Link';
import UrlTooltip from 'components/Shared/UrlTooltip';
import { makeProfileUrl } from 'utils/helpers/profiles';
import AvatarWithMenu from 'components/Shared/AvatarWithMenu';
import { BirthdayIcon } from 'scenes/Dybr/Users/BirthdayIcon';

import useCopyTooltip from 'utils/hooks/useCopyTooltip';
import { HOSTNAME } from 'configs/dybr';
import isCommentEditTimeOut from '../../utils/helpers/commentEdit';

export default function Comment(props) {
  const ref = useRef(null);
  const [showCommentMenu, setShowCommentMenu] = useState(false);

  // scroll to the comment on load
  useEffect(() => {
    if (props.scrollIntoView) {
      ref.current.scrollIntoView();
      props.onScrollIntoView();
    }
  }, [props]);

  const {
    copy: copyCommentURL,
    isCopied: isUrlCopied,
    showTooltip: showUrlTooltip,
    toggle: toggleUrlTooltip
  } = useCopyTooltip(HOSTNAME + props.url);

  function onDelete() {
    if (props.canDelete && props.onDelete) {
      if (window.confirm('Точно удалить комментарий?')) {
        props.onDelete(props.comment.id);
      }
    }
  }

  function createMenuItems() {
    const minutesSincePublished = moment().diff(
      moment(props.comment.createdAt),
      'minutes'
    );
    const canEdit =
      props.canEdit && !isCommentEditTimeOut(minutesSincePublished);

    const items = [];
    if (canEdit) {
      items.push(
        <button onClick={() => props.onEdit(props.comment.id)}>
          редактировать
        </button>
      );
    }
    if (props.canDelete) {
      items.push(<button onClick={onDelete}>удалить</button>);
    }

    return items;
  }

  function toggleCommentMenu() {
    setShowCommentMenu(showCommentMenu => !showCommentMenu);
  }

  const {
    url,
    comment: { content, id, profile, createdAt },
    canDelete,
    hasUserFunctions
  } = props;

  const comment = processHtmlToReact(content);

  return (
    <div ref={ref} className="blog-comment" id={id} data-testid="blog-comment">
      <div className="blog-comment-meta--up" id={id}>
        {/* (
            <div
              className={
                isBookmarked
                  ? 'blog-entry-bookmark active'
                  : 'blog-entry-bookmark'
              }
              onClick={this.toggleBookmark}
              title={
                isBookmarked ? 'удалить из закладок' : 'добавить в закладки'
              }
            >
              <BookmarkUp />
            </div>
            )*/}

        <AvatarWithMenu className="blog-comment-avatar" profile={profile} />

        <div className="blog-comment-author-wrapping">
          <div className="blog-comment-author">
            <Link to={makeProfileUrl(profile)}>{profile.nickname}</Link>
            <BirthdayIcon birthday={profile.birthday} nick={profile.nickname} />
          </div>

          {profile.settings.subtext && (
            <div className="blog-entry-subtext">
              <span>{profile.settings.subtext}</span>
            </div>
          )}
        </div>
        <div className="blog-comment-date">
          {moment(createdAt).format('HH:mm DD.MM.YYYY')}
        </div>
      </div>
      <div
        className="blog-comment-content"
        id={id}
        data-testid={'blog-comment-content'}
      >
        {comment}
      </div>
      <div className="blog-comment-meta--down" id={id}>
        <div className="blog-entry-interactions">
          {hasUserFunctions && (
            <div className="blog-entry-comment-icon">
              <span
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  props.handleReply(id, profile.id, profile.nickname)
                }
                title="ответить"
                data-testid={'blog-entry-comment-reply'}
              >
                <ReplyIcon />
              </span>
            </div>
          )}
        </div>

        <div className="blog-entry-controls">
          <span
            className="blog-entry-controls-link"
            onClick={copyCommentURL}
            title="скопировать URL"
          >
            <LinkIcon />
            {showUrlTooltip && (
              <UrlTooltip
                onClickAway={toggleUrlTooltip}
                isUrlCopied={isUrlCopied}
                text={'ссылка скопирована!'}
                url={url}
              />
            )}
          </span>
          {canDelete && (
            <span
              className="blog-entry-controls-cog"
              onClick={toggleCommentMenu}
            >
              <SettingsCogIcon />
              {showCommentMenu && (
                <EntryMenu
                  menuItems={createMenuItems()}
                  onClickAway={toggleCommentMenu}
                />
              )}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
