import React from 'react';

// basic, add more stuff if needed

const BlogCheckbox = ({ label, checked, id, onChange }) => (
  <div className="blog-checkbox">
    <input type="checkbox" id={id} checked={checked} onChange={onChange} />
    <label htmlFor={id}>{label}</label>
  </div>
);

export default BlogCheckbox;
