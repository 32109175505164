import React, { useEffect, useState } from 'react';
import WithActiveProfileId from '../../../store/localStorage/WithActiveProfileId';
import { connect } from 'react-redux';
import AvatarWithMenu from '../../../components/Shared/AvatarWithMenu';
import { getProfileApi } from '../../../api/profiles';
import { useTokenState } from '../../../store/localStorage/useTokenState';
import { BirthdayIcon } from '../Users/BirthdayIcon';
import { DirectMessageHeaderProfile } from '../Users/styled/StyledProfile';
import { EntryPageProvider } from '../../Blog/BlogEntryPage/EntryPageContext';
import defaultBlogDesign from '../../../configs/defaultBlogDesign';
import style from '../../../styles/blog/BlogCssConstructor';
import { ThemeProvider } from 'styled-components';
import { selectActiveProfileAugmented } from '../../../store/user/selectors/selectActiveProfileAugmented';
import { ConversationMessagesContainer } from './ConversationMessages';
import css from './ConversationScene.module.css';
import SimpleArrowLeft from '../../../styles/Icons/SimpleArrowLeftWithBackground';

import { ArrowIconWithBackground } from '../../../components/Shared/IconButton';

function ConversationScene({ targetProfileId, ownProfile }) {
  const [targetProfile, setTargetProfile] = useState();
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [error, setError] = useState('');
  const [token] = useTokenState();
  const isLoggedIn = Boolean(token);

  useEffect(() => {
    loadTargetProfile();
  }, [targetProfileId]);

  async function loadTargetProfile() {
    try {
      const res = await getProfileApi({
        profileId: targetProfileId,
        token
      });
      setTargetProfile(res.data);
    } catch (error) {
      setError('не удалось получить данные профиля: возможно, он находится в архиве или удален');
    } finally {
      setLoadingProfile(false);
    }
  }

  const theme = defaultBlogDesign;
  return (
    <ThemeProvider theme={theme}>
      <style>{style(theme)}</style>
      <div>
        {targetProfile && (
          <div className={css.header}>
            <div className={css.headerSideContainer}>
              {isLoggedIn && (
                <ArrowIconWithBackground light href={'/direct'}>
                  <SimpleArrowLeft className={css.backButtonIcon} />
                  <div className={css.backButtonText}>
                    вернуться ко всем разговорам
                  </div>
                </ArrowIconWithBackground>
              )}
            </div>
            <DirectMessageHeaderProfile>
              <AvatarWithMenu
                className="profile-avatar direct-message-header"
                profile={targetProfile}
              />
              <BirthdayIcon
                birthday={targetProfile.birthday}
                nick={targetProfile.nickname}
              />
              <div className="nickname">{targetProfile.nickname}</div>
            </DirectMessageHeaderProfile>
            <div className={css.headerSideContainer} />
          </div>
        )}
      </div>
      <EntryPageProvider>
        <ConversationMessagesContainer
          ownProfileId={ownProfile?.id}
          targetProfileId={targetProfileId}
        />
      </EntryPageProvider>
    </ThemeProvider>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    ownProfile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

const mapDispatchToProps = {};

export const ConversationContainer = WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(ConversationScene)
);
