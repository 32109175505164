export function classNames(classes) {
  return Object.entries(classes)
    .filter(([, value]) => value)
    .map(([key, value]) => key)
    .join(' ');
}

// const classes = {
//   'maybeClass': true,
//   'otherClass': true,
//   'probablyNotClass': false,
// };
//
// const myClassNames = classNames(classes);
// // Output: "maybeClass otherClass"
//
// <li className={myClassNames} />
