// in the absence of enums...
export const AccessType = {
  registered: 'registered',
  allowCustomList: 'allow-custom-list',
  denyCustomList: 'deny-custom-list',
  private: 'private',
  subscribers: 'subscribers',
  favorites: 'favorites',
  participants: 'participants',
  admins: 'admins'
};
