import React from 'react';
import Select from 'components/FormElements/Select';
import designConstants from 'configs/designConstants';
import { SettingWrapper } from 'scenes/Blog/designer/components/styled';

export default class AlignTextSetting extends React.Component {
  getDisplayOptions = () => {
    return designConstants.textAlignment.map((f, i) => ({
      label: f.label,
      value: i
    }));
  };

  handleSettingChange = ID => {
    this.props.update(this.props.nodeKey)(
      designConstants.textAlignment[ID].value
    );
  };
  getSettingID = () => {
    return designConstants.textAlignment.findIndex(
      s => s.value === this.props.setting
    );
  };

  render() {
    const { menuPlacement, nodeKey } = this.props;
    if (!nodeKey) return null;

    return (
      <SettingWrapper className="font-settings">
        <div className="label-section">выравнивание текста</div>
        <div className="select-section">
          <Select
            options={this.getDisplayOptions()}
            captureMenuScroll={true}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            className="alignment-select"
            menuPlacement={menuPlacement}
            selectedOptionID={this.getSettingID()}
            onChange={this.handleSettingChange}
          />
        </div>
      </SettingWrapper>
    );
  }
}
