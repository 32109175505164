const defaultUserSettings = {
  pagination: {
    entries: 10,
    comments: 20,
    profiles: 20,
    blogs: 20
  },
  disabledDesigns: [] // strings (design ids)
};

export default defaultUserSettings;
