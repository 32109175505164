import React, { useState } from 'react';
import { Link } from '@reach/router';
import styled from 'styled-components';
import HeadedBlock from 'components/DybrComponents/HeadedBox';
import {
  ActionButton,
  ImportantButton,
  LinkButton
} from 'components/Shared/Buttons';
import TextField from 'components/FormElements/TextField';
import { doLoginThunk } from 'store/user/thunks/doLoginThunk';
import { connect } from 'react-redux';
import { requestNewPasswordThunk } from 'store/user/thunks/requestNewPasswordThunk';
import { useTokenState } from 'store/localStorage/useTokenState';

const InputWrapper = styled.div``;
const FormWrapper = styled.div`
  font-size: 16px;
  position: relative;
  min-height: 252px;
`;

const ActionsWrapper = styled.div`
  padding: 20px 0;
  text-align: center;
  div {
    font-size: 14px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;

  button {
    margin: 0;
    padding: 16px;
  }
  & > button {
    width: 37%;
  }
  a {
    display: block;
    width: 57%;
  }
  a button {
    width: 100%;
  }
`;

// used alsp in account create forms, abstract?
const ErrorWrapper = styled.div`
  /*position: absolute;
  top: -17px;*/
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.textLighter};
  background-color: ${(props) => props.theme.brand};
  font-size: 14px;
  transition: height 0.3s, margin 0.6s;
  height: 0;
  margin-bottom: 0px;
  overflow: hidden;
  margin-bottom: 0px;

  ${(props) =>
    props.show
      ? `opacity: 1;
    height: 30px;
    padding: 5px;
    margin-bottom: 20px;
    `
      : ''};
`;

export function LoginForm({ doLoginThunk, requestNewPasswordThunk }) {
  const [view, setView] = useState('login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});

  const [, setToken] = useTokenState();

  const changeView = (view) => () => {
    setView(view);
    setErrors({});
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setErrors({});
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setErrors({});
  };

  const requestLogin = async (event) => {
    event.preventDefault();
    if (!email) {
      setErrors((err) => ({ ...err, emailError: 'не заполнено' }));
      return;
    }

    if (!password) {
      setErrors((err) => ({ ...err, passwordError: 'не заполнено' }));
      return;
    }
    setErrors({});

    setSubmitting(true);
    const res = await doLoginThunk({ email, password, setToken });
    setSubmitting(false);

    if (res.error) {
      setErrors((err) => ({ ...err, requestError: res.error }));
    }
  };

  const requestPasswordChange = async (event) => {
    event.preventDefault();
    if (!email) {
      setErrors((err) => ({ ...err, emailError: 'не заполнено' }));
      return;
    }
    setErrors({});
    setSubmitting(true);

    const res = await requestNewPasswordThunk({ email });

    setSubmitting(false);
    if (res.error) {
      setErrors((err) => ({ ...err, requestError: res.error }));
    } else {
      setSuccess(true);
    }
  };

  return (
    <HeadedBlock title="Вход на Дыбр" width="380px">
      <FormWrapper>
        <form onSubmit={requestLogin} data-testid="login-form">
          <ErrorWrapper data-testid="error-block" show={errors?.requestError}>
            {errors?.requestError}
          </ErrorWrapper>

          <InputWrapper>
            <TextField
              type="email"
              value={email}
              onChange={handleEmailChange}
              label="почта"
              id="username"
              error={errors?.emailError}
            />

            {view === 'login' && (
              <TextField
                type="password"
                value={password}
                onChange={handlePasswordChange}
                label="пароль"
                id="password"
                error={errors?.passwordError}
              />
            )}
          </InputWrapper>

          <ActionsWrapper>
            {view === 'login' && (
              <>
                <ButtonWrapper>
                  <ActionButton
                    primary
                    disabled={submitting}
                    type="submit"
                    data-testid="login-button"
                  >
                    {submitting ? 'Входим...' : 'Войти'}
                  </ActionButton>
                  <Link to={`/account/new`}>
                    <ImportantButton data-testid="create-account-button">
                      Создать аккаунт
                    </ImportantButton>
                  </Link>
                </ButtonWrapper>
                <LinkButton
                  onClick={changeView('requestPassword')}
                  className="link-button"
                >
                  Сменить пароль
                </LinkButton>
              </>
            )}
            {view === 'requestPassword' && (
              <ButtonWrapper>
                {!success && (
                  <ActionButton
                    primary
                    onClick={requestPasswordChange}
                    disabled={submitting}
                  >
                    {submitting ? 'Обрабатываем...' : 'Запросить смену пароля'}
                  </ActionButton>
                )}
                {!success && (
                  <LinkButton
                    onClick={changeView('login')}
                    className="link-button"
                  >
                    Назад, я вспомнил
                  </LinkButton>
                )}
                {success && (
                  <ActionButton
                    primary
                    onClick={changeView('login')}
                    disabled={submitting}
                  >
                    Назад
                  </ActionButton>
                )}
                {success && (
                  <div style={{ marginLeft: '10px' }}>
                    Ссылка отправлена на указанную почту!
                  </div>
                )}
              </ButtonWrapper>
            )}
          </ActionsWrapper>
        </form>
      </FormWrapper>
    </HeadedBlock>
  );
}

const mapDispatchToProps = {
  doLoginThunk,
  requestNewPasswordThunk
};

export const LoginFormContainer = connect(null, mapDispatchToProps)(LoginForm);
