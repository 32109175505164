import React, { useEffect, useState } from 'react';
import { getAvatar } from 'utils/helpers/profiles';
import {
  LIMIT_DURATION_FOR_COMMENT_EDIT,
  LIMIT_DURATION_FOR_COMMENT_EDIT_IN_MINUTES
} from 'configs/dybr';
import Editor from 'components/Froala/Editor';
import { useEntryPageContext } from 'scenes/Blog/BlogEntryPage/EntryPageContext';
import { selectActiveProfileAugmented } from 'store/user/selectors/selectActiveProfileAugmented';
import { connect } from 'react-redux';
import useInterval from 'utils/hooks/useInterval';
import moment from 'moment';
import { debounce } from 'utils/helpers/general';
import { navigate } from '@reach/router';
import { useTokenState } from 'store/localStorage/useTokenState';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';
import { selectFamiliarProfiles } from '../../../store/lists/selectors/selectFamiliarProfiles';
import isCommentEditTimeOut from '../../../utils/helpers/commentEdit';

const TimeLeft = ({ limitMin, since }) => {
  const [timeLeft, setTimeLeft] = useState(null);

  useInterval(() => {
    const secondsSincePublished = moment().diff(moment(since), 'seconds');
    setTimeLeft(limitMin * 60 - secondsSincePublished);
  }, 1000 / 60);
  return timeLeft > 0
    ? 'время для редактирования: ' + moment.utc(timeLeft * 1000).format('m:ss')
    : 'время для редактирования вышло';
};

const CommentEditForm = ({
  profile,
  editingComment,
  commentDraft,
  navigateTo,
  familiarProfiles,
  onPublishFinished,
  onCreateComment,
  onUpdateComment,
  onSaveDraftComment,
  sendButtonLabel
}) => {
  const [token] = useTokenState();
  const { editorRef, formRef } = useEntryPageContext();
  const [commentContent, setCommentContent] = useState(
    commentDraft?.content || ''
  );
  const [submitting, setSubmitting] = useState(false);
  const [publishingRequested, setPublishingRequested] = useState(false);

  useEffect(() => {
    const saveInterval = setInterval(() => {
      const editor = editorRef.current;
      if (editor && editor.codeView && editor.codeView.isActive()) {
        const content = editor.codeView.get();
        onSaveDraftComment(content);
      }
    }, 1000);

    return () => {
      if (saveInterval) {
        clearInterval(saveInterval);
      }
    };
  }, [editorRef]);

  const saveDraft = () => debounce(500, onSaveDraftComment);

  const handleContentChange = value => {
    setCommentContent(value);
    // this works only when outside of html editing, because froala does not fire any event in html mode
    saveDraft()(value || '');
  };

  useEffect(() => {
    const publishComment = async () => {
      setSubmitting(true);

      const editor = editorRef.current;

      let content = editor.html.get();

      // transform html and use it if the save button was hit from the code view
      if (editor.codeView && editor.codeView.isActive()) {
        editor.html.set(editor.codeView.get());
        content = editor.codeView.get();
      }

      let res;
      if (editingComment) {
        const minutesSincePublished = moment().diff(
          moment(editingComment.createdAt),
          'minutes'
        );
        if (isCommentEditTimeOut(minutesSincePublished - 1)) {
          window.alert(
            `Закончились ${LIMIT_DURATION_FOR_COMMENT_EDIT_IN_MINUTES} минут, в течение которых можно редактировать комментарий.`
          );
          onPublishFinished();
          return;
        }

        res = await onUpdateComment(content);
      } else {
        res = await onCreateComment(content);
      }
      setSubmitting(false);
      if (res.error) {
        window.alert(res.error);
        return;
      }
      setCommentContent('');
      await onPublishFinished();
      onSaveDraftComment('');
      const commentId = res.data?.id ?? '';
      navigate(navigateTo(commentId));
    };

    if (publishingRequested) {
      publishComment();
      setPublishingRequested(false);
    }
  }, [
    publishingRequested,
    editingComment,
    editorRef,
    onPublishFinished,
    profile.id,
    token
  ]);

  return (
    <div ref={formRef} className={`blog-comment entry-form`}>
      <div className="blog-comment-meta--up ">
        <div className="blog-comment-avatar" style={{ position: 'relative' }}>
          <img
            src={getAvatar(profile)}
            alt={profile.settings?.nickname ?? ''}
          />
        </div>
        <div className="blog-comment-author-wrapping">
          <div className="blog-comment-author">
            <a href={`/profile/${profile.id}`}>{profile.nickname}</a>
          </div>
          {profile.subtext && (
            <div className="blog-comment-subtext">
              <span>{profile.settings?.subtext ?? ''}</span>
            </div>
          )}
        </div>

        {editingComment && (
          <div className="blog-comment-date">
            {LIMIT_DURATION_FOR_COMMENT_EDIT && (
              <TimeLeft
                since={editingComment.createdAt}
                limitMin={LIMIT_DURATION_FOR_COMMENT_EDIT_IN_MINUTES}
              />
            )}
          </div>
        )}
      </div>

      <Editor
        value={commentContent}
        onChange={handleContentChange}
        onInit={editor => {
          editorRef.current = editor;
        }}
        onSubmitByEnter={() => setPublishingRequested(true)}
        autocompleteMentionOptions={familiarProfiles}
      />
      <p className="edit-entry-form-buttons">
        <span>
          <button
            disabled={submitting || commentContent.length === 0}
            onClick={() => setPublishingRequested(true)}
            className="add-entry-btn hvr-underline-from-center"
            data-testid="blog-entry-comment-button"
          >
            {submitting
              ? 'публикуем...'
              : editingComment
              ? 'сохранить'
              : sendButtonLabel
              ? sendButtonLabel
              : 'комментировать'}
          </button>
        </span>
      </p>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    profile: selectActiveProfileAugmented(state, { activeProfileId }),
    familiarProfiles: selectFamiliarProfiles(state)
  };
};

export const CommentEditFormContainer = WithActiveProfileId(
  connect(mapStateToProps)(CommentEditForm)
);
