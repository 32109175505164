import React from 'react';

class EntryMenu extends React.Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onClickAway();
    }
  };
  render() {
    return (
      <div ref={this.setWrapperRef} className="controls-dropdown-wrapper">
        <div className="controls-dropdown">
          <ul>
            {this.props.menuItems.map(item => (
              <li>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default EntryMenu;
