import styled from 'styled-components';

export const Cake = styled.div`
  line-height: 1;
  .icon {
    width: 17px;
    height: 17px;
  }

  /*  at profile page */
  .cake {
    fill: ${p => p.theme.favUnderline};
  }
  .cherry-stem {
    fill: ${p => p.theme.text};
  }
  .cherry {
    fill: ${p => p.theme.brand};
  }

  /* header at blog page */
  .blog-header-profile & {
    ${p =>
      !p.theme.header
        ? '/* to avoid undefined error */'
        : `
        .cake { fill: ${p.theme.header.color}; }
        .cherry-stem { fill: ${p.theme.header.color}; }
        .cherry { fill: ${p.theme.colors.accent}; }
    `}

    /* at favourite page */
    .blog-header--thin & {
      margin: 4px 0px 5px;
      & + a {
        bottom: 0;
      }
    }

    /* at blog page */
    .blog-header--main & {
      margin-top: 15px;
    }
    & + h4 {
      a {
        bottom: -7px;
      }
    }
  }

  /* at entry */
  .blog-comment-author &,
  .blog-entry-author & {
    display: inline-block;
    padding: 5px 5px 6px;
    margin-left: 8px;
    transform: translate(0, 0);

    ${p =>
      !p.theme.colors
        ? ` /* common feed */
          .cake { fill: ${p.theme.favUnderline} !important}
          .cherry-stem { fill: ${p.theme.text} !important}
          .cherry { fill: ${p.theme.brand} !important}
          background-color: ${p.theme.backgroundLighter}
          `
        : ` /* inside blog */
          .cake { fill: ${p.theme.colors.headers}; } 
          .cherry-stem { fill: ${p.theme.colors.text}; }
          .cherry { fill: ${p.theme.colors.accent}; }
          background-color: ${p.theme.colors.blocks}
      `}
  }
`;
