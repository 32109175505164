import produce from 'immer';
import keyBy from 'lodash/keyBy';

import {
  AddDesignAction,
  CollapseDesignerAction,
  DeleteDesignAction,
  EndEditingAction,
  ExpandDesignerAction,
  StartEditingAction,
  StoreDesignsAction,
  UpdateDesignAction
} from './actions';
import { LogoutAction } from 'store/user/actions/LogoutAction';
import cloneDeep from 'lodash/cloneDeep';
import { defaultDesignerState } from 'store/_setup/initialState';
import { ClearDesignsAction } from 'store/designer/actions/ClearDesignsAction';

/**
 *  structure:
 *
 *
    designerOn: false,
    designerCollapsed: false,
    designs: {
        '1': { ... }
    },

    draftDesign: {
        id: '0',
        name: "новый дизайн",
        colors: {},
        background: {},
        layout: {},
        header: {},
        fonts: {}
        ...same as design settings...
    }
 *
 */

export const designerReducer = produce((draft, action) => {
  switch (action.type) {
    case AddDesignAction.type:
      draft.designer.designs[action.design.id] = action.design;
      return;

    case StoreDesignsAction.type:
      draft.designer.designs = keyBy(action.designs, 'id');
      return;

    case DeleteDesignAction.type:
      delete draft.designer.designs[action.designID];
      return;

    case CollapseDesignerAction.type:
      draft.designer.designerCollapsed = true;
      return;

    case StartEditingAction.type:
      draft.designer.draftDesign = action.designData;
      draft.designer.designerOn = true;
      draft.designer.designerCollapsed = false;
      return;

    case EndEditingAction.type:
      draft.designer.draftDesign = {};
      draft.designer.designerOn = false;
      return;

    case ExpandDesignerAction.type:
      draft.designer.designerCollapsed = false;
      return;

    case UpdateDesignAction.type:
      draft.designer.draftDesign = {
        ...draft.designer.draftDesign,
        ...action.designData
      };
      return;
    case LogoutAction.type:
    case ClearDesignsAction.type:
      draft.designer = cloneDeep(defaultDesignerState);
      return;
    default:
      return;
  }
});
