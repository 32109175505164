import styled from 'styled-components';
import bg from 'styles/img/blog_header_bg_full.jpg';

export const FormWrapper = styled.div`
  width: 400px;
  min-height: 100vh;
  background-color: ${(p) => p.theme.backgroundLighter};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 50px 30px 170px 30px;

  h2 {
    padding-bottom: 40px;
    text-align: center;
    font-family: ${(p) => p.theme.headerFont};
    line-height: 1;
    text-transform: uppercase;
    font-size: 24px;
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-height: 400px;

    div {
      flex-shrink: 0;
    }

    margin-bottom: 150px;
  }
`;

export const ButtonWrapper = styled.div`
  padding: 20px 0;
  text-align: center;
  div {
    font-size: 14px;
  }
`;

// copied from login form. Abstract?
export const ErrorWrapper = styled.div`
  /*position: absolute;
  top: -17px;*/
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.textLighter};
  background-color: ${(props) => props.theme.brand};
  font-size: 14px;
  transition: height 0.3s, margin 0.6s;
  height: 0;
  margin-bottom: 0px;
  overflow: hidden;

  ${(props) =>
    props.show
      ? `opacity: 1;
    height: auto;
    padding: 5px;
    margin-bottom: 20px;
    `
      : ''};
`;

export const ErrorBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.textLighter};
  background-color: ${(props) => props.theme.brand};
  font-size: 14px;
  height: 0;
  margin-bottom: 0px;
  overflow: hidden;

  ${(props) =>
    props.show
      ? `opacity: 1;
    height: auto;
    padding: 5px;
    `
      : ''};
`;

export const SuccessBlock = styled.div`
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.textLighter};
  background-color: ${(props) => props.theme.accentDark};
  font-size: 14px;
  height: 0;
  margin-bottom: 0px;
  overflow: hidden;

  ${(props) =>
    props.show
      ? `opacity: 1;
    height: auto;
    padding: 5px;
    `
      : ''};
`;

export const Page = styled.div`
  background: url(${bg});
  width: 100%;
`;
