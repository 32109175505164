import { Router } from '@reach/router';
import React from 'react';
import { CommonFeedContainer } from 'scenes/Dybr/Feeds/CommonFeed';
import BlogList from 'scenes/Dybr/Feeds/BlogList';
import { ServiceBackground } from 'components/DybrComponents/ServiceBackground';
import { HeaderGeneral } from 'components/DybrComponents/HeaderGeneral';
import TabsContainer from 'components/DybrComponents/TabsContainer';
import { NavTab } from 'components/DybrComponents/NavTab';

function FeedScenes() {
  return (
    <ServiceBackground data-testid="feed-scenes">
      <HeaderGeneral paddingBottom="45px">
        <h1>дыбр</h1>
        <TabsContainer>
          <NavTab to="blogs">все дневники</NavTab>
          <NavTab to="communities">все сообщества</NavTab>
          <NavTab partial={false} to="./">
            общая лента
          </NavTab>
        </TabsContainer>
      </HeaderGeneral>

      <Router>
        <CommonFeedContainer path="/" />
        <BlogList path="/communities" isCommunity={true} />
        <BlogList path="/blogs" isCommunity={false} />
      </Router>
    </ServiceBackground>
  );
}

export default FeedScenes;
