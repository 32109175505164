export function hexToRgba(hex, opacity) {
  hex = hex.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
}

export function rgbaStringToObject(str) {
  if (!str) return '';
  var rgb = str.match(/\d+/g);
  if (!rgb) return str;
  if (rgb[4]) rgb[3] = '0.' + rgb[4];
  return {
    r: rgb[0],
    g: rgb[1],
    b: rgb[2],
    a: rgb[3]
  };
}

export function rgbaObjectToString(rgba) {
  return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
}

export function randomColor() {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}

export function changeRgbaAlpha(rgba, alpha) {
  const obj = rgbaStringToObject(rgba);
  obj.a = alpha;
  return rgbaObjectToString(obj);
}

export function darkenColor(str) {
  const coeff = 1 / 2;
  const color = str.includes('rgba')
    ? rgbaStringToObject(str)
    : {
        r: parseInt(str.substr(1, 2), 16),
        g: parseInt(str.substr(3, 2), 16),
        b: parseInt(str.substr(5, 2), 16),
        a: 1
      };
  return rgbaObjectToString({
    r: color.r * coeff,
    g: color.g * coeff,
    b: color.b * coeff,
    a: color.a
  });
}
