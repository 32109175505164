import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import { MainLoggedInContainer } from './Main/MainLoggedIn';
import About from './Static/About';
import Contacts from './Static/Contacts';
import Terms from './Static/Terms';
import Help from './Static/Help/Help';
import NotFound from 'scenes/Dybr/Static/NotFound';
import Footer from 'scenes/Footer/Footer';
import { selectIsSignupIncomplete } from 'store/user/selectors/selectIsSignupIncomplete';
import { connect } from 'react-redux';
import { ProfileContainer } from 'scenes/Dybr/Users/ProfilePage/Profile';
import { MainLoggedOut } from 'scenes/Dybr/Main/MainLoggedOut';
import FeedScenes from 'scenes/Dybr/Feeds/FeedScenes';
import { useTokenState } from 'store/localStorage/useTokenState';
import SearchScene from './Feeds/SearchScene';
import ForbiddenActions from './Static/TermsDetails/ForbiddenActions';

export function Dybr({ isSignupIncomplete }) {
  const [token] = useTokenState();
  const isLoggedIn = Boolean(token);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const creatingFirstProfile = isLoggedIn && isSignupIncomplete;

  return (
    <React.Fragment>
      <Router>
        {isLoggedIn && !isSignupIncomplete ? (
          <MainLoggedInContainer path="/" />
        ) : (
          <MainLoggedOut path="/" />
        )}
        {!creatingFirstProfile && <FeedScenes path="feed/*" />}
        {!creatingFirstProfile && <SearchScene path="search/*" />}
        {!creatingFirstProfile && <ProfileContainer path="profile/:pid" />}

        <About path="about" />
        <Contacts path="contacts" />
        <Terms path="terms" />
        <ForbiddenActions path="terms/forbidden-actions" />
        <Help path="help" />
        <NotFound default reason={'Dybr scene: не можем найти страницу'} />
      </Router>
      <Footer withLemur />
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  isSignupIncomplete: selectIsSignupIncomplete(state)
});

export const DybrContainer = connect(mapStateToProps)(Dybr);
