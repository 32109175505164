import React from 'react';

export const CheckmarkIcon = ({ className }) => (
  <svg
    viewBox="0 0 572.23065 572.23065"
    className={`icon icon-check-mark ${className}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="circle-fill main-outline"
      d="m 572.23065,286.11533 c 0,158.01713 -128.09819,286.11531 -286.11532,286.11532 C 128.0982,572.23065 5.5228474e-6,444.13246 0,286.11533 0,128.0982 128.0982,-5.5228476e-6 286.11533,0 c 79.00856,2.7614236e-6 150.53739,32.024553 202.31408,83.801244 6.47209,6.472086 12.63555,13.252799 18.46641,20.318166 3.51983,4.58469 7.19169,9.11635 10.2836,13.22808 0.25558,0.34868 0.52324,0.71804 0.75942,1.04288 l -29.14936,29.35118 32.55632,47.26908 c 0,0 26.06125,-25.64364 26.06347,-25.63439 0.0272,0.0609 0.0571,0.12738 0.0815,0.18185 0.29639,0.66207 0.61366,1.38508 0.87929,1.98916 0.46504,1.063 0.9238,2.12938 1.37625,3.19908 14.47823,34.23037 22.48436,71.86472 22.48436,111.369 z"
    />
    <path
      className="check-mark main-outline"
      d="M 517.93921,118.40588 272.45134,363.90683 141.57073,233.02619 c -11.09836,-11.09945 -29.09099,-11.09834 -40.19157,0 -11.09946,11.09837 -11.09946,29.09212 0,40.19158 l 150.9764,150.97424 c 11.09505,11.09836 29.10099,11.09059 40.1916,0 L 547.40705,169.36699 C 539.39803,151.39656 529.4564,134.3569 517.93921,118.40588 Z"
    />
  </svg>
);
