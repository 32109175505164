import theme from 'styles/DybrTheme';
import { useMemo } from 'react';

const activeStyle = {
  borderColor: theme.softAlertInProgressText
};

const acceptStyle = {
  borderColor: theme.accentDark,
  backgroundColor: theme.softAlertSuccess
};

const rejectStyle = {
  borderColor: theme.brandDarker,
  cursor: 'not-allowed',
  backgroundColor: theme.softAlertError
};

export function useDropzoneStyle(isDragActive, isDragAccept, isDragReject) {
  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );
  return { style };
}
