import React from 'react';
import convert from 'htmr';

// MORE tag in the actionable form

export class More extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  open = ev => {
    ev.preventDefault();
    this.setState({ open: true });
  };

  close = ev => {
    ev.stopPropagation();
    this.setState({ open: false });
  };

  render() {
    const { children, cover = 'читать' } = this.props;

    const coverProcessed = convert(cover, {});

    if (this.state.open) {
      return <span onDoubleClick={this.close}>{children}</span>;
    }
    return (
      <a href="/" onClick={this.open}>
        {coverProcessed}
      </a>
    );
  }
}

export function moreTagsToJSX(html = '') {
  const regexOpen = /\[MORE=(.+?\n*)\]/g;
  const regexClose = /(\[\/MORE])/g;

  html = html.replace(regexClose, '</More>');

  function matchToMoreOpenTag(match, g1) {
    const safeCover = g1.replace(/'/g, '&#39;');
    return "<More cover='" + safeCover + "'>";
  }

  html = html.replace(regexOpen, matchToMoreOpenTag);

  return html;
}
