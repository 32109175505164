import React, { useEffect, useState } from 'react';
import { Router } from '@reach/router';
import { connect } from 'react-redux';

import { getProfileByBlogSlug } from 'api/profiles';
import { getDesignAPI } from 'api/designs';

import NotFound from 'scenes/Dybr/Static/NotFound';

import BlogHeader from 'components/BlogComponents/BlogHeader';
import Footer from 'scenes/Footer/Footer';
import BlogActionsSidePanel from 'components/BlogComponents/BlogSidePanel';
import { DesignerPanelContainer } from 'scenes/Blog/designer/_DesignerScene';
import Loading from 'components/Shared/Loading';

import style from 'styles/blog/BlogCssConstructor';
import composeBlogTheme from 'styles/blog/ComposeBlogTheme';
import {
  Container,
  Content,
  ContentContainer,
  Header,
  Page
} from 'styles/blog/BlogLayout/StyledBlocks';
import { ThemeProvider } from 'styled-components';
import { selectDraftDesign } from 'store/designer/selectors';
import { updateProfileSetting } from 'store/user/thunks/updateProfileSetting';
import { updateUserSetting } from 'store/user/thunks/updateUserSettingThunk';
import { selectUserSettingsAugmented } from 'store/user/selectors/selectUserSettingsAugmented';
import { selectActiveProfileAugmented } from 'store/user/selectors/selectActiveProfileAugmented';
import { selectProfileIsFavoriteBySlug } from 'store/lists/selectors/selectProfileIsFavoriteBySlug';
import { BlogFeedContainer } from 'scenes/Blog/BlogFeed';
import { BlogEntryEditContainer } from 'scenes/Blog/EntryEdit/BlogEntryEdit';
import { BlogEntryPageContainer } from 'scenes/Blog/BlogEntryPage/BlogEntryPage';
import { BlogFavoritesContainer } from 'scenes/Blog/BlogFavorites';
import { getCommunitiesEntriesApi, getFavoriteEntriesApi } from 'api/entries';
import { getBookmarksApi } from 'api/bookmarks';
import css from 'scenes/Blog/_BlogScene.module.css';
import {
  useActiveProfile,
  useTokenState
} from 'store/localStorage/useTokenState';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';

/**
 * Scenes are mainly for routing and getting the data used in all sub-pages or sections
 *
 * The blog scene needs to do the following:
 * + fetch blofile
 * + fetch blog design and compose the final theme, put the css on the page
 * + route to the right page
 *
 */

const isDesignDisabled = (userSettings, design) => {
  let disabled = userSettings.disabledDesigns;
  const id = design?.id;

  if (!id || id === '0') {
    return false;
  }
  return disabled.includes(id);
};

export function Blog({
  slug,
  profile,
  updateProfileSetting,
  designerOn,
  designerCollapsed,
  designDraft,
  userSettings,
  updateUserSetting,
  ...restProps
}) {
  const [loading, setLoading] = useState(true);
  const [blog, setBlog] = useState({});
  const [design, setDesign] = useState();
  const [designDisabled, setDesignDisabled] = useState(false);
  const [sidePanelSaving, setSidePanelSaving] = useState(false);
  const [error, setError] = useState(false);
  const { activeProfileId, userId } = useActiveProfile();
  const [token] = useTokenState();
  const loggedIn = Boolean(token);

  useEffect(() => {
    window.scrollTo(0, 0);
    const loadBlog = async () => {
      setLoading(true);
      if (!slug) {
        return;
      }

      const res = await getProfileByBlogSlug({ slug, token, withTags: true });
      const blogData = res.data;
      if (res.error) {
        setError(true);
      } else if (blogData) {
        setBlog(blogData);

        const designId = blogData.settings?.currentDesign;

        let newDesign = {};
        if (designId && designId !== '0') {
          const designRes = await getDesignAPI({
            designId,
            profileId: blogData.id,
            token
          });

          if (!designRes.error) {
            // NOTE: most designs have id in the attributes, but apparently some
            // older ones have "0", so make sure we use the actual id.
            // And honestly, id should not be there to begin with...
            newDesign = { ...designRes.data, id: designId };
            setDesign(newDesign);
          }
        } else {
          setDesign(newDesign);
        }
        setDesignDisabled(isDesignDisabled(userSettings, newDesign));
      }
      setLoading(false);
    };
    loadBlog();
  }, [activeProfileId, slug]);

  const hotUpdateTagList = (tags) => {
    const blogTags = [...(blog.tags ?? [])];
    tags.forEach((entryTag) => {
      const tagIndex = blogTags.findIndex((t) => t.name === entryTag);
      if (tagIndex !== -1) {
        blogTags[tagIndex] = {
          ...blogTags[tagIndex],
          entries: blogTags[tagIndex].entries + 1
        };
      } else {
        blogTags.push({ name: entryTag, entries: 1 });
      }
    });
    setBlog((prevState) => ({ ...prevState, tags: blogTags }));
  };

  const updatePinnedInProfiles = (pinned) => {
    updateProfileSetting('pinnedEntries', pinned, token);
    setBlog((prevState) => ({
      ...prevState,
      settings: { ...prevState.settings, pinnedEntries: pinned }
    }));
  };

  const pinEntry = async (entryID) => {
    const pinned = blog.settings.pinnedEntries;
    // todo only owner and admins in community should be allowed to do that
    if (pinned && pinned.length && !pinned.find((p) => p === entryID)) {
      if (
        window.confirm(
          'Ранее закрепленная запись будет откреплена. Продолжить?'
        )
      ) {
        updatePinnedInProfiles([entryID]);
        return;
      } else return;
    }
    updatePinnedInProfiles([entryID]);
  };

  const unpinEntryIfPinned = (entryID) => {
    const pinned = blog.settings.pinnedEntries;

    if (pinned.find((e) => e === entryID)) {
      updatePinnedInProfiles([]);
    }
  };

  const toggleDefaultStyle = async () => {
    if (sidePanelSaving) {
      return;
    }
    setSidePanelSaving(true);
    setDesignDisabled((prevState) => !prevState);

    let disabled = [...userSettings.disabledDesigns];
    const id = design?.id;

    if (id && id !== '0' && !isDesignDisabled(userSettings, design)) {
      disabled.push(id);
    } else {
      disabled = disabled.filter((d) => d !== id);
    }
    // this is cleanup of old disabled designs
    disabled = disabled.filter((d) => d && d !== '0');

    const res = await updateUserSetting(
      'disabledDesigns',
      disabled,
      token,
      userId
    );

    if (!res) {
      setDesignDisabled((prevState) => !prevState);
    }

    setSidePanelSaving(false);
  };

  if (loading) return <Loading />;

  if (!loading && error) {
    return (
      <div className={css.error}>
        Не удалось получить данные. Попробуйте перезагрузить страницу
      </div>
    );
  }

  const isOwnBlog = blog.id === activeProfileId;

  let theme = composeBlogTheme({
    loggedIn,
    ownBlog: isOwnBlog,
    tmpDesign: designDraft,
    designer: designerOn,
    blogDesignDisabled: designDisabled,
    blogTheme: design
  });

  return (
    <ThemeProvider theme={theme}>
      <Page>
        <style>{style(theme)}</style>

        <div data-testid="blog" className="blog-page">
          <Header className={'blog-header-container ' + theme.layout.align}>
            <Router>
              <BlogHeader default profile={blog} />
              <BlogHeader path=":eid" profile={blog} />
              <BlogHeader path="favorites" profile={blog} />
              <BlogHeader path="bookmarks" profile={blog} />
              <BlogHeader path="communities" profile={blog} />
            </Router>
          </Header>
          <Container
            className={'blog-container ' + theme.layout.align}
            designer={designerOn}
            designerPreviewMode={designerCollapsed}
          >
            <ContentContainer className="blog-content-container">
              <Content className="blog-content">
                <Router>
                  <BlogFeedContainer path="/" blog={blog} />
                  <BlogEntryPageContainer path=":eid" blog={blog} />
                  <BlogEntryEditContainer
                    path=":eid/edit"
                    blog={blog}
                    hotUpdateTagList={hotUpdateTagList}
                    pinEntry={pinEntry}
                    unpinEntryIfPinned={unpinEntryIfPinned}
                  />
                  <BlogFavoritesContainer
                    path="favorites"
                    ownerProfile={blog}
                    apiCallback={getFavoriteEntriesApi}
                  />
                  <BlogFavoritesContainer
                    path="bookmarks"
                    ownerProfile={blog}
                    apiCallback={getBookmarksApi}
                  />
                  <BlogFavoritesContainer
                    path="communities"
                    ownerProfile={blog}
                    apiCallback={getCommunitiesEntriesApi}
                  />
                  <NotFound
                    default
                    reason={'Blog: Не можем найти такую страницу'}
                  />
                </Router>
              </Content>
            </ContentContainer>
          </Container>
        </div>
      </Page>
      {loggedIn && (
        <BlogActionsSidePanel
          profile={blog}
          isDefaultStyleOn={designDisabled}
          toggleDefaultStyle={toggleDefaultStyle}
          loading={sidePanelSaving}
          blogTheme={theme}
          higher={restProps['*'] === 'favorites'}
        />
      )}

      {isOwnBlog && designerOn && (
        <DesignerPanelContainer applyDesign={() => setDesign(designDraft)} />
      )}
      <Footer copyright={false} />
    </ThemeProvider>
  );
}

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    isFavorite: selectProfileIsFavoriteBySlug(state, ownProps.slug),
    designerOn: state.designer.designerOn,
    designerCollapsed: state.designer.designerCollapsed,
    designDraft: selectDraftDesign(state),
    userSettings: selectUserSettingsAugmented(state),
    profile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

const mapDispatchToProps = {
  updateProfileSetting,
  updateUserSetting
};

export const BlogContainer = WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(Blog)
);
