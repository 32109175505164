import styled from 'styled-components';

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  td,
  th {
    padding: 12px 0px 12px 0px;
    border-bottom: 1px solid ${(p) => p.theme.divider};
  }

  th {
    color: ${(p) => p.theme.accentDark};
  }
`;

export default StyledTable;
