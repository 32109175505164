import { createSelector } from 'reselect';
import { selectNotificationItems } from './selectNotificationItems';

/**
 * returns an array of unique entryIDs
 */
export const selectNotificationEntryIDs = createSelector(
  selectNotificationItems,
  items => {
    return [
      ...new Set(items.filter(item => item.entry).map(item => item.entry))
    ];
  }
);
