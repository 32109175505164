import React from 'react';
import Select from 'components/FormElements/Select';
import designConstants from 'configs/designConstants';

export default class BackgroundDisplaySetting extends React.Component {
  getDisplayOptions = () => {
    return designConstants[this.props.types].map((f, i) => ({
      label: f.label,
      value: i
    }));
  };

  handleSettingChange = ID => {
    this.props.update(this.props.nodeKey)({
      value: {
        ...this.props.setting,
        repeat: designConstants[this.props.types][ID].repeat,
        position: designConstants[this.props.types][ID].position,
        size: designConstants[this.props.types][ID].size,
        attachment: designConstants[this.props.types][ID].attachment
      }
    });
  };
  getSettingID = () => {
    const { repeat, position, size, attachment } = this.props.setting;

    return designConstants[this.props.types].findIndex(
      f =>
        f.size === size &&
        f.repeat === repeat &&
        f.position === position &&
        f.attachment === attachment
    );
  };

  render() {
    const { menuPlacement, nodeKey } = this.props;
    if (!nodeKey) return null;

    return (
      <Select
        options={this.getDisplayOptions()}
        captureMenuScroll={true}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        className="background-select"
        menuPlacement={menuPlacement}
        selectedOptionID={this.getSettingID()}
        onChange={this.handleSettingChange}
      />
    );
  }
}
