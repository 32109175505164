import convert from 'htmr';

function sanitizeTags(string) {
  if (!string) {
    return '';
  }
  let regexImage = /(<img([^>]+)>)/gi;
  let regexTags = /(<([^>]+)>)/gi;

  return string.replace(regexImage, '[изображение]').replace(regexTags, '');
}

export const truncateEntry = ({ title, content }, length) => {
  let string = sanitizeTags(title || content);

  if (string.length > length) string = string.slice(0, length) + '...';

  return convert(string);
};

export const truncateMention = (
  { content },
  profileId,
  lengthBefore,
  lengthAfter
) => {
  const regexMention = new RegExp(
    `<a[^>]*?href="[^>]*?/profile/${profileId}"[^>]*?>.*?</a>`,
    'gi'
  );

  const mentionMatch = regexMention.exec(content);
  if (mentionMatch) {
    let beforeContent = '';
    let afterContent = '';
    if (mentionMatch.index === 0) {
      afterContent = sanitizeTags(content);
    } else {
      beforeContent = sanitizeTags(content.slice(0, mentionMatch.index));
      afterContent = sanitizeTags(content.slice(mentionMatch.index));
    }
    const mention = afterContent.split(' ')[0];

    const firstPosition = Math.max(0, beforeContent.length - lengthBefore);
    const lastPosition = Math.min(
      afterContent.length,
      mention.length + lengthAfter
    );
    const string =
      (firstPosition !== 0 ? '...' : '') +
      beforeContent.slice(firstPosition) +
      afterContent.slice(0, lastPosition) +
      (lastPosition !== afterContent.length ? '...' : '');
    return convert(string);
  } else {
    return truncateEntry({ content }, length);
  }
};
