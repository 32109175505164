import React, { useEffect, useState } from 'react';

import {
  Block,
  ContentWrapper,
  MessageWrapper,
  Row,
  Settings,
  SettingSectionTitle,
  SettingsFlexbox
} from 'components/DybrComponents/StyledServicePagesElements';
import { connect } from 'react-redux';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';
import TextField from 'components/FormElements/TextField';
import { ActionButton, LinkButton } from 'components/Shared/Buttons';
import { selectUserData } from 'store/user/selectors/selectUserData';
import { changeUserEmailApi, updateUserPasswordApi } from 'api/users';
import {
  useActiveProfile,
  useTokenState
} from 'store/localStorage/useTokenState';
import { requestNewPasswordThunk } from 'store/user/thunks/requestNewPasswordThunk';
import { validateEmailDomain } from '../../../utils/helpers/validate';
import Select from 'components/FormElements/Select';
import { updateUserSetting } from 'store/user/thunks/updateUserSettingThunk';
import { selectUserSettingsAugmented } from 'store/user/selectors/selectUserSettingsAugmented';
import { parseToken } from 'store/user/reducer/parseToken';

const tokenExpirationOptions = [
  { value: 30, label: '30 дней' },
  { value: 90, label: '90 дней' },
  { value: 180, label: '180 дней' },
  { value: 365, label: '1 год' },
]

function AccountSettingsScene({ user, requestNewPasswordThunk, userSettings, updateUserSetting }) {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordRetyped, setNewPasswordRetyped] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState({});
  const [token] = useTokenState();
  const { userId } = useActiveProfile();
  const [tokenExpirationId, setTokenExpirationId] = useState(findTokenExpirationOption(userSettings?.login?.tokenExpiration));

  useEffect(() => {
    document.title = `Настройки аккаунта`;
  }, []);

  function findTokenExpirationOption(tokenExpiration) {
    if (tokenExpiration && tokenExpiration.periodType == "day")
    {
      if (tokenExpiration.value) {
        let option = tokenExpirationOptions.findIndex(option => option.value == tokenExpiration.value);
        return option !== -1 ? option : null;
      }
    }
    return null;
  }

  function validateEmail() {
    if (newEmail === user.email) {
      setErrors({ ...errors, formEmail: 'Новый email совпадает с текущим' });
      return false;
    }
    if (!newEmail) {
      setErrors({ ...errors, formEmail: 'Укажите новый email' });
      return false;
    }
    let unsupportedEmailDomain = validateEmailDomain(newEmail);
    if (unsupportedEmailDomain) {
      setErrors({
        ...errors,
        formEmail: `Почтовый домен "${unsupportedEmailDomain}" не поддерживается`
      });
      return false;
    }
    return true;
  }

  async function changeEmail() {
    const isValid = validateEmail();
    if (!isValid) {
      return;
    }
    const res = await changeUserEmailApi({
      newEmail,
      userId,
      token
    });
    if (res && res.error) {
      setSuccessMessage({ ...successMessage, formEmail: '' });
      setErrors({ ...errors, formEmail: 'Не удалось отправить письмо' });
    } else {
      setErrors({ ...errors, formEmail: '' });
      setSuccessMessage({
        ...successMessage,
        formEmail:
          'Письмо с подтверждением отправлено на новый адрес. Проверьте его, чтобы завершить смену почты'
      });
    }
  }

  async function changePassword() {
    if (newPassword !== newPasswordRetyped) {
      setErrors({ ...errors, formPassword: 'Пароли не совпадают!' });
      return;
    }
    if (oldPassword && newPassword && newPasswordRetyped) {
      const res = await updateUserPasswordApi({
        oldPassword,
        newPassword,
        userId,
        token
      });
      if (res.error) {
        setErrors({ ...errors, formPassword: res.error });
        setSuccessMessage({ ...successMessage, formPassword: '' });
      } else {
        setErrors({ ...errors, formPassword: '' });
        setSuccessMessage({
          ...successMessage,
          formPassword: 'Пароль успешно изменён'
        });
      }
    } else {
      setErrors({ ...errors, formPassword: 'Заполните все поля' });
    }
  }

  async function recoverPassword({ email }) {
    const sendRecoveryMail = window.confirm(
      'Выслать письмо с восстановлением пароля?'
    );
    if (sendRecoveryMail) {
      const res = await requestNewPasswordThunk({ email });
      if (res.error) {
        window.alert(res.error);
      } else {
        window.alert('Ссылка отправлена на почту аккаунта!');
      }
    }
  }

  async function changeTokenExpiration() {
    if (tokenExpirationOptions[tokenExpirationId]) {
      let loginSettings = {
        'period_type': 'day',
        'value': tokenExpirationOptions[tokenExpirationId].value
      }
      const result = updateUserSetting('login.token_expiration', loginSettings, token, userId);
      if (result.error) {
        setErrors({ ...errors, formPassword: result.error });
        setSuccessMessage({ ...successMessage, tokenExpiration: '', tokenExpirationWarning: '' });
      } else {
        setErrors({ ...errors, tokenExpiration: '' });
        setSuccessMessage({
          ...successMessage,
          tokenExpiration: 'Длительность сессии успешно изменена',
          tokenExpirationWarning: 'Пожалуйста, перезайдите в аккаунт для применения изменений'
        });
      }
    }
  }

  return (
    <ContentWrapper>
      <Block>
        <SettingsFlexbox>
          <SettingSectionTitle>
            <h4>Смена почты</h4>
            <p>
              Вы можете сменить ваш email, он будет использоваться со следующего
              входа.
            </p>
            <p>
              <br />
            </p>
            <p>
              Внимание: если у вас уже зарегистрирован аккаунт на новой почте,
              произойдет слияние аккаунтов. Все профили будут доступны из одного
              аккаунта с возможностью переключения между ними.
            </p>
          </SettingSectionTitle>
          <Settings>
            <TextField disabled label="текущий email" value={user.email} />
            <TextField
              label="новый email"
              value={newEmail}
              onChange={e => setNewEmail(e.target.value)}
            />
            <MessageWrapper type="error" show={errors.formEmail}>
              {errors.formEmail}
            </MessageWrapper>
            <MessageWrapper type="success" show={successMessage.formEmail}>
              {successMessage.formEmail}
            </MessageWrapper>
            <ActionButton onClick={changeEmail}>сменить почту</ActionButton>
          </Settings>
        </SettingsFlexbox>
      </Block>
      <Block>
        <SettingsFlexbox>
          <SettingSectionTitle>
            <h4>Смена пароля</h4>
            <p>
              Вы можете сменить ваш пароль, он будет использоваться со
              следующего входа.
            </p>
          </SettingSectionTitle>
          <Settings>
            <TextField
              isRequired={true}
              type="password"
              value={oldPassword}
              onChange={e => setOldPassword(e.target.value)}
              label="ваш пароль"
              id="old-password"
              error={errors?.oldPassword}
            />
            <Row>
              <TextField
                isRequired={true}
                type="password"
                value={newPassword}
                onChange={e => {
                  setNewPassword(e.target.value);
                  setErrors({ ...errors, formPassword: '' });
                }}
                label="новый пароль"
                id="new-password"
                touched={false}
              />
              <TextField
                isRequired={true}
                type="password"
                value={newPasswordRetyped}
                onChange={e => {
                  setNewPasswordRetyped(e.target.value);
                  setErrors({ ...errors, formPassword: '' });
                }}
                label="повторите новый пароль"
                id="new-password-retyped"
                touched={false}
              />
            </Row>
            <MessageWrapper type="error" show={errors.formPassword}>
              {errors.formPassword}
            </MessageWrapper>
            <MessageWrapper type="success" show={successMessage.formPassword}>
              {successMessage.formPassword}
            </MessageWrapper>
            <ActionButton onClick={changePassword}>сменить пароль</ActionButton>
            <LinkButton onClick={() => recoverPassword({ email: user.email })}>
              Забыли пароль?
            </LinkButton>
          </Settings>
        </SettingsFlexbox>
      </Block>
      <Block>
        <SettingsFlexbox>
          <SettingSectionTitle>
            <h4>Продолжительность сессии</h4>
            <p>
              Вы можете изменить продолжительность сессии. По истечении указанного времени придется войти в аккаунт заново.
            </p>
            <p> <br /> </p>
            <p> 
              Чтобы увеличить безопасность, рекомендуем устанавливать короткое время сессии.
            </p>
            <p> <br /> </p>
            <p style={{color: "#de413a"}}>Текущая сессия истекает через {Math.ceil(new Date(parseToken(token).tokenExp * 1000 - new Date()) / (1000 * 60 * 60 * 24))} дней</p>
          </SettingSectionTitle>
          <Settings>
            <Select
              options={tokenExpirationOptions}
              className={['setting-select']}
              placeholder="Выберите длительность сессии"
              onChange={value => setTokenExpirationId(value)}
              selectedOptionID={tokenExpirationId}
            />
            <p> <br /> </p>
            <MessageWrapper type="success" show={successMessage.tokenExpiration}>
              {successMessage.tokenExpiration}
            </MessageWrapper>
            <MessageWrapper type="warning" show={successMessage.tokenExpirationWarning}>
              {successMessage.tokenExpirationWarning}
            </MessageWrapper>
            <MessageWrapper type="error" show={errors.tokenExpiration}>
              {errors.tokenExpiration}
            </MessageWrapper>
            
            <ActionButton onClick={changeTokenExpiration}>сохранить</ActionButton>
          </Settings>
        </SettingsFlexbox>
      </Block>
    </ContentWrapper>
  );
}

const mapDispatchToProps = {
  requestNewPasswordThunk,
  updateUserSetting
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: selectUserData(state),
    userSettings: selectUserSettingsAugmented(state)
  };
};

export const AccountSettingsContainer = WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(AccountSettingsScene)
);
