import React, { useEffect } from 'react';
import ContentWrap from 'components/DybrComponents/ContentWrap';
import { StyledHelpSection } from './StyledHelp';
import {
  SectionNarrowBlock,
  SectionTitle
} from 'components/DybrComponents/Section';
import { helpSections } from './text';

export default function HelpPage() {
  useEffect(() => {
    document.title = `Помощь - Дыбр`;
  }, []);

  function renderSection(section) {
    return (
      <StyledHelpSection>
        <h3>{section.title}</h3>
        {section.items.map((i) => renderItem(i))}
      </StyledHelpSection>
    );
  }

  function renderItem(item) {
    return (
      <>
        <h4>{item.title}</h4>
        {item.content}
      </>
    );
  }

  // take each element of text array (we have section objects there and render them)
  let sections = helpSections.map((s) => renderSection(s));

  return (
    <ContentWrap>
      <SectionTitle>Ответы на часто задаваемые вопросы</SectionTitle>
      <SectionNarrowBlock>{sections}</SectionNarrowBlock>
    </ContentWrap>
  );
}
