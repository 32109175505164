import React from 'react';

const Icon = ({ className, ...props }) => (
  <svg
    viewBox="0 0 401.8125 516.37499"
    className={`icon icon-lock ${className}`}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="lock main-outline"
      d="M 201,0 C 122.587,0 57.5625,65.026454 57.5625,143.43946 v 66.9375 H 57.375 c -32.513,0 -57.375,24.862 -57.375,57.375 V 459 c 0,32.513 24.862,57.375 57.375,57.375 h 0.1875 286.6875 0.1875 c 32.513,0 57.375,-24.862 57.375,-57.375 V 267.75 c 0,-30.6 -24.862,-57.375 -57.375,-57.375 V 143.4375 C 344.4375,65.0255 279.412,0 201,0 Z m 0,57.376954 c 47.812,0 86.0625,38.2495 86.0625,86.062506 V 210.375 h -172.125 v -66.93554 c 0,-47.812006 38.2505,-86.062506 86.0625,-86.062506 z"
    />
  </svg>
);
export default Icon;
