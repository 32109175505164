import { API_URL } from 'configs/dybr';

export const upload = (files, token, callback) => {
  // eslint-disable-next-line
  let formData = new FormData();
  // eslint-disable-next-line
  const xhr = new XMLHttpRequest();

  files.forEach(file => {
    formData.append('file', file);
  });
  console.log('formData', formData);
  xhr.onreadystatechange = () => {
    const { readyState, status, response } = xhr;

    if (readyState === 4) {
      try {
        const res = JSON.parse(response);
        if (status === 200) {
          if (res.link) {
            callback(res.link);
          } else {
            window.alert('Ошибка загрузки файла.');
          }
        } else {
          // onFailure(response);
          console.log('fail?');
          window.alert(
            'Что-то пошло не так во время загрузки, просим прощения'
          );
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  xhr.open('POST', API_URL + '/image-upload', true);

  if (token) xhr.setRequestHeader('Authorization', 'Bearer ' + token);

  xhr.send(formData);
};
