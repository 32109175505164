import React, { useEffect, useRef, useState } from 'react';
import TextField from 'components/FormElements/TextField';
import {
  AnimatedConditionalComponent,
  GroupedFields,
  GroupedFieldsRow,
  Label,
  SidebarErrorWrapper,
  SidebarFormWrapper
} from '../SidebarForm';
import UserProfilesSelectorContainer from '../../../../User/Settings/Privacy/PrivacyLists/UserProfilesSelectorContainer';
import { ActionButton } from 'components/Shared/Buttons';
import { getProfileApi, getProfileByBlogSlug } from 'api/profiles';
import { useTokenState } from 'store/localStorage/useTokenState';
import TagsSelect from 'components/FormElements/TagsSelect';
import { navigate } from '@reach/router';
import { useSearchParams } from '../../../../hooks/useSearchParams';
import Checkbox from '../../../../../components/FormElements/Checkbox';
import { formSearchParams } from '../../../../../utils/helpers/searchParamUtils';
import DatePicker from '../../../../../components/FormElements/DatePicker';

function BlogSearchForm({ initialBlogSlug }) {
  const minQueryLength = 3;

  const initSearchParams = useSearchParams();
  const [searchParams, setSearchParams] = useState(initSearchParams);
  const [searchProfile, setSearchProfile] = useState(null);
  const [blogSlug, setBlogSlug] = useState(initialBlogSlug);
  const [tagOptions, setTagOptions] = useState([]);

  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [token] = useTokenState();

  const changedInitialProfileRef = useRef(!initialBlogSlug);

  useEffect(() => {
    if (initialBlogSlug) {
      setIsLoading(true);
      getProfileByBlogSlug({
        slug: initialBlogSlug,
        token,
        withTags: true
      }).then(profileResponse => {
        setIsLoading(false);
        if (profileResponse.status === 'ok') {
          setTagOptions(tagsToOptions(profileResponse.data.tags));
          setSearchProfile({
            label: profileResponse.data.nickname,
            value: profileResponse.data.id
          });
        }
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  function tagsToOptions(tags) {
    return tags.map(tag => {
      return {
        label: tag.name,
        value: tag.name
      };
    });
  }

  useEffect(() => {
    if (searchProfile && changedInitialProfileRef.current) {
      setIsLoading(true);
      getProfileApi({
        profileId: searchProfile.value,
        token,
        include: ['tags']
      }).then(async response => {
        setIsLoading(false);
        if (response.error) {
          setFormErrors({ ...formErrors, form: response.error });
        }
        if (!response.error && response.status === 'ok') {
          setBlogSlug(response.data.blogSlug);
          setTagOptions(tagsToOptions(response.data.tags));
          setSearchParams({ ...searchParams, tags: [] });
        }
      });
    }
    if (!searchProfile && changedInitialProfileRef.current) {
      setTagOptions([]);
      setSearchParams({ ...searchParams, tags: [] });
    }
    if (!changedInitialProfileRef.current && searchProfile) {
      changedInitialProfileRef.current = true;
    }
  }, [searchProfile]);

  function validateSearchTerm(value) {
    if (value && value.length < minQueryLength) {
      formErrors.queryTerm = 'Минимум ' + minQueryLength + ' символа';
      return false;
    }
    formErrors.queryTerm = '';
    return true;
  }

  function handleTextChange(event) {
    const value = event.target.value;
    setSearchParams({ ...searchParams, content: value });
    validateSearchTerm(value);
  }

  function validateProfile(profile) {
    if (!profile) {
      setFormErrors({ ...formErrors, form: 'профиль не выбран' });
      return false;
    }
    setFormErrors({ ...formErrors, form: '' });
    return true;
  }

  function validateAndRedirect() {
    let isValid = validateSearchTerm(searchParams.content);
    isValid *= validateProfile(searchProfile);

    //Check required fields
    const filledDateParams = searchParams.startDate || searchParams.endDate;
    if (
      (!searchParams.tags || searchParams.tags.length === 0) &&
      !searchParams.content &&
      filledDateParams
    ) {
      setFormErrors({ ...formErrors, form: 'Укажите тег или ключевое слово' });
      isValid = false;
    } else {
      setFormErrors({ ...formErrors, form: '' });
    }
    if (isValid) {
      navigate(`/blog/${blogSlug}?${formSearchParams(searchParams)}`);
    }
  }

  return (
    <SidebarFormWrapper className={'sidebar-form-wrapper'}>
      <GroupedFields>
        <UserProfilesSelectorContainer
          isMulti={false}
          isClearable={true}
          className={'sidebar-select'}
          placeholder="профиль"
          value={searchProfile}
          onChange={setSearchProfile}
          isDisabled={isLoading}
        />
      </GroupedFields>
      <TextField
        disabled={isLoading}
        type="text"
        value={searchParams.content}
        meta={{}}
        onChange={handleTextChange}
        error={formErrors.queryTerm}
        label="ключевые слова"
        touched
      />
      <GroupedFields gap={'small'}>
        <TagsSelect
          isDisabled={isLoading}
          profile={searchProfile}
          value={searchParams.tags}
          onChange={tags => setSearchParams({ ...searchParams, tags })}
          searchAllTags={false}
          tagOptions={tagOptions}
          noOptionsMessage={({ inputValue }) => {
            return searchProfile
              ? inputValue
                ? 'ничего не найдено'
                : 'у профиля нет тегов'
              : 'сначала выберите профиль';
          }}
          className={'sidebar-select'}
        />
        {searchParams.tags && searchParams.tags.length > 1 && (
          <AnimatedConditionalComponent
            as={Checkbox}
            label={'все теги в одной записи'}
            checked={searchParams.tagsGrouped}
            onChange={() =>
              setSearchParams({
                ...searchParams,
                tagsGrouped: !searchParams.tagsGrouped
              })
            }
          />
        )}
      </GroupedFields>
      <GroupedFields>
        <Label>за период</Label>
        <GroupedFieldsRow>
          <DatePicker
            value={searchParams.startDate}
            onChange={date =>
              setSearchParams({ ...searchParams, startDate: date })
            }
            isClearable={true}
            maxDate={new Date()}
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            label={'с начала'}
          />
          <Label>по</Label>
          <DatePicker
            value={searchParams.endDate}
            onChange={date =>
              setSearchParams({ ...searchParams, endDate: date })
            }
            isClearable={true}
            maxDate={new Date()}
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            label={'сегодня'}
          />
        </GroupedFieldsRow>
      </GroupedFields>
      <SidebarErrorWrapper show={formErrors.form}>
        {formErrors.form}
      </SidebarErrorWrapper>
      <ActionButton disabled={isLoading} onClick={validateAndRedirect}>
        искать
      </ActionButton>
    </SidebarFormWrapper>
  );
}

export default BlogSearchForm;
