//import { loadUserFromStorage } from './_helpers';
import {
  BanProfileAction,
  HideProfileAction,
  StoreFavoritesAction,
  StoreListAction,
  StoreReadersAction,
  SubscribeToProfileAction,
  UnbanProfileAction,
  UnhideProfileAction,
  UnsubscribeFromProfileAction
} from './actions';

import {
  banProfileApi,
  getListsApi,
  hideProfileFromFeedApi,
  removeProfileFromListApi
} from 'api/lists';
import {
  addToFavoritesApi,
  getFavoritesApi,
  getReadersApi,
  removeFromFavoritesApi
} from 'api/favorites';
import { selectHiddenListId } from 'store/lists/selectors/selectHiddenListId';
import { selectBannedListId } from 'store/lists/selectors/selectBannedListId';

export const loadCustomLists = ({ profileId, token }) => async (dispatch) => {
  if (!token || !profileId || profileId === '0') {
    return;
  }

  const res = await getListsApi({ token });

  if (!res.error) {
    res.data.forEach((list) => dispatch(new StoreListAction(list)));
  } else if (res.noAuth) {
    // dispatch logout
  } else {
    // dispatch error
  }
};

export const loadFavorites = ({ profileId, token }) => async (dispatch) => {
  const res = await getFavoritesApi({ profileId, token });

  if (!res.error) {
    dispatch(new StoreFavoritesAction(res.data));
  } else {
    // dispatchError(dispatch, res);
  }
};

export const loadReaders = ({ profileId, token }) => async (dispatch) => {
  const res = await getReadersApi({ profileId, token });

  if (!res.error) {
    dispatch(new StoreReadersAction(res.data));
  } else {
    // dispatchError(dispatch, res);
  }
};

export const loadLists = ({ profileId, token }) => async (
  dispatch,
  getState
) => {
  if (!profileId || profileId === '0') {
    console.log('no profile detected, skip loading lists:', profileId);
    return;
  }
  await loadCustomLists({ profileId, token })(dispatch, getState);
  await loadFavorites({ profileId, token })(dispatch, getState);
  await loadReaders({ profileId, token })(dispatch, getState);
};

export const subscribeToProfile = ({
  profile,
  token,
  activeProfileId
}) => async (dispatch) => {
  const res = await addToFavoritesApi({
    profileId: profile.id,
    readerId: activeProfileId,
    token
  });

  if (!res.error) {
    dispatch(new SubscribeToProfileAction(profile));
    return true;
  } else {
    // dispatchError(dispatch, res);
    return false;
  }
};

export const unsubscribeFromProfile = ({
  profile,
  token,
  activeProfileId
}) => async (dispatch) => {
  const res = await removeFromFavoritesApi({
    profileId: profile.id,
    readerId: activeProfileId,
    token
  });

  if (!res.error) {
    dispatch(new UnsubscribeFromProfileAction(profile));
    return true;
  } else {
    // dispatchError(dispatch, res);
    return false;
  }
};

export const hideProfile = ({ profile, token }) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const listId = selectHiddenListId(state);
  const res = await hideProfileFromFeedApi({ profileId: profile.id, token });

  if (!res.error) {
    if (!listId) {
      // we need to save listId together with the hidden profileId
      dispatch(new StoreListAction(res.data));
    } else {
      dispatch(new HideProfileAction(profile));
    }
    return true;
  } else {
    // dispatchError(dispatch, res);
    return false;
  }
};

export const unhideProfile = ({ profile, token }) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const listId = selectHiddenListId(state);

  const res = await removeProfileFromListApi({
    profileId: profile.id,
    listId,
    token
  });
  if (!res.error) {
    dispatch(new UnhideProfileAction(profile));
    return true;
  } else {
    // dispatchError(dispatch, res);
    return false;
  }
};

export const banProfile = ({ profile, token }) => async (
  dispatch,
  getState
) => {
  const state = getState();

  const res = await banProfileApi({ profileId: profile.id, token });
  const listId = selectBannedListId(state);
  if (!res.error) {
    if (!listId) {
      // we need to save listId together with the banned profileId
      dispatch(new StoreListAction(res.data));
    } else {
      dispatch(new BanProfileAction(profile));
    }
    return true;
  } else {
    // dispatchError(dispatch, res);
    return false;
  }
};

export const unbanProfile = ({ profile, token }) => async (
  dispatch,
  getState
) => {
  const state = getState();
  const listId = selectBannedListId(state);

  const res = await removeProfileFromListApi({
    profileId: profile.id,
    listId,
    token
  });
  if (!res.error) {
    dispatch(new UnbanProfileAction(profile));
    return true;
  } else {
    // dispatchError(dispatch, res);
    return false;
  }
};
