import React, { useEffect } from 'react';
import ContentWrap from 'components/DybrComponents/ContentWrap';

import HeadedBlock from 'components/DybrComponents/HeadedBox';

import {
  SectionLargerBlock,
  SectionNarrowBlock,
  SectionSmallerBlock,
  SectionTitle
} from 'components/DybrComponents/Section';

const About = (props) => {
  useEffect(() => {
    document.title = `О проекте - Дыбр`;
  }, []);

  return (
    <ContentWrap>
      <SectionTitle className="large-margin" data-testid="about-section">
        О проекте
      </SectionTitle>
      <SectionLargerBlock>
        <SectionNarrowBlock size="500px">
          <p>
            Дыбр &mdash; проект дневниководов для дневниководов, основанный на
            вере в то, что личные блоги не умрут никогда.
          </p>
          <p>
            Дыбр разрабатывается маленькой командой друзей энтузиастов. Мы живем
            в четырех разных странах, но в основном онлайн, и много лет &mdash;
            на дневниках. Мы хотим сделать душевный и удобный сайт для личных
            блогов, который бы жил долго и счастливо, и развивался с нами и с
            нашими пользователями.
          </p>
          <p>
            Быстро сказка сказывается, но не скоро дело делается. Создание
            блог-платформы &mdash; не самое простое дело, но мы стараемся. Мы
            работаем на наших основных работах, в основном программистами, и
            занимаемся Дыбром изо всех оставшихся после этого сил. Вечера
            пятницы &mdash; это то время, когда активность в нашем слаке обычно
            поднимается, потому что наступают выходные &mdash; главное время
            дыбрить!
          </p>
          <p>
            Мы стараемся использовать современные, но проверенные технологии, не
            всегда выбирая то, с чем мы непременно лучше всего знакомы по опыту
            работы. Работа над Дыбром &mdash; это радость узнавать новое и
            разрабатывать что-то важное лично для нас и для других людей.
          </p>
        </SectionNarrowBlock>
      </SectionLargerBlock>
      <SectionSmallerBlock>
        <HeadedBlock title="Команда Дыбра" dark>
          <img
            data-testid="dybr-team-img"
            style={{
              objectFit: 'contain',
              width: '100%',
              height: '100%',
              overflow: 'hidden'
            }}
            alt="lemurs!"
            src="https://dybr.ru/img/p/131/1593208292_1593208279266.jpg"
          />
        </HeadedBlock>
      </SectionSmallerBlock>
    </ContentWrap>
  );
};

export default About;
