import Select from 'components/FormElements/Select';
import React, { useRef } from 'react';
import { getAllProfilesApi } from 'api/profiles';
import { useTokenState } from 'store/localStorage/useTokenState';

function UserProfilesSelector({
  onChange,
  owned,
  favorites,
  readers,
  value,
  placeholder,
  isMulti = true,
  ...rest
}) {
  const [token] = useTokenState();
  const getPresetGroupedOptionsRef = useRef(null);

  const profileToOption = profile => ({
    label: profile.nickname,
    value: profile.id
  });

  const profilesToGroupedOption = (profiles, label) => ({
    label: label,
    options: profiles.map(profileToOption)
  });

  const getPresetGroupedOptions = (searchTerm, page) => {
    if (page !== 1) {
      return [];
    }
    const groupedOptions = [];
    const filter = profile =>
      profile.nickname
        .toLowerCase()
        .includes(searchTerm ? searchTerm.toLowerCase() : '');
    const filteredOwned = (owned ?? []).filter(filter);
    if (filteredOwned && filteredOwned.length) {
      const ownedProfilesGroupedOption = profilesToGroupedOption(
        filteredOwned,
        'Мои профили'
      );
      groupedOptions.push(ownedProfilesGroupedOption);
    }
    const nonMutualReaders = readers.filter(reader => !reader.isFavorite);
    const filteredFamiliar = [
      ...(favorites ?? []),
      ...(nonMutualReaders ?? [])
    ].filter(filter);
    if (filteredFamiliar && filteredFamiliar.length) {
      const familiarProfilesGroupedOption = profilesToGroupedOption(
        filteredFamiliar,
        'Мои читатели и избранное'
      );
      groupedOptions.push(familiarProfilesGroupedOption);
    }

    return groupedOptions;
  };

  async function loadProfiles(searchTerm, loadedGroupOptions, { page }) {
    const presetGroupedOptions = getPresetGroupedOptionsRef.current(
      searchTerm,
      page
    );
    if (!searchTerm) {
      //Do not load other profiles without search term
      return {
        options: presetGroupedOptions,
        hasMore: false,
        additional: { page: page + 1 }
      };
    }
    const response = await getAllProfilesApi({
      searchTerm,
      searchAttribute: 'nickname',
      sort: 'nickname',
      pageNumber: page,
      pageSize: 50,
      searchAllBlogs: true,
      token
    });
    if (response.error) {
      return {
        options: presetGroupedOptions,
        hasMore: false
      };
    }
    let presetIds = [];
    presetGroupedOptions.forEach(
      groupedOption =>
        (presetIds = presetIds.concat(
          groupedOption.options.map(option => option.value)
        ))
    );
    const otherProfiles = response.data.filter(
      profile => !presetIds.includes(profile.id)
    );
    const otherProfilesGroupedOption = profilesToGroupedOption(
      otherProfiles,
      'Другие пользователи'
    );
    const totalLoaded =
      loadedGroupOptions && loadedGroupOptions.length
        ? loadedGroupOptions.reduce(
            (sum, options) => sum + options.options.length,
            0
          )
        : 0;
    const hasMore = totalLoaded < response.meta.totalProfiles;
    return {
      options: [...presetGroupedOptions, otherProfilesGroupedOption],
      hasMore: hasMore,
      additional: { page: page + 1 }
    };
  }

  React.useEffect(() => {
    getPresetGroupedOptionsRef.current = getPresetGroupedOptions;
  }, [owned, favorites, readers]);

  return (
    <Select
      isAsync
      isPaginated={true}
      isGrouped={true}
      isMulti={isMulti}
      isClearable={false}
      placeholder={placeholder ?? 'ники пользователей'}
      controlledValue
      value={value}
      loadingMessage={() => 'ищем...'}
      loadOptions={loadProfiles}
      defaultOptions
      onChange={onChange}
      noOptionsMessage={() => 'ничего не нашлось'}
      maxMenuHeight={300}
      captureMenuScroll={true}
      menuPlacement="auto"
      additional={{
        page: 1
      }}
      {...rest}
    />
  );
}

export default UserProfilesSelector;
