import React from 'react';

import {
  Block,
  Settings,
  SettingSectionTitle,
  SettingsFlexbox
} from 'components/DybrComponents/StyledServicePagesElements';
import { CrosspostBlockContainer } from 'scenes/User/Settings/CrosspostBlock';

const platforms = [
  // {
  //   id: 'diary',
  //   displayName: 'diary.ru'
  // },
  {
    id: 'dreamwidth',
    displayName: 'dreamwidth.org'
  },
  {
    id: 'livejournal',
    displayName: 'livejournal.com'
  }
];

export function CrosspostSettings() {
  return (
    <Block>
      <SettingsFlexbox>
        <SettingSectionTitle>
          <h4>Кросспост</h4>
          <p>
            Вы можете настроить автоматическое дублирование записей вашего блога
            на другие сервисы
          </p>
        </SettingSectionTitle>
        <Settings>
          {platforms.map((platform) => (
            <CrosspostBlockContainer key={platform.id} platform={platform} />
          ))}
        </Settings>
      </SettingsFlexbox>
    </Block>
  );
}
