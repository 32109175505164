import styled from 'styled-components';

export const IconButton = styled.a`
  position: relative;
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;

  .icon {
    width: 100%;
    height: 100%;
	
    .main-outline, .filling-outline, .filling-area {
      transition: opacity 0.3s;
    }
    .filling-area, .secondary-filling-area {
      opacity: 0;
    }
  }

  &:hover .icon,
  .icon.icon-active {
    .filling-area {
      opacity: 1;
    }
  }
}
`;
