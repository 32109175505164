import { getSelectedEntriesApi } from 'api/entries';
import isEqual from 'lodash/isEqual';
import { StoreEntriesAction } from 'store/notifications/actions/StoreEntriesAction';
import { selectNotificationEntriesMap } from 'store/notifications/selectors/selectNotificationEntriesMap';

export const loadNotificationEntries =
  ({ entryIds, token }) =>
  async (dispatch, getState) => {
    const state = getState();

    const currentEntriesIds = Object.keys(selectNotificationEntriesMap(state));

    if (isEqual(currentEntriesIds.slice().sort(), entryIds.slice().sort())) {
      return;
    }

    if (!entryIds || entryIds.length <= 0) {
      dispatch(new StoreEntriesAction({}));
      return;
    }

    const res = await getSelectedEntriesApi({ ids: entryIds, token });
    if (!res.error) {
      dispatch(new StoreEntriesAction(res.data));
    } else {
      // dispatch error
    }
  };
