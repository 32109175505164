import React, { useEffect, useState } from 'react';
import ContentWrap from 'components/DybrComponents/ContentWrap';

import { exportReportData, formReportHtml } from 'utils/helpers/logError';
import {
  ActionButton,
  ImportantButton,
  SecondaryButton
} from 'components/Shared/Buttons';

import {
  SectionNarrowBlock,
  SectionTitle
} from 'components/DybrComponents/Section';
import Loading from 'components/Shared/Loading';
import { ErrorBlock } from 'scenes/Account/components/StyledForm';
import { reportErrorApi } from 'api/errorHandling';
import { navigate } from '@reach/router';
import Footer from 'scenes/Footer/Footer';
import { useTokenState } from '../../../store/localStorage/useTokenState';

function ErrorLog({
  mode = 'errorlog',
  title = 'Если что-то пошло не так',
  conjunction = 'если',
  error,
  errorInfo
}) {
  const [stepsToReproduce, setStepsToReproduce] = useState('');
  const [sending, setSending] = useState(false);
  const [success, setSuccess] = useState();
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [token] = useTokenState();

  useEffect(() => {
    document.title = `Сообщить о проблеме - Дыбр`;
  });

  function clearState() {
    localStorage.clear();
    navigate('/');
  }

  function sendReport() {
    //validate. `true` means it's not defined
    let errors = {};
    if (!token && !email) {
      errors.email = true;
    }
    if (!stepsToReproduce) {
      errors.stepsToReproduce = true;
    }
    setErrors(errors);

    //send
    if (!Object.keys(errors).length) {
      setSending(true);

      const reportData = exportReportData({ error, errorInfo });
      const reportHtml = formReportHtml({
        ...reportData,
        userMessage: stepsToReproduce,
        userEmail: email
      });
      const res = reportErrorApi({
        ...reportData,
        reportHtml,
        userMessage: stepsToReproduce,
        userEmail: email
      });
      setSuccess(!res.error);

      setSending(false);
    }
  }

  function makeHtml() {
    const reportData = exportReportData({ error, errorInfo });
    const reportHtml = formReportHtml({
      ...reportData,
      userMessage: stepsToReproduce,
      userEmail: email
    });
    const fullHtml = `
      <!DOCTYPE HTML>
      <html lang="ru">
        <head>
          <title>Report</title>
        </head>
        <body>
        <pre>${reportHtml}</pre>
        </body>
      </html>
    `;
    const newWindow = window.open('', 'ДЫБРОЛОГ');
    newWindow.document.write(fullHtml);
    newWindow.document.close();
    newWindow.focus();
  }

  return (
    <>
      <ContentWrap>
        <SectionTitle>{title}</SectionTitle>

        <SectionNarrowBlock size="800px">
          <div>
            <br />
            <br />
            Просим прощения, {conjunction} что-то сломалось. Вы можете помочь
            нам узнать, что именно и как это починить, отправив отчет об ошибке.{' '}
            {mode === 'errorlog'
              ? `К сожалению, у нас нет информации об ошибке, поэтому,
                пожалуйста, напишите максимально подробно, что именно сломалось
                и как мы могли бы воспроизвести эту проблему`
              : `Если напишете в дополнение, что именно произошло,
                будем очень признательны!`}
            <br />
            <textarea
              style={{
                width: '100%',
                height: '100px',
                margin: '20px 0',
                padding: '15px',
                border: errors.stepsToReproduce ? '1px solid red' : null
              }}
              value={stepsToReproduce}
              onChange={e => setStepsToReproduce(e.target.value)}
              placeholder={'Описание проблемы и ваши последние действия'}
            />
            <br />
            {!token && (
              <>
                Вы не залогинены, укажите свой email для связи
                <input
                  style={{
                    width: '100%',
                    margin: '20px 0',
                    padding: '15px',
                    border: errors.email ? '1px solid red' : null
                  }}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder={'Ваш email для связи'}
                />
                <br />
              </>
            )}
            <ErrorBlock show={success === false}>
              Произошла ошибка при отправке отчета. Пожалуйста, отправьте его на
              наш email, используя инструкцию ниже:
            </ErrorBlock>
            <ErrorBlock
              show={Object.keys(errors).length}
              style={{ marginBottom: 10 }}
            >
              Отчёт не был отправлен. Пожалуйста, заполните все поля.
            </ErrorBlock>
            {sending ? (
              <Loading />
            ) : (
              success === undefined && (
                <ImportantButton onClick={sendReport}>
                  Отправить!
                </ImportantButton>
              )
            )}
            {success === true && (
              <div>Большое спасибо! Отчет успешно отправлен на сервер. </div>
            )}
            {success !== true && (
              <>
                <br />
                <br />
                Если кнопка не работает, то нажмите вот эту кнопку, и скопируйте
                то, что там есть, в тело письма, которое нужно отправить на{' '}
                <a href="mailto:support@dybr.ru">support@dybr.ru</a>
                <br />
                <br />
                <ActionButton onClick={makeHtml}>Сделать HTML</ActionButton>
              </>
            )}
            <br />
            <br />
            Часто ошибку можно исправить, вычистив данные сайта из браузера. Вы
            можете сделать это или просто закрыть это окно, нажав одну из кнопок
            ниже по вашему выбору:
            <br />
            <br />
            <SecondaryButton
              onClick={clearState}
              title="Удалить все данные из браузера, приведет к разлогиниванию"
            >
              Очистить и закрыть
            </SecondaryButton>
            <br />
            <br />
            <SecondaryButton
              onClick={() => {
                navigate('/');
              }}
              title="На главную"
            >
              Закрыть
            </SecondaryButton>
            <br />
            <br />
            Спасибо большое за ваше терпение, поддержку и помощь! Мы стараемся
            делать Дыбр лучше каждый день.
          </div>
        </SectionNarrowBlock>
      </ContentWrap>
      <Footer />
    </>
  );
}

export default ErrorLog;
