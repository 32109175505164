import React, { useState } from 'react';
import css from 'scenes/User/Settings/Privacy/PrivacyLists/List/AddMoreNames.module.css';
import { usePrivacyListContext } from 'scenes/User/Settings/Privacy/PrivacyLists/PrivacyListContext';
import UserProfilesSelectorContainer from '.././UserProfilesSelectorContainer';
import { SecondaryButton } from 'components/Shared/ButtonsNew';

function AddMoreNames({ list }) {
  const { handleAddUsers, menuPortalRef } = usePrivacyListContext();

  const [profiles, setProfiles] = useState([]);

  const handleUserListChange = options => {
    setProfiles(options);
  };

  return (
    <div className={css.container}>
      <UserProfilesSelectorContainer
        onChange={handleUserListChange}
        value={profiles}
        containerClassName={css.selector}
        menuPortalTarget={menuPortalRef.current}
      />
      <SecondaryButton
        className={css.addButton}
        onClick={() => {
          handleAddUsers(
            list,
            profiles.map(p => p.value)
          );
          setProfiles([]);
        }}
        disabled={profiles.length === 0}
      >
        Добавить
      </SecondaryButton>
    </div>
  );
}

export default AddMoreNames;
