import React from 'react';

const Icon = ({ className }) => (
  <svg
    viewBox="0 0 15 8"
    className={`icon icon-minus-for-contract ${className}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      className="minus main-outline"
      d="M 15,0 V 2 H 0 V 0 Z"
    />
  </svg>
);
export default Icon;
