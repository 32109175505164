import getCommonStyles from './BlogCss/Common';
import getHeaderStyles from './BlogCss/Header';
import getEntryStyles from './BlogCss/Entry';
import getEntryFormStyles from './BlogCss/EntryForm';
import getCommentStyles from './BlogCss/Comment';
import getCommentFormStyles from './BlogCss/CommentForm';
import getFroalaStyles from './BlogCss/Froala';
import getTablesStyles from './BlogCss/Tables';
import getPaginationStyles from './BlogCss/Pagination';
import getBlogControlsStyles from './BlogCss/BlogControls';

const style = theme => {
  const commonS = getCommonStyles(theme);

  const headerS = getHeaderStyles(theme);

  const entryS = getEntryStyles(theme);

  const entryFormS = getEntryFormStyles(theme);

  const commentS = getCommentStyles(theme);

  const commentFormS = getCommentFormStyles(theme);

  const froalaS = getFroalaStyles(theme);

  const tablesS = getTablesStyles(theme);

  const paginationS = getPaginationStyles(theme);

  const blogControlsS = getBlogControlsStyles(theme);

  const styles = [
    commonS,
    headerS,
    entryS,
    entryFormS,
    commentFormS,
    commentS,
    froalaS,
    tablesS,
    paginationS,
    blogControlsS
  ];

  return styles.join(' ');
};

export default style;
