import reduceReducers from 'reduce-reducers';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { classActionMiddleware } from 'store/_setup/classActionMiddleware';
import { initialState } from 'store/_setup/initialState';

import { designerReducer } from 'store/designer/designerReducer';
import { listReducer } from 'store/lists/listReducer';
import { notificationsReducer } from 'store/notifications/reducer/notificationsReducer';
import { userReducer } from 'store/user/reducer/userReducer';

const reducers = [
  designerReducer,
  listReducer,
  notificationsReducer,
  userReducer
];

const rootReducer = reduceReducers(initialState, ...reducers);

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(classActionMiddleware, thunk))
);
