import React from 'react';
import styled from 'styled-components';

/*
This is used on non-blog dybr pages.
Example: login form. 

TODO: is this used much? Do we need it here, or in Dybr section?
*/

const Block = styled.div`
  background: ${props =>
    props.dark ? props.theme.backgroundLight : props.theme.backgroundLighter};
  min-width: ${props => props.w || '320px'};
  width: ${props => props.w || 'auto'};

  @media (max-width: 1200px) {
    min-width: ${props => props.w || '300px'};
    width: ${props => props.w || 'auto'};
  }
  @media (max-width: ${props => props.w || '400px'}) {
    min-width: 100%;
    max-width: 100%;
  }
`;

const InnerContent = styled.div`
  padding: 36px 54px;
  @media (max-width: 1200px) {
    padding: 18px 22px;
  }
  @media (max-width: 800px) {
    padding: 15px;
  }
  @media (max-width: 600px) {
    font-size: 16px;
    text-align: center;
  }
`;

const Title = styled.h3`
  height: 54px;
  line-height: 54px;
  font-family: 'Montserrat Alternates', sans-serif;
  text-transform: lowercase;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  color: ${props => props.theme.textLighter};
  background: ${props => props.theme.brand};
`;

const HeadedBlock = ({ title = '', children, dark = false, width }) => (
  <Block dark={dark} w={width}>
    <Title>{title}</Title>
    <InnerContent>{children}</InnerContent>
  </Block>
);

export default HeadedBlock;
