import { selectNotificationsByMessageId } from 'store/notifications/selectors/selectNotificationIds';
import { MarkNotificationReadAction } from 'store/notifications/actions/MarkNotificationReadAction';
import { markMessageAsReadApi } from '../../../api/private-messages';

export const readMessagesNotifications =
  ({ messageIds, token, activeProfileId }) =>
  async (dispatch, getState) => {
    const state = getState();
    const notifications = selectNotificationsByMessageId(state, {
      messageIds
    });

    for (const notification of notifications) {
      const res = await markMessageAsReadApi({
        profileId: activeProfileId,
        messageId: notification.message.id,
        token
      });
      if (!res.error) {
        dispatch(new MarkNotificationReadAction(notification.id));
      } else {
        // dispatch error
      }
    }
  };
