import React, { useCallback, useEffect, useState } from 'react';

import { validateName } from 'utils/helpers/validate';
import TextField from 'components/FormElements/TextField';
import { ActionButton } from 'components/Shared/Buttons';
import config from 'configs/dybr';
import { debounce } from 'utils/helpers/general';

import {
  Block,
  Settings,
  SettingSectionTitle,
  SettingsFlexbox
} from 'components/DybrComponents/StyledServicePagesElements';
import { connect } from 'react-redux';
import { updateProfileThunk } from 'store/user/thunks/updateProfileThunk';
import { selectActiveProfileAugmented } from 'store/user/selectors/selectActiveProfileAugmented';
import {
  useActiveProfile,
  useTokenState
} from 'store/localStorage/useTokenState';
import WithActiveProfileId from 'store/localStorage/WithActiveProfileId';

function NicknameSettings({ profile, updateProfileThunk }) {
  const [nickname, setNickname] = useState(profile.nickname);
  const [nicknameError, setNicknameError] = useState('');
  const { activeProfileId } = useActiveProfile();
  const [token] = useTokenState();

  useEffect(() => {
    setNickname(profile.nickname);
    setNicknameError('');
  }, [profile]);

  const validateNickname = async (nickname, token) => {
    const error = await validateName(nickname, { token });
    setNicknameError(error ?? '');
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedValidate = useCallback(debounce(300, validateNickname), []);

  const updateNickname = () => {
    if (nickname !== profile.nickname) {
      updateProfileThunk({ data: { nickname }, token, activeProfileId });
    }
  };

  const changeNickname = e => {
    const value = e.target.value;
    setNicknameError('');
    setNickname(value);

    if (value !== profile.nickname) {
      debouncedValidate(value, token);
    }
  };

  const canSaveFirstSection =
    nickname !== (profile.nickname ?? '') && !nicknameError;

  return (
    <Block>
      <SettingsFlexbox>
        <SettingSectionTitle>
          <h4>Смена ника</h4>
          <p>Здесь вы можете изменить ваше Дыбро-имя</p>
        </SettingSectionTitle>
        <Settings>
          <TextField
            label="никнейм"
            isRequired={true}
            onChange={changeNickname}
            maxLen={config.MAX_LENGTH.nickname}
            value={nickname}
            error={nicknameError}
            touched
          />
          {canSaveFirstSection && (
            <ActionButton onClick={updateNickname}>сохранить</ActionButton>
          )}
        </Settings>
      </SettingsFlexbox>
    </Block>
  );
}

const mapDispatchToProps = {
  updateProfileThunk
};

const mapStateToProps = (state, ownProps) => {
  const activeProfileId = ownProps.activeProfileId;

  return {
    profile: selectActiveProfileAugmented(state, { activeProfileId })
  };
};

export default WithActiveProfileId(
  connect(mapStateToProps, mapDispatchToProps)(NicknameSettings)
);
