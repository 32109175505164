import {
  Block,
  Settings,
  SettingSectionTitle,
  SettingsFlexbox
} from 'components/DybrComponents/StyledServicePagesElements';
import { ArchiveUploadContainer } from 'scenes/User/Settings/Blog/ArchiveUpload/ArchiveUpload';
import { ArchiveDownloadContainer } from 'scenes/User/Settings/Blog/ArchiveDownload';
import React from 'react';

export function ArchiveSettings() {
  return (
    <Block>
      <SettingsFlexbox>
        <SettingSectionTitle>
          <h4>Архив</h4>
          <p>
            Здесь можно загрузить архив дневника на Дыбр или получить архив
            своего блога.
          </p>
        </SettingSectionTitle>

        <Settings>
          <ArchiveUploadContainer />
          <ArchiveDownloadContainer />
        </Settings>
      </SettingsFlexbox>
    </Block>
  );
}
