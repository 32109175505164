import React from 'react';
import Select from 'components/FormElements/Select';
import designConstants from 'configs/designConstants';

export default class AlignSetting extends React.Component {
  getDisplayOptions = () => {
    return designConstants.layoutAlignment.map((f, i) => ({
      label: f.label,
      value: i
    }));
  };

  handleSettingChange = ID => {
    this.props.update(this.props.nodeKey)(
      designConstants.layoutAlignment[ID].value
    );
  };
  getSettingID = () => {
    return designConstants.layoutAlignment.findIndex(
      s => s.value === this.props.setting
    );
  };

  render() {
    const { menuPlacement, nodeKey } = this.props;
    if (!nodeKey) return null;

    return (
      <Select
        options={this.getDisplayOptions()}
        captureMenuScroll={true}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        className="alignment-select"
        menuPlacement={menuPlacement}
        selectedOptionID={this.getSettingID()}
        onChange={this.handleSettingChange}
      />
    );
  }
}
