import React from 'react';

const Icon = ({ className }) => (
  <svg
    className={`icon icon-simple-arrow-left ${className}`}
    viewBox="0 0 7 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="arrow main-outline"
      d="M 6.5489037,0.91555726 C 6.57469,0.67226956 6.5007992,0.42240236 6.3067162,0.24563546 6.006803,-0.05697324 5.4887517,-0.08405684 5.1602318,0.18704166 3.681311,1.6221001 2.2159132,3.0724839 0.74226308,4.5132137 c -0.3104036,0.3365698 -0.8017989,0.6292343 -0.7363281,1.15625 -0.0654836,0.5270287 0.4258813,0.8197242 0.7363281,1.15625 1.47359992,1.4404511 2.93918752,2.8893386 4.41796872,4.3242183 0.328296,0.27137 0.8463214,0.245721 1.1464844,-0.05664 C 6.6948822,10.739758 6.6016019,10.09248 6.193435,9.7964173 4.7876092,8.4216756 3.3864243,7.0420454 1.9785912,5.6694637 3.3864243,4.296882 4.7876092,2.9172521 6.193435,1.5425104 6.3975185,1.3944787 6.5231174,1.1588451 6.5489037,0.91555726 Z"
    />
  </svg>
);

export default Icon;
