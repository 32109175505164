import React from 'react';

const Icon = ({ className }) => (
  <svg
    className={`icon icon-simple-arrow-down-with-background ${className}`}
    viewBox="0 0 21.912875 21.912875"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="circle main-outline"
      d="M 10.956437,3.6090088e-7 A 10.956437,10.956437 0 0 1 21.912874,10.956438 10.956437,10.956437 0 0 1 10.956437,21.912874 10.956437,10.956437 0 0 1 5.0000001e-7,10.956438 10.956437,10.956437 0 0 1 10.956437,3.6090088e-7 Z"
    />
    <path
      className="arrow main-outline"
      d="m 6.2030615,8.2147954 c -0.243288,-0.02579 -0.493155,0.04811 -0.669922,0.242188 -0.302609,0.299913 -0.329692,0.817964 -0.05859,1.146484 1.435059,1.4789216 2.885442,2.9443196 4.326172,4.4179696 0.3365705,0.310404 0.6292345,0.801799 1.1562505,0.736328 0.527029,0.06548 0.819724,-0.425881 1.15625,-0.736328 1.440447,-1.4736 2.889335,-2.939187 4.324214,-4.4179696 0.27137,-0.328296 0.245721,-0.846321 -0.05664,-1.146484 -0.353534,-0.388166 -1.000812,-0.294886 -1.296874,0.113281 C 13.70918,9.9760904 12.32955,11.377276 10.956968,12.785109 9.5843855,11.377276 8.2047555,9.9760904 6.8300145,8.5702644 6.6819825,8.3661814 6.4463495,8.2405824 6.2030615,8.2147954 Z"
    />
  </svg>
);

export default Icon;
