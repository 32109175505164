import { StoreUserDataAction } from 'store/user/actions/StoreUserDataAction';
import { getUserData } from 'api/users';
import { setActiveProfileThunk } from './setActiveProfileThunk';
import { navigate } from '@reach/router';
import { setCommunitiesThunk } from 'store/user/thunks/setCommunitiesThunk';

export const loadUserById = ({
  setToken,
  token,
  userId,
  activeProfileId
}) => async (dispatch, getState) => {
  if (!userId || !token) {
    return;
  }

  const res = await getUserData({ userId, token });

  if (!res.error) {
    dispatch(new StoreUserDataAction(res.data));

    const profile = res.data?.profiles?.find(
      (profile) => profile.id === activeProfileId
    );

    if (!activeProfileId || activeProfileId === '0' || !profile) {
      if (res.data.activeProfile && res.data.activeProfile !== '0') {
        await setActiveProfileThunk({
          profileId: res.data.activeProfile,
          setToken,
          token
        })(dispatch, getState);
      } else if (res.data.profiles) {
        await setActiveProfileThunk({
          profileId: res.data.profiles[0].id,
          setToken,
          token
        })(dispatch, getState);
      } else {
        navigate('/account/create');
      }
    } else {
      // dispatch(new MigrateFormsAction(userId, activeProfileId));

      return await setCommunitiesThunk({ profileId: activeProfileId, token })(
        dispatch,
        getState
      );
    }
  } else {
    // dispatch error
    // dispatch(new LogoutAction());
  }
};
