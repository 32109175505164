import { query } from './Query';

export const getReserved = async ({ token }) => {
  try {
    let res = await query({
      method: 'GET',
      resource: 'nickname-verifications',
      queryParams: {
        'filters[state]': 'verified'
      },
      token
    });
    return { status: 'ok', data: res.data };
  } catch (err) {
    console.log('getReservedNicknames', err);
    return { error: 'что-то случилось, но не то, что мы ожидали' };
  }
};

export const checkNickname = async ({ nickname, token }) => {
  try {
    await query({
      method: 'GET',
      resource: 'availability',
      queryParams: {
        nickname: nickname.toLowerCase()
      },
      token
    });
    return { status: 'ok' };
  } catch (err) {
    console.log('check nickname', err);
    return { error: 'никнейм занят' };
  }
};
