import { leaveCommunityApi } from 'api/communities';
import { LeaveCommunityAction } from 'store/user/actions/LeaveCommunityAction';

export const leaveCommunityThunk = ({
  activeProfileId,
  communityId,
  token
}) => async (dispatch) => {
  const res = await leaveCommunityApi({
    activeProfileId,
    communityId,
    token
  });
  if (!res.error) {
    dispatch(new LeaveCommunityAction(activeProfileId, communityId));
    return true;
  } else {
    console.error(res.error);
    alert('что-то пошло не так');

    // dispatchError(dispatch, res);
    return false;
  }
};
