import { createSelector } from 'reselect';
import { selectNotificationsMap } from 'store/notifications/selectors/selectNotificationsMap';

export const selectNotificationsMentioned = createSelector(
  [selectNotificationsMap, (state, { activeProfileId }) => activeProfileId],
  notifications =>
    Object.values(notifications)
      .filter(notification => notification.type === 'mention')
      .sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1))
);

export const selectNotificationsOwnBlog = createSelector(
  [selectNotificationsMap, (state, { activeProfileId }) => activeProfileId],
  (notifications, profileId) =>
    Object.values(notifications)
      .filter(
        notification =>
          notification.blog === profileId && notification.type === 'comment'
      )
      .sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1))
);

export const selectNotificationsSmbdElseBlog = createSelector(
  [selectNotificationsMap, (state, { activeProfileId }) => activeProfileId],
  (notifications, profileId) =>
    Object.values(notifications)
      .filter(
        notification =>
          notification.blog !== profileId && notification.type === 'comment'
      )
      .sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1))
);

export const selectNotificationsPrivate = createSelector(
  [selectNotificationsMap, (state, { activeProfileId }) => activeProfileId],
  (notifications, profileId) =>
    Object.values(notifications)
      .filter(notification => notification.type === 'message')
      .sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1))
);
