import React from 'react';
import styled from 'styled-components';
import theme from 'styles/DybrTheme';
import background from 'styles/img/section_background/compressed.jpg';

// section is a horizonal full width div with content things inside.
// it justt has backgrounds and padding
export const SectionWrapperBackground = styled.div`
  background: ${props => props.backgroundColor || 'transparent'};
  width: 100%;
  ${props => (props.noPadding ? '' : 'padding: 84px 0 87px 0;')}

  /*we need this padding here so the footer does not overlap with the content and in the same time we keep the background of the last section behind*/
  padding-bottom: ${props =>
    props.lastSection ? '170px' : props.noPadding ? '' : '87px'};

  @media (max-width: 800px) {
    
    ${props => (props.noPadding ? '' : 'padding: 50px 0 60px 0;')}
    padding-bottom: ${props =>
      props.lastSection ? '185px' : props.noPadding ? '' : '60px'};
  }
  @media (max-width: 600px) {
    ${props => (props.noPadding ? '' : 'padding: 30px 0 40px 0;')}
    padding-bottom: ${props =>
      props.lastSection ? '195px' : props.noPadding ? '' : '40px'};
  }

  &.with-background {
    background: url(${background});
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

// this is a layout wrapper. Gives width and alignment to section content.
// adds FLEX, so inside blocks can use it.
export const ContentWrapperFlex = styled.div`
  max-width: ${props => props.theme.maxWidth};
  min-width: ${props => props.theme.minWidth};
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 1200px) {
    margin-left: 15px;
    margin-right: 15px;
  }
  @media (max-width: 600px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

// layout only!!
export const SectionSmallerBlock = styled.div`
  display: flex;
  width: 350px;
  min-width: 350px;
  flex-grow: 1;
  flex-shrink: 1;

  @media (max-width: 900px) {
    width: 50%;
    justify-content: center;
  }

  @media (max-width: 800px) {
    padding: 0;
  }

  @media (max-width: 600px) {
    width: 100%;
    min-width: 320px;
  }
`;

export const SectionLargerBlock = styled.div`
  display: flex;
  flex: 2 0 450px;

  @media (max-width: 800px) {
    width: 50%;
    flex: 1 0 450px;
    justify-content: center;
  }

  @media (max-width: 500px) {
    width: 100%;
    flex: 1 0 0%;
    /* margin: 10px 0; */
  }
`;

export const SectionNarrowBlock = styled.div`
  max-width: ${props => props.size || '800px'};
  margin: 0 auto;
`;

export const SectionTitle = styled.h2`
  width: 100%;
  text-align: center;
  padding: 0 15px;
  color: ${props => props.color || props.theme.text};
  @media (max-width: 600px) {
    padding-top: 10px;
  }
`;

// Puts it all together.
// TODO: Is this nice to use?..
export const Section = ({
  lastSection,
  backgroundColor,
  withBackground,
  noPadding,
  title,
  children
}) => {
  return (
    <SectionWrapperBackground
      backgroundColor={backgroundColor}
      noPadding={noPadding}
      className={withBackground ? 'with-background' : ''}
      lastSection={lastSection}
    >
      {title.length !== 0 && <SectionTitle>{title}</SectionTitle>}
      <ContentWrapperFlex>{children}</ContentWrapperFlex>
    </SectionWrapperBackground>
  );
};

Section.defaultProps = {
  withBackground: false,
  backgroundColor: theme.backgroundLighter,
  lastSection: false,
  title: ''
};

export default Section;
