import { useEntryDraftState } from 'store/localStorage/drafts/useDraftState';
import { useActiveProfile } from 'store/localStorage/useTokenState';

export function setEntryDraft(entryData, blog, activeProfileId) {
  return (draft) => {
    let entryId = entryData.id;

    if (blog.isCommunity && entryData.id === '0') {
      entryId = `${blog.id}.${entryData.id}`;
    }

    if (
      !entryData.content &&
      !entryData.title &&
      (!entryData.tags || entryData.tags.length === 0)
    ) {
      if (draft?.[activeProfileId]?.[entryId]) {
        delete draft[activeProfileId][entryId];
      }
      return draft;
    } else {
      return {
        ...draft,
        [activeProfileId]: {
          ...draft?.[activeProfileId],
          [entryId]: {
            ...draft?.[activeProfileId]?.[entryId],
            ...entryData
          }
        }
      };
    }
  };
}

export function useSetEntryDraft() {
  const [, setDraft] = useEntryDraftState();
  const { activeProfileId } = useActiveProfile();

  return (entryData, blog) =>
    setDraft(setEntryDraft(entryData, blog, activeProfileId));
}
