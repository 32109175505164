import React, { useEffect } from 'react';
import Loading from 'components/Shared/Loading';
import { connect } from 'react-redux';
import { loadEntryToRedirect } from 'store/notifications/operations/loadEntryToRedirect';
import { useTokenState } from 'store/localStorage/useTokenState';

function EntryRedirect({ eid, location, loadEntryToRedirect }) {
  const [token] = useTokenState();

  useEffect(() => {
    loadEntryToRedirect({ eid, location, token });
  }, [token, eid, location, loadEntryToRedirect]);

  return <Loading />;
}

const mapDispatchToProps = {
  loadEntryToRedirect
};

export const EntryRedirectContainer = connect(
  null,
  mapDispatchToProps
)(EntryRedirect);
