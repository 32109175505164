import React from 'react';

export const AddToFavoritesIcon = () => (
  <svg
    className={`icon icon-add-to-favorites`}
    viewBox="0 0 45 46"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="favorites-star main-outline"
      d="M 21.782046,0 C 21.485748,0 21.213822,0.1669258 21.082827,0.4335938 L 14.774234,13.216797 0.66876511,15.267578 c -0.293961,0.04288 -0.5388524,0.247035 -0.6308594,0.529297 -0.09123,0.281484 -0.014818,0.59142 0.1972656,0.798828 L 10.444156,26.544922 8.0339991,40.59375 c -0.05068,0.292398 0.06843,0.587058 0.308594,0.761719 0.135673,0.09903 0.297581,0.15039 0.458984,0.15039 0.123978,0 0.249444,-0.03058 0.363282,-0.08984 l 9.9687499,-5.240235 c -0.377598,-0.782769 -0.669012,-1.586027 -0.859375,-2.40625 L 10.692203,37.68555 12.74689,25.923831 c 0.04054,-0.230432 -0.03888,-0.467989 -0.208984,-0.630859 L 3.8406401,16.962891 15.860171,15.248047 c 0.235982,-0.03342 0.439239,-0.178865 0.544922,-0.388672 l 5.376953,-10.7011719 5.375,10.6992189 c 0.105686,0.209806 0.31089,0.357197 0.546875,0.390625 l 12.019532,1.714844 -6.951172,6.65625 c 0.0919,0.07602 0.19628,0.145358 0.285156,0.222656 l 5.166016,-2.271484 5.103515,-4.97461 c 0.212865,-0.206625 0.288496,-0.517344 0.197266,-0.798828 -0.09279,-0.283042 -0.336899,-0.48641 -0.63086,-0.529297 L 28.787906,13.216797 22.481265,0.4335938 C 22.351051,0.1669257 22.079121,0 21.782046,0 Z"
    />
    <path
      className="favorites-heart-fill filling-area"
      d="m 42.66566,27.209989 c -1.109763,-1.259221 -2.649314,-1.952685 -4.334986,-1.952685 -2.370786,0 -3.871753,1.415932 -4.713402,2.603787 -0.218366,0.308235 -0.404124,0.617305 -0.560088,0.908138 -0.155965,-0.290833 -0.341679,-0.599903 -0.560088,-0.908138 -0.84165,-1.187855 -2.342617,-2.603787 -4.713403,-2.603787 -1.685672,0 -3.225223,0.693508 -4.33503,1.95273 -1.058565,1.201214 -1.641593,2.80998 -1.641593,4.529929 0,1.87222 0.73095,3.613571 2.30034,5.480036 1.402617,1.668181 3.420475,3.387689 5.757114,5.378829 0.870698,0.741979 1.771102,1.509273 2.729691,2.347977 l 0.02878,0.02523 c 0.124279,0.10881 0.279232,0.16317 0.434185,0.16317 0.154952,0 0.309905,-0.0544 0.434184,-0.16317 l 0.02878,-0.02523 c 0.958589,-0.838704 1.858993,-1.605953 2.729823,-2.348066 2.336507,-1.991006 4.354365,-3.710515 5.756982,-5.37874 1.569391,-1.866509 2.30034,-3.607816 2.30034,-5.480036 0,-1.719949 -0.583028,-3.328715 -1.641637,-4.529974 z"
    />
    <path
      className="favorites-heart-outline filling-outline"
      d="m 27.519608,24.757628 c -1.770377,0 -3.387377,0.727993 -4.552952,2.050506 -1.111759,1.261589 -1.724891,2.950775 -1.724891,4.757171 0,1.966322 0.768489,3.796399 2.416742,5.756674 1.4731,1.752027 3.592536,3.556734 6.046594,5.64795 0.914451,0.779273 1.859214,1.585471 2.865972,2.466328 l 0.03033,0.0267 c 0.130523,0.114278 0.294073,0.17167 0.456812,0.17167 0.16274,0 0.324392,-0.05744 0.454916,-0.17167 l 0.03033,-0.0267 c 1.006758,-0.880858 1.953278,-1.686916 2.867867,-2.466328 2.453919,-2.091077 4.573496,-3.895877 6.046594,-5.64795 1.648255,-1.960321 2.414847,-3.790353 2.414847,-5.756674 0,-1.806396 -0.611189,-3.495536 -1.722994,-4.757171 -1.165528,-1.322513 -2.782576,-2.050506 -4.552954,-2.050506 -2.489919,0 -4.067061,1.485815 -4.951004,2.733372 -0.22934,0.323727 -0.423799,0.648272 -0.5876,0.953723 -0.1638,-0.305451 -0.360112,-0.629996 -0.589496,-0.953723 -0.883944,-1.247557 -2.459189,-2.733372 -4.949109,-2.733372 z m 10.881975,2.130618 c 1.199251,0 2.293501,0.491993 3.082057,1.386714 0.779688,0.884679 1.209319,2.081758 1.209319,3.370458 0,3.117779 -3.268366,5.855496 -7.481476,9.445676 -0.691511,0.589246 -1.402851,1.195949 -2.15327,1.848315 -0.750379,-0.652286 -1.461639,-1.258946 -2.15327,-1.848315 -4.212988,-3.590098 -7.542131,-6.297378 -7.542131,-9.415157 0,-1.2887 0.427774,-2.485779 1.207424,-3.370458 0.788597,-0.894721 1.8847,-1.386714 3.083952,-1.386714 1.66517,0 2.741487,1.025875 3.351216,1.886465 0.546933,0.771844 1.474686,2.184027 1.474686,2.184027 0,0 0.258339,0.41773 0.578123,0.41773 0.319783,0 0.576227,-0.41773 0.576227,-0.41773 0,0 0.868995,-1.442661 1.415927,-2.214546 0.609728,-0.860549 1.686045,-1.886465 3.351216,-1.886465 z"
    />
  </svg>
);
