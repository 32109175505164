/**
 * get page number, size, total entries, url base
 * render pagination accordingly
 */

import React from 'react';
import { navigate } from '@reach/router';
import ArrowLeftIcon from 'styles/Icons/SimpleArrowLeft';
import ArrowRightIcon from 'styles/Icons/SimpleArrowRight';

const Pagination = ({
  totalRecords,
  currentPage,
  lastPage = false, // temp
  url,
  // temp, refactor to always use function instead of url
  makePageUrl = ({ page }) => url + page,
  pageSize,
  maxFirst = 5,
  unknownSize = false,
  wrap = (a) => a,
  onChange
}) => {
  const totalPages = Math.ceil(totalRecords / pageSize);

  if ((!totalPages && !unknownSize) || (totalPages === 1 && !unknownSize))
    return null;

  const paginationElements = [];
  const pageLinks = [];

  const navigateTo = (url) => (e) => {
    e.preventDefault();
    navigate(url);
    onChange?.();
  };
  const addLink = (i) => {
    const link = (
      <a
        href={makePageUrl({ page: i })}
        key={i}
        onClick={navigateTo(makePageUrl({ page: i }))}
        className={`page page-${i}`}
      >
        {i}
      </a>
    );
    pageLinks.push(link);
  };

  if (currentPage !== 1) {
    const prev = makePageUrl({ page: currentPage - 1 });
    const link = (
      <div key={'prev'} className="previous">
        <a href={prev} onClick={navigateTo(prev)}>
          <ArrowLeftIcon />
          <span className="previous-word">предыдущая</span>
        </a>
      </div>
    );
    paginationElements.push(link);
  } else {
    const link = <div key={'prev'} className="previous" />;
    paginationElements.push(link);
  }

  for (let i = 1, dots = true; i <= (unknownSize ? 100 : totalPages); i += 1) {
    // how many page numbers to show before and after current
    const pagesBefore = 1;
    const pagesAfter = unknownSize ? 1 : Math.min(1, totalPages - currentPage);
    let showLink =
      i === 1 ||
      (i <= maxFirst && currentPage <= maxFirst - 2) ||
      (i >= currentPage - pagesBefore && i <= currentPage + pagesAfter) ||
      i === totalPages;
    if (lastPage && i >= currentPage + 1) showLink = false;
    if (lastPage) dots = false;

    if (showLink) {
      if (currentPage === i) {
        const link = (
          <span key={i} className={`current page-${i}`}>
            {i}
          </span>
        );
        pageLinks.push(link);
      } else {
        addLink(i);
        dots = true;
      }
    } else if (dots) {
      dots = false;
      const link = (
        <span key={i} className={`dots dots-${i}`}>
          ...
        </span>
      );
      pageLinks.push(link);
    }
  }

  paginationElements.push(
    <div key={'numbers'} className="page-numbers">
      {pageLinks}
    </div>
  );
  if (
    (currentPage !== totalPages || unknownSize) &&
    (totalPages || unknownSize) &&
    !lastPage
  ) {
    const next = makePageUrl({ page: currentPage + 1 });
    const link = (
      <div key="next" className="next">
        <a href={next} onClick={navigateTo(next)}>
          <span className="next-word">следующая</span>
          <ArrowRightIcon />
        </a>
      </div>
    );
    paginationElements.push(link);
  } else {
    const link = <div key="next" className="next" />;
    paginationElements.push(link);
  }
  return wrap(paginationElements);
};

export default Pagination;
