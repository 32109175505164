import Select from 'components/FormElements/Select';
import React, { useState } from 'react';
import { usePrivacyListContext } from 'scenes/User/Settings/Privacy/PrivacyLists/PrivacyListContext';

function getInitialSelectedLists(initialOptions, lists) {
  const listById = lists.reduce((listSet, item) => {
    listSet[item.id] = item.name;
    return listSet;
  }, {});

  return initialOptions.map((itemId) => ({
    label: listById[itemId],
    value: itemId
  }));
}

function CustomListDropdown({ onChange, initialOptions, ...rest }) {
  const { lists } = usePrivacyListContext();
  const [selectedLists, setSelectedLists] = useState(() =>
    initialOptions ? getInitialSelectedLists(initialOptions, lists) : []
  );

  return (
    <Select
      isMulti
      isClearable={false}
      placeholder="выберите списки"
      controlledValue
      value={selectedLists}
      options={lists.map((list) => ({ label: list.name, value: list.id }))}
      defaultOptions
      onChange={(options) => {
        setSelectedLists(options);
        onChange?.(options);
      }}
      noOptionsMessage={() => 'ничего не нашлось'}
      maxMenuHeight={300}
      captureMenuScroll={true}
      menuPlacement="auto"
      {...rest}
    />
  );
}

export default CustomListDropdown;
