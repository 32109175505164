export default {
  // Common variables
  maxWidth: '1180px',
  minWidth: '300px',

  // Fonts
  textFont: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  headerFont: "'Montserrat Alternates', sans-serif",

  // Colors
  brandLighter: '#ff938e',
  brandLight: '#f76961',
  brand: '#de413a',
  brandDark: '#bd221a',
  brandDarker: '#960d06',

  accentLighter: '#cedad6',
  accentLight: '#85dabc',
  accent: '#59ae92',
  accentDark: '#518473',

  softAlertSuccess: '#eef8ed',
  softAlertInProgress: '#eaf4fd',
  softAlertError: '#faecea',
  softAlertSuccessText: '#407145',
  softAlertInProgressText: '#23446a',
  // softAlertErrorText: '#bd221a', такой же, как brandDarker

  textLighter: '#fafafa',
  textLight: '#929292',
  text: '#333333',

  backgroundLighter: '#fafafa',
  backgroundLight: '#eeeeee',
  backgroundDark: '#929292',
  backgroundDarker: '#333333',

  shadow: '#888888',
  divider: '#c6c6c6',

  white: '#ffffff',
  backgroundIcon: '#c8c8cb',
  backgroundScroll: '#dcdcdc',
  favUnderline: '#f7a861',
  currentDesign: '#4bbf5a',

  // Border
  borderRadius: 'none'
};
